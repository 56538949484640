import React from "react";
import { InputFormReq } from "../interfaces/InputForm";
import arrowDown from "../assets/img/arrdownSelect.png";
import { Countries } from "../structure/countries";

type IProps = InputFormReq;

const InputForm = (p: IProps) => {
  if (!p.isSelector && !p.text) {
    return (
      <input
        name={p.name}
        className={`${p.className ? p.className : `inputFormDefault`}`}
        type={`${p.name?.includes("password") ? "password" : "text"}`}
        placeholder={p.placeholder}
        required={p.required}
        value={p.value}
        onInput={(e) => {
          if (p.onChange) p.onChange(e.currentTarget.value);
        }}
      />
    );
  }
  if (p.text) {
    return (
      <textarea
        name={p.name}
        className={`${
          p.className ? `${p.className} !h-[100px] pt-3` : `inputFormDefault`
        }`}
        placeholder={p.placeholder}
        value={p.value}
        onInput={(e) => {
          if (p.onChange) p.onChange(e.currentTarget.value);
        }}
      />
    );
  }
  return (
    <div className={`${p.userSelector ? `flex flex-row` : `relative w-full`}`}>
      <select
        className={`${p.className ? p.className : `selectorFormDefault`}`}
        name="country"
        id="co"
        value={p.value}
        onChange={(e) => {
          if (p.onChange) p.onChange(e.currentTarget.value);
        }}
      >
        {Countries.map((co) => {
          return <option value={co.value}>{co.name}</option>;
        })}
      </select>
      <img
        className={`${
          p.arrow ? `hidden` : `absolute top-[25px] right-[15px] `
        }`}
        src={arrowDown}
        alt="arrow"
      />
    </div>
  );
};

export default InputForm;
