import React, {useEffect} from "react";
import logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import arrow from "../../assets/img/arrow-left.svg";
import Button from "../../components/Button";
import InputForm from "../../components/InputForm";
import { buildObject } from "../../utils/buildObject";
import { passwordRecovery } from "../../network/UserApi/userApi";

export const Recovery = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const handleRecovery = (e: any) => {
    e.preventDefault();
    const data = buildObject(e, 1);
    passwordRecovery(data).then(() => {});
  };
  return (
    <div className="flex flex-col h-screen pb-0">
      <div className="flex relative justify-between px-[calc(50%-620px)] flex-grow-0 min-h-[70px]">
        <img src={logo} alt="logo" />
        <Link to={AppRoutes.Home}>
          <div className="flex items-center absolute bottom-[-70%] left-[70px] cursor-pointer">
            <img className="mr-3 h-[15px]" src={arrow} alt="arrow" />
            <p className="base text-white">Назад</p>
          </div>
        </Link>
      </div>
      <main className="flex justify-center items-start w-[100%] bg-gradient-to-r to-[#3dcd58] from-[#0e0353] bg-contain	flex-grow">
        <div className="mt-[200px] mb-[100px] flex flex-col items-center min-w-[450px] max-w-[450px] p-[40px] border-[16px] border-white bg-white rounded-xl">
          <div className={`mt-[-15px] text-center`}>
            <p className={`base text-[20px]`}>Восстановление пароля</p>
            <p className={`base text-SecondaryAccent mt-4 mb-4`}>
              Пожалуйста, введите адрес электронной почты, которая привязана к
              вашему аккаунту
            </p>
          </div>
          <form
            method={"POST"}
            className={`w-full mt-0 `}
            onSubmit={(event) => {
              handleRecovery(event);
            }}
          >
            <InputForm
              name={"email"}
              placeholder={"Электронная почта"}
              required={true}
            />
            <Button
              className="w-full h-14 font-bold"
              text="Сбросить пароль"
              isAttention={false}
            />
          </form>
        </div>
      </main>
    </div>
  );
};
