import React, { useEffect, useState } from "react";
import ArchiveBlock from "../../components/ArchiveBlock";
import { GetArchiveCountRes } from "../../network/CourseApi/courseInterfaces";
import { getArchiveCount } from "../../network/CourseApi/courseApi";
import { ArchiveCardReq } from "../../interfaces/ArchiveCard";
import mediumVoltage from "../../assets/img/mediumVoltage.png";
import lowVoltage from "../../assets/img/lowVoltage.png";
import asutp from "../../assets/img/asutp.png";
import buildingAutomation from "../../assets/img/buildingAutomation.png";
import informationModeling from "../../assets/img/informationModeling.png";
import calculationsAndDesign from "../../assets/img/calculationsAndDesign.png";
import TabbarBlock from "../../components/TabbarBlock";
import {
  GetCoffeeReq,
  GetCoffeeRes,
} from "../../network/CoffeeApi/coffeeInterfaces";
import { getCoffee } from "../../network/CoffeeApi/coffeeApi";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import AppRoutes from "../../structure/routes";
import CoffeeBlock from "../../components/CoffeeBlock";
import CalendarBlock from "../../components/CalendarBlock";
import { CalendarSessions } from "../../network/SessionApi/sessionInterfaces";
import { getSessionsForCalendar } from "../../network/SessionApi/sessionApi";

const COFFEE_FILTER: GetCoffeeReq = { limit: 0 };

const UseHash = () => {
  const location = useLocation();
  return location.hash;
};

export const Courses = () => {
  const [archiveCount, setArchiveCount] = useState<GetArchiveCountRes>([]);
  const [selected, setSelected] = useState<any>("");
  const [coffee, setCoffee] = useState<Array<GetCoffeeRes>>([]);
  const [calendarSessions, setCalendarSessions] = useState<CalendarSessions>();

  useEffect(() => {
    getArchiveCount().then((r) => {
      setArchiveCount(r);
    });
      window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    getCoffee(COFFEE_FILTER).then((r) => {
      setCoffee(r);
    });
  }, []);

  useEffect(() => {
    getSessionsForCalendar().then((r) => {
      setCalendarSessions(r);
    });
  }, []);

  const currentHash = UseHash();

  const archive: Array<ArchiveCardReq> = [
    {
      imgSrc: mediumVoltage,
      name: "Среднее напряжение",
      count: archiveCount[0],
      courseId: 24,
    },
    {
      imgSrc: lowVoltage,
      name: "Низкое напряжение",
      count: archiveCount[1],
      courseId: 22,
    },
    {
      imgSrc: asutp,
      name: "АСУТП",
      count: archiveCount[2],
      courseId: 20,
    },
    {
      imgSrc: buildingAutomation,
      name: "Автоматизация зданий",
      count: archiveCount[3],
      courseId: 19,
    },
    {
      imgSrc: informationModeling,
      name: "Информационное моделирование",
      count: archiveCount[4],
      courseId: 21,
    },
    {
      imgSrc: calculationsAndDesign,
      name: "Расчеты и проектирование",
      count: archiveCount[5],
      courseId: 23,
    },
  ];

  const tabbarElements = [
    {
      name: "Профессиональные курсы",
      path: "#courses",
    },
    {
      name: "Экспертные сессии",
      path: "#expert",
      active: false,
    },
    {
      name: "Кофе с экспертом",
      path: "#coffee",
      active: false,
    },
  ];

  return (
    <div>
      <div>
        <TabbarBlock elements={tabbarElements} onSelect={setSelected} />
      </div>
      {selected === "#courses" ||
      currentHash === "" ||
      currentHash === "#courses" ? (
        <div className={`mt-10`}>
          <p className={`base text-[36px]`}>Профессиональные курсы</p>
          <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
            Теория и практика онлайн
          </p>
          <ArchiveBlock archiveCards={archive} />
        </div>
      ) : null}
      {selected === "#expert" || currentHash === "#expert" ? (
        <div>
          <div className={`mt-10`}>
            <p className={`base text-[36px]`}>Экспертные сессии</p>
            <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
              Это живые и записанные вебинары от практикующих экспертов
              длительностью около 1 часа. Сессии также разделены по направлениям
              для удобства поиска. Расписание экспертных сессий отображается в
              календаре, вы можете записаться на предстоящую сессию и послушать
              выступление онлайн, а также задать спикеру ваши вопросы. Если
              интересная вам сессия уже состоялась, вы можете найти её запись в
              разделе «Экспертные сессии».
            </p>
            {calendarSessions && (
              <CalendarBlock sessions={calendarSessions.sessions} />
            )}
          </div>
          <div className={`mt-12`}>
            <p className={`base text-[36px]`}>
              Предложите тему или станьте экспертом
            </p>
            <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
              Каждый участник программы Life-long Learning может предложить нам
              тему для следующей экспертной сессии. А если вам есть, чем
              поделиться с сообществом инженеров-проектировщиков, вы можете сами
              выступить в роли спикера!
            </p>
            <div className={`relative mt-10`}>
              <Link to={AppRoutes.Feedback}>
                <Button
                  className={`!rounded-none border border-MainAccent bg-white text-MainAccent baseMicro h-[30px] flex items-center hover:bg-MainAccent hover:text-white`}
                  text={"Оставить заявку"}
                />
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {selected === "#coffee" || currentHash === "#coffee" ? (
        <div className={`mt-10`}>
          <p className={`base text-[36px]`}>Кофе с экспертом</p>
          <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
            10-минутные видеоподкасты, где эксперты делятся своим опытом и
            лайфхаками в проектировании
          </p>
          <CoffeeBlock cards={coffee} />
        </div>
      ) : null}
    </div>
  );
};
