import Colors from "../structure/colors";
import resolveTheme from "tailwindcss/resolveConfig";
import { content, theme } from "../tailwind.config";

export function getColor(d: Colors, hex: boolean = false) {
  const config: any = resolveTheme({ content, theme });
  let res;
  switch (d) {
    case Colors.Deadline:
      res = hex
        ? config.theme?.colors["MainDeadlineAccent"]["DEFAULT"]
        : "bg-MainDeadlineAccent";
      break;
    case Colors.CloseDeadline:
      res = hex
        ? config.theme?.colors["CloseDeadlineAccent"]["DEFAULT"]
        : "bg-CloseDeadlineAccent";
      break;
    case Colors.Main:
      res = hex
        ? config.theme?.colors["MainAccent"]["DEFAULT"]
        : "bg-MainAccent";
      break;
  }
  return res;
}

export function getDeadlineColor(
  d: string,
  userPercent: number,
  testPercent: number
) {
  let res;
  switch (d) {
    case "dead":
      res = "bg-CloseDeadlineAccent";
      break;
    case "normal":
      if (userPercent !== 100 || (testPercent === 100 && userPercent === 100)) {
        res = "bg-MainAccent";
      } else {
        res = "bg-MainDeadlineAccent";
      }
      break;
  }
  return res;
}
