const RareCountries = {
  RussianFederation: {
    value: "RU",
    name: "RUSSIAN FEDERATION",
  },
  AalandIslands: {
    value: "AX",
    name: "AALAND ISLANDS",
  },
  Afghanistan: {
    value: "AF",
    name: "AFGHANISTAN",
  },
  Albania: {
    value: "AL",
    name: "ALBANIA",
  },
  Algeria: {
    value: "DZ",
    name: "ALGERIA",
  },
  AmericanSamoa: {
    value: "AS",
    name: "AMERICAN SAMOA",
  },
  Andorra: {
    value: "AD",
    name: "ANDORRA",
  },
  Angola: {
    value: "AO",
    name: "ANGOLA",
  },
  Anguilla: {
    value: "AI",
    name: "ANGUILLA",
  },
  Antarctica: {
    value: "AQ",
    name: "ANTARCTICA",
  },
  AntiguaAndBarbuda: {
    value: "AG",
    name: "ANTIGUA AND BARBUDA",
  },
  Argentina: {
    value: "AR",
    name: "ARGENTINA",
  },
  Armenia: {
    value: "AM",
    name: "ARMENIA",
  },
  Aruba: {
    value: "AW",
    name: "ARUBA",
  },
  Australia: {
    value: "AU",
    name: "AUSTRALIA",
  },
  Austria: {
    value: "AT",
    name: "AUSTRIA",
  },
  Azerbaijan: {
    value: "AZ",
    name: "AZERBAIJAN",
  },
  Bahamas: {
    value: "BS",
    name: "BAHAMAS",
  },
  Bahrain: {
    value: "BH",
    name: "BAHRAIN",
  },
  Bangladesh: {
    value: "BD",
    name: "BANGLADESH",
  },
  Barbados: {
    value: "BB",
    name: "BARBADOS",
  },
  Belarus: {
    value: "BY",
    name: "BELARUS",
  },
  Belgium: {
    value: "BE",
    name: "BELGIUM",
  },
  Belize: {
    value: "BZ",
    name: "BELIZE",
  },
  Benin: {
    value: "BJ",
    name: "BENIN",
  },
  Bermuda: {
    value: "BM",
    name: "BERMUDA",
  },
  Bhutan: {
    value: "BT",
    name: "BHUTAN",
  },
  Bolivia: {
    value: "BO",
    name: "BOLIVIA",
  },
  BosniaAndHerzegovina: {
    value: "BA",
    name: "BOSNIA AND HERZEGOWINA",
  },
  Botswana: {
    value: "BW",
    name: "BOTSWANA",
  },
  BouvetIsland: {
    value: "BV",
    name: "BOUVET ISLAND",
  },
  Brazil: {
    value: "BR",
    name: "BRAZIL",
  },
  BritishIndianOceanTerritory: {
    value: "IO",
    name: "BRITISH INDIAN OCEAN TERRITORY",
  },
  BruneiDarussalam: {
    value: "BN",
    name: "BRUNEI DARUSSALAM",
  },
  Bulgaria: {
    value: "BG",
    name: "BULGARIA",
  },
  BurkinaFaso: {
    value: "BF",
    name: "BURKINA FASO",
  },
  Burundi: {
    value: "BI",
    name: "BURUNDI",
  },
  Cambodia: {
    value: "KH",
    name: "CAMBODIA",
  },
  Cameroon: {
    value: "CM",
    name: "CAMEROON",
  },
  Canada: {
    value: "CA",
    name: "CANADA",
  },
  CapeVerde: {
    value: "CV",
    name: "CAPE VERDE",
  },
  CaymanIslands: {
    value: "KY",
    name: "CAYMAN ISLANDS",
  },
  CentralAfricanRepublic: {
    value: "CF",
    name: "CENTRAL AFRICAN REPUBLIC",
  },
  Chad: {
    value: "TD",
    name: "CHAD",
  },
  Chile: {
    value: "CL",
    name: "CHILE",
  },
  China: {
    value: "CN",
    name: "CHINA",
  },
  ChristmasIsland: {
    value: "CX",
    name: "CHRISTMAS ISLAND",
  },
  Colombia: {
    value: "CO",
    name: "COLOMBIA",
  },
  Comoros: {
    value: "KM",
    name: "COMOROS",
  },
  CookIslands: {
    value: "CK",
    name: "COOK ISLANDS",
  },
  CostaRica: {
    value: "CR",
    name: "COSTA RICA",
  },
  CoteDIvoire: {
    value: "CI",
    name: "COTE D'IVOIRE",
  },
  Cuba: {
    value: "CU",
    name: "CUBA",
  },
  Cyprus: {
    value: "CY",
    name: "CYPRUS",
  },
  CzechRepublic: {
    value: "CZ",
    name: "CZECH REPUBLIC",
  },
  Denmark: {
    value: "DK",
    name: "DENMARK",
  },
  Djibouti: {
    value: "DJ",
    name: "DJIBOUTI",
  },
  Dominica: {
    value: "DM",
    name: "DOMINICA",
  },
  DominicanRepublic: {
    value: "DO",
    name: "DOMINICAN REPUBLIC",
  },
  Ecuador: {
    value: "EC",
    name: "ECUADOR",
  },
  Egypt: {
    value: "EG",
    name: "EGYPT",
  },
  ElSalvador: {
    value: "SV",
    name: "EL SALVADOR",
  },
  EquatorialGuinea: {
    value: "GQ",
    name: "EQUATORIAL GUINEA",
  },
  Eritrea: {
    value: "ER",
    name: "ERITREA",
  },
  Estonia: {
    value: "EE",
    name: "ESTONIA",
  },
  Ethiopia: {
    value: "ET",
    name: "ETHIOPIA",
  },
  FaroeIslands: {
    value: "FO",
    name: "FAROE ISLANDS",
  },
  Fiji: {
    value: "FJ",
    name: "FIJI",
  },
  Finland: {
    value: "FI",
    name: "FINLAND",
  },
  France: {
    value: "FR",
    name: "FRANCE",
  },
  FrenchGuiana: {
    value: "GF",
    name: "FRENCH GUIANA",
  },
  FrenchPolynesia: {
    value: "PF",
    name: "FRENCH POLYNESIA",
  },
  FrenchSouthernTerritories: {
    value: "TF",
    name: "FRENCH SOUTHERN TERRITORIES",
  },
  Gabon: {
    value: "GA",
    name: "GABON",
  },
  Gambia: {
    value: "GM",
    name: "GAMBIA",
  },
  Georgia: {
    value: "GE",
    name: "GEORGIA",
  },
  Germany: {
    value: "DE",
    name: "GERMANY",
  },
  Ghana: {
    value: "GH",
    name: "GHANA",
  },
  Gibraltar: {
    value: "GI",
    name: "GIBRALTAR",
  },
  Greece: {
    value: "GR",
    name: "GREECE",
  },
  Greenland: {
    value: "GL",
    name: "GREENLAND",
  },
  Grenada: {
    value: "GD",
    name: "GRENADA",
  },
  Guadeloupe: {
    value: "GP",
    name: "GUADELOUPE",
  },
  Guam: {
    value: "GU",
    name: "GUAM",
  },
  Guatemala: {
    value: "GT",
    name: "GUATEMALA",
  },
  Guinea: {
    value: "GN",
    name: "GUINEA",
  },
  GuineaBissau: {
    value: "GW",
    name: "GUINEA-BISSAU",
  },
  Guyana: {
    value: "GY",
    name: "GUYANA",
  },
  Haiti: {
    value: "HT",
    name: "HAITI",
  },
  HeardAndMcDonaldIslands: {
    value: "HM",
    name: "HEARD AND MC DONALD ISLANDS",
  },
  Honduras: {
    value: "HN",
    name: "HONDURAS",
  },
  HongKong: {
    value: "HK",
    name: "HONG KONG",
  },
  Hungary: {
    value: "HU",
    name: "HUNGARY",
  },
  Iceland: {
    value: "IS",
    name: "ICELAND",
  },
  India: {
    value: "IN",
    name: "INDIA",
  },
  Indonesia: {
    value: "ID",
    name: "INDONESIA",
  },
  Iraq: {
    value: "IQ",
    name: "IRAQ",
  },
  Ireland: {
    value: "IE",
    name: "IRELAND",
  },
  Israel: {
    value: "IL",
    name: "ISRAEL",
  },
  Italy: {
    value: "IT",
    name: "ITALY",
  },
  Jamaica: {
    value: "JM",
    name: "JAMAICA",
  },
  Japan: {
    value: "JP",
    name: "JAPAN",
  },
  Jordan: {
    value: "JO",
    name: "JORDAN",
  },
  Kazakhstan: {
    value: "KZ",
    name: "KAZAKHSTAN",
  },
  Kenya: {
    value: "KE",
    name: "KENYA",
  },
  Kiribati: {
    value: "KI",
    name: "KIRIBATI",
  },
  Kuwait: {
    value: "KW",
    name: "KUWAIT",
  },
  Kyrgyzstan: {
    value: "KG",
    name: "KYRGYZSTAN",
  },
  LaoPeoplesDemocraticRepublic: {
    value: "LA",
    name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  Latvia: {
    value: "LV",
    name: "LATVIA",
  },
  Lebanon: {
    value: "LB",
    name: "LEBANON",
  },
  Lesotho: {
    value: "LS",
    name: "LESOTHO",
  },
  Liberia: {
    value: "LR",
    name: "LIBERIA",
  },
  LibyanArabJamahiriya: {
    value: "LY",
    name: "LIBYAN ARAB JAMAHIRIYA",
  },
  Liechtenstein: {
    value: "LI",
    name: "LIECHTENSTEIN",
  },
  Lithuania: {
    value: "LT",
    name: "LITHUANIA",
  },
  Luxembourg: {
    value: "LU",
    name: "LUXEMBOURG",
  },
  Macau: {
    value: "MO",
    name: "MACAU",
  },
  Madagascar: {
    value: "MG",
    name: "MADAGASCAR",
  },
  Malawi: {
    value: "MW",
    name: "MALAWI",
  },
  Malaysia: {
    value: "MY",
    name: "MALAYSIA",
  },
  Maldives: {
    value: "MV",
    name: "MALDIVES",
  },
  Mali: {
    value: "ML",
    name: "MALI",
  },
  Malta: {
    value: "MT",
    name: "MALTA",
  },
  MarshallIslands: {
    value: "MH",
    name: "MARSHALL ISLANDS",
  },
  Martinique: {
    value: "MQ",
    name: "MARTINIQUE",
  },
  Mauritania: {
    value: "MR",
    name: "MAURITANIA",
  },
  Mauritius: {
    value: "MU",
    name: "MAURITIUS",
  },
  Mayotte: {
    value: "YT",
    name: "MAYOTTE",
  },
  Mexico: {
    value: "MX",
    name: "MEXICO",
  },
  Monaco: {
    value: "MC",
    name: "MONACO",
  },
  Mongolia: {
    value: "MN",
    name: "MONGOLIA",
  },
  Montserrat: {
    value: "MS",
    name: "MONTSERRAT",
  },
  Morocco: {
    value: "MA",
    name: "MOROCCO",
  },
  Mozambique: {
    value: "MZ",
    name: "MOZAMBIQUE",
  },
  Myanmar: {
    value: "MM",
    name: "MYANMAR",
  },
  Namibia: {
    value: "NA",
    name: "NAMIBIA",
  },
  Nauru: {
    value: "NR",
    name: "NAURU",
  },
  Nepal: {
    value: "NP",
    name: "NEPAL",
  },
  Netherlands: {
    value: "NL",
    name: "NETHERLANDS",
  },
  NetherlandsAntilles: {
    value: "AN",
    name: "NETHERLANDS ANTILLES",
  },
  NewCaledonia: {
    value: "NC",
    name: "NEW CALEDONIA",
  },
  NewZealand: {
    value: "NZ",
    name: "NEW ZEALAND",
  },
  Nicaragua: {
    value: "NI",
    name: "NICARAGUA",
  },
  Niger: {
    value: "NE",
    name: "NIGER",
  },
  Nigeria: {
    value: "NG",
    name: "NIGERIA",
  },
  Niue: {
    value: "NU",
    name: "NIUE",
  },
  NorfolkIsland: {
    value: "NF",
    name: "NORFOLK ISLAND",
  },
  NorthernMarianaIslands: {
    value: "MP",
    name: "NORTHERN MARIANA ISLANDS",
  },
  Norway: {
    value: "NO",
    name: "NORWAY",
  },
  Oman: {
    value: "OM",
    name: "OMAN",
  },
  Pakistan: {
    value: "PK",
    name: "PAKISTAN",
  },
  Palau: {
    value: "PW",
    name: "PALAU",
  },
  Panama: {
    value: "PA",
    name: "PANAMA",
  },
  PapuaNewGuinea: {
    value: "PG",
    name: "PAPUA NEW GUINEA",
  },
  Paraguay: {
    value: "PY",
    name: "PARAGUAY",
  },
  Peru: {
    value: "PE",
    name: "PERU",
  },
  Philippines: {
    value: "PH",
    name: "PHILIPPINES",
  },
  Pitcairn: {
    value: "PN",
    name: "PITCAIRN",
  },
  Poland: {
    value: "PL",
    name: "POLAND",
  },
  Portugal: {
    value: "PT",
    name: "PORTUGAL",
  },
  PuertoRico: {
    value: "PR",
    name: "PUERTO RICO",
  },
  Qatar: {
    value: "QA",
    name: "QATAR",
  },
  Reunion: {
    value: "RE",
    name: "REUNION",
  },
  Romania: {
    value: "RO",
    name: "ROMANIA",
  },
  Rwanda: {
    value: "RW",
    name: "RWANDA",
  },
  SaintHelena: {
    value: "SH",
    name: "SAINT HELENA",
  },
  SaintKittsAndNevis: {
    value: "KN",
    name: "SAINT KITTS AND NEVIS",
  },
  SaintLucia: {
    value: "LC",
    name: "SAINT LUCIA",
  },
  SaintPierreAndMiquelon: {
    value: "PM",
    name: "SAINT PIERRE AND MIQUELON",
  },
  SaintVincentAndTheGrenadines: {
    value: "VC",
    name: "SAINT VINCENT AND THE GRENADINES",
  },
  Samoa: {
    value: "WS",
    name: "SAMOA",
  },
  SanMarino: {
    value: "SM",
    name: "SAN MARINO",
  },
  SaoTomeAndPrincipe: {
    value: "ST",
    name: "SAO TOME AND PRINCIPE",
  },
  SaudiArabia: {
    value: "SA",
    name: "SAUDI ARABIA",
  },
  Senegal: {
    value: "SN",
    name: "SENEGAL",
  },
  SerbiaAndMontenegro: {
    value: "CS",
    name: "SERBIA AND MONTENEGRO",
  },
  Seychelles: {
    value: "SC",
    name: "SEYCHELLES",
  },
  SierraLeone: {
    value: "SL",
    name: "SIERRA LEONE",
  },
  Singapore: {
    value: "SG",
    name: "SINGAPORE",
  },
  Slovakia: {
    value: "SK",
    name: "SLOVAKIA",
  },
  Slovenia: {
    value: "SI",
    name: "SLOVENIA",
  },
  SolomonIslands: {
    value: "SB",
    name: "SOLOMON ISLANDS",
  },
  Somalia: {
    value: "SO",
    name: "SOMALIA",
  },
  SouthAfrica: {
    value: "ZA",
    name: "SOUTH AFRICA",
  },
  SouthGeorgiaAndTheSouthSandwichIslands: {
    value: "GS",
    name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
  },
  Spain: {
    value: "ES",
    name: "SPAIN",
  },
  SriLanka: {
    value: "LK",
    name: "SRI LANKA",
  },
  Sudan: {
    value: "SD",
    name: "SUDAN",
  },
  Suriname: {
    value: "SR",
    name: "SURINAME",
  },
  SvalbardAndJanMayenIslands: {
    value: "SJ",
    name: "SVALBARD AND JAN MAYEN ISLANDS",
  },
  Swaziland: {
    value: "SZ",
    name: "SWAZILAND",
  },
  Sweden: {
    value: "SE",
    name: "SWEDEN",
  },
  Switzerland: {
    value: "CH",
    name: "SWITZERLAND",
  },
  SyrianArabRepublic: {
    value: "SY",
    name: "SYRIAN ARAB REPUBLIC",
  },
  Taiwan: {
    value: "TW",
    name: "TAIWAN",
  },
  Tajikistan: {
    value: "TJ",
    name: "TAJIKISTAN",
  },
  Thailand: {
    value: "TH",
    name: "THAILAND",
  },
  TimorLeste: {
    value: "TL",
    name: "TIMOR-LESTE",
  },
  Togo: {
    value: "TG",
    name: "TOGO",
  },
  Tokelau: {
    value: "TK",
    name: "TOKELAU",
  },
  Tonga: {
    value: "TO",
    name: "TONGA",
  },
  TrinidadAndTobago: {
    value: "TT",
    name: "TRINIDAD AND TOBAGO",
  },
  Tunisia: {
    value: "TN",
    name: "TUNISIA",
  },
  Turkey: {
    value: "TR",
    name: "TURKEY",
  },
  Turkmenistan: {
    value: "TM",
    name: "TURKMENISTAN",
  },
  TurksAndCaicosIslands: {
    value: "TC",
    name: "TURKS AND CAICOS ISLANDS",
  },
  Tuvalu: {
    value: "TV",
    name: "TUVALU",
  },
  Uganda: {
    value: "UG",
    name: "UGANDA",
  },
  Ukraine: {
    value: "UA",
    name: "UKRAINE",
  },
  UnitedArabEmirates: {
    value: "AE",
    name: "UNITED ARAB EMIRATES",
  },
  UnitedKingdom: {
    value: "GB",
    name: "UNITED KINGDOM",
  },
  UnitedStates: {
    value: "US",
    name: "UNITED STATES",
  },
  UnitedStatesMinorOutlyingIslands: {
    value: "UM",
    name: "UNITED STATES MINOR OUTLYING ISLANDS",
  },
  Uruguay: {
    value: "UY",
    name: "URUGUAY",
  },
  Uzbekistan: {
    value: "UZ",
    name: "UZBEKISTAN",
  },
  Vanuatu: {
    value: "VU",
    name: "VANUATU",
  },
  Venezuela: {
    value: "VE",
    name: "VENEZUELA",
  },
  VietNam: {
    value: "VN",
    name: "VIET NAM",
  },
  WallisAndFutunaIslands: {
    value: "WF",
    name: "WALLIS AND FUTUNA ISLANDS",
  },
  WesternSahara: {
    value: "EH",
    name: "WESTERN SAHARA",
  },
  Yemen: {
    value: "YE",
    name: "YEMEN",
  },
  Zambia: {
    value: "ZM",
    name: "ZAMBIA",
  },
  Zimbabwe: {
    value: "ZW",
    name: "ZIMBABWE",
  },
};

export const Countries = Object.keys(RareCountries).map(key => ({
  name: (RareCountries as any)[key].name,
  value: (RareCountries as any)[key].value
}));
