import {useEffect, useState} from "react";
import Button from "../../components/Button";
import { orderPrise } from "../../network/PromoApi/promoApi";
import { priseOrderReq } from "../../network/PromoApi/promoInterfaces";
import ModalWrapper from "../../components/ModalWrapper";
import { OrderPriseRes } from "../../network/universalInterfaces";

const PrizeOfFirstProject = ({}) => {

    const [input, setInput] = useState<priseOrderReq>({});
    const [mailError, setMailError] = useState<boolean>(false);
    const [orderResponse, setOrderResponse] = useState<OrderPriseRes>()
    const [isModal, handleModal] = useState<boolean>(false)
    const [isErrorModal, toggleIsErrorModal] = useState<boolean>(false)
    const [modalChild, setModalChild] = useState<any>()

    const tryRequestOrder = () => {
        orderPrise(input).then((r: OrderPriseRes) => {
            setOrderResponse(r)
        })
    }

    useEffect(() => {
        if(orderResponse?.status === "ok") {
            setModalChild(<div><p className={`base`}>
                Мы получили Ваш вопрос и свяжемся с Вами по электронной почте в ближайшее время
         </p></div>)
            toggleIsErrorModal(false)
            handleModal(true)
        }
        else {
            setModalChild(<div><p className={`base`}>
                Похоже что-то пошло не так. <br /> Повторите попытку позднее
         </p></div>)
            toggleIsErrorModal(true)
            handleModal(true)
        }
    }, [orderResponse])

    return <div>
        <div className="flex flex-col items-center pt-16">
                <p className={`base font-bold text-[26px] leading-[35px] mb-5`}>
                    Заказ приза за первый проект </p>
                <div className={`flex flex-col items-start w-[460px] gap-3 mb-8`}>
                    <div className="relative w-full ">
                        <input
                            type="text"
                            id="name"
                            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                            placeholder=" "
                            required={true}
                            onChange={(event) => {
                                setInput({...input, name: event.currentTarget.value});
                            }}
                        />

                        <label
                            htmlFor="name"
                            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
                        >
                            Ваше имя *
                        </label>
                    </div>
                    <div className="relative w-full">
                        <input
                            type="text"
                            id="mail"
                            className={`block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page ${
                                mailError ? "border-CloseDeadlineAccent" : ""
                            } first_page`}
                            placeholder=" "
                            onChange={(event) => {
                                if (
                                    event.currentTarget.value.match(
                                        "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)"
                                    ) ||
                                    event.currentTarget.value.length === 0
                                ) {
                                    setMailError(false);
                                    setInput({...input, email: event.currentTarget.value});
                                } else {
                                    setMailError(true);
                                }
                            }}
                            required={true}
                        />
                        {mailError && (
                            <p className="text-[12px] leading-4 text-CloseDeadlineAccent mt-0.5 base">
                                Пожалуйста, проверьте введенный адрес
                            </p>
                        )}

                        <label
                            htmlFor="mail"
                            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
                        >
                            Введите E-Mail, указанный при регистрации проекта *
                        </label>
                    </div>
                    <div className="relative w-full ">
                        <textarea
                            
                            id="messasge"
                            className="block rounded-lg h-52 px-4 py-0 pt-4 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                            placeholder=" "
                            required={true}
                            onChange={(event) => {
                                setInput({...input, message: event.currentTarget.value});
                            }}
                        />

                        <label
                            htmlFor="message"
                            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
                        >
                            Ваш вопрос
                        </label>
                    </div>
                </div>
                <Button
                    text={`Отправить`}
                    className={`h-14 w-40 rounded-full`}
                    onClick={() => {
                        tryRequestOrder()
                    }}
                    disabled={!(input.name && input.email && input.message)}
                />
            </div> 
            <ModalWrapper
                isError = {isErrorModal}
                header={isErrorModal ? "Ошибка отправки": "Вопрос отправлен"}
                text={``}
                onClose={() => handleModal(false)}
                visible={isModal}
                    >
                        {modalChild}
                    </ModalWrapper>
    </div>
}

export default PrizeOfFirstProject
