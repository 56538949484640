import React, {useEffect} from "react";
import certs from "../../assets/img/certs.png";
import versatility from "../../assets/img/versatility.png";
import continuity from "../../assets/img/continuity.png";
import mobility from "../../assets/img/mobility.png";
import rewards from "../../assets/img/rewards.png";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import Button from "../../components/Button";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div className={`w-[795px]`}>
      <div className={`mt-5`}>
        <h1 className={`baseH1 text-MainAccent`}>
          Выстраивайте свое профессиональное будущее
        </h1>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-[36px]`}>
          Что такое программа Life-long Learning?
        </p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Программа непрерывного развития компетенций, которая разработана для
          специалистов проектных организаций. Это первая образовательная
          платформа для инженеров-проектировщиков.
        </p>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-[36px]`}>Зачем вам нужна эта программа?</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Чтобы развивать компетенции и быть востребованным специалистом в мире
          быстро меняющихся технологий.
        </p>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-[36px]`}>Преимущества</p>
        <div className={`flex flex-row justify-between mt-10 `}>
          <div>
            <img
              className={`object-contain h-[82px] w-[112px]`}
              src={certs}
              alt="certs"
            />
            <p
              className={`base text-SecondaryAccent text-[14px] text-center mt-4`}
            >
              Сертификаты
            </p>
          </div>
          <div>
            <img
              className={`object-contain h-[82px] w-[112px]`}
              src={versatility}
              alt="certs"
            />
            <p
              className={`base text-SecondaryAccent text-[14px] text-center mt-4`}
            >
              Гибкость
            </p>
          </div>
          <div>
            <img
              className={`object-contain h-[82px] w-[112px]`}
              src={continuity}
              alt="certs"
            />
            <p
              className={`base text-SecondaryAccent text-[14px] text-center mt-4`}
            >
              Непрерывность
            </p>
          </div>
          <div>
            <img
              className={`object-contain h-[82px] w-[112px]`}
              src={mobility}
              alt="certs"
            />
            <p
              className={`base text-SecondaryAccent text-[14px] text-center mt-4`}
            >
              Мобильность
            </p>
          </div>
          <div>
            <img
              className={`object-contain h-[82px] w-[112px]`}
              src={rewards}
              alt="certs"
            />
            <p
              className={`base text-SecondaryAccent text-[14px] text-center mt-4`}
            >
              Награды
            </p>
          </div>
        </div>
      </div>
      <div className={`mt-14`}>
        <p className={`base text-[36px]`}>Как устроено обучение?</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          На платформе есть три типа обучения: профессиональные курсы,
          экспертные сессии и видеоподкасты «Кофе с экспертом».
        </p>
      </div>
      <div className={`mt-6`}>
        <p className={`base text-[20px]`}>
          Профессиональные курсы
        </p>
        <p className={`base text-SecondaryAccent-dark mt-2 text-[15px]`}>
          помогают глубоко изучить тему и получить сертификат о прохождении.
          Обучение удобно разделено по направлениям, затем в каждом направлении
          вы можете последовательно или выборочно проходить нужные вам курсы.
          Курс может состоять из одного или нескольких уроков, а также
          обязательного тестирования. При успешном прохождения теста вы
          получаете сертификат. Некоторые курсы заблокированы, пока вы не
          пройдёте предваряющее их обучение.
        </p>
        <div className={`mt-4`}>
          <Link to={AppRoutes.Courses}>
            <Button
              text={"Профессиональные курсы"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
      </div>
      <div className={`mt-8`}>
        <p className={`base text-[20px]`}>
          Экспертные сессии
        </p>
        <p className={`base text-SecondaryAccent-dark mt-2 text-[15px]`}>
          Это живые и записанные вебинары от практиков-экспертов. Один вебинар
          длится от 1 часа. Сессии также разделены по направлениям для удобства
          поиска. Вы можете записаться на предстоящую сессию и присутствовать на
          ней онлайн, сразу получать ответы на свои вопросы. Если желаемая
          сессия завершена, вы всегда можете найти её запись в разделе
          «Экспертные сессии». А также посмотреть расписание грядущих экспертных
          сессий в календаре.
        </p>
        <div className={`mt-4`}>
          <Link to={`${AppRoutes.Courses}#expert`}>
            <Button
              text={"Экспертные сессии"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
      </div>
      <div className={`mt-8`}>
        <p className={`base text-[20px]`}>
          Видеоподкаст «Кофе с экспертом»
        </p>
        <p className={`base text-SecondaryAccent-dark mt-2 text-[15px]`}>
          10-15 минутные видео от признанных экспертов в своей области.
        </p>
        <div className={`mt-4`}>
          <Link to={`${AppRoutes.Courses}#coffee`}>
            <Button
              text={"Кофе с экспертом"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
      </div>
      <div className={`mt-16`}>
        <p className={`base text-[36px]`}>Сертификаты</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          За прохождение Профессиональных курсов и Экспертных сессий вы можете
          получить сертификат, который подтвердит ваши новые знания. Все ваши
          сертификаты хранятся в специальном разделе Сертификаты, откуда вы
          можете скачать их в любой момент.
        </p>
        <div className={`mt-4`}>
          <Link to={AppRoutes.Certificates}>
            <Button
              text={"Сертификаты"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
      </div>
      <div className={`mt-16`}>
        <p className={`base text-[36px]`}>Учебный кабинет</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Весь ваш прогресс фиксируется на странице Учебного кабинета. В любой
          момент вы можете увидеть курсы, сессии и видеоподкасты, на которые
          записаны, находитесь в процессе изучения или завершили. Также из
          учебного кабинета можно записаться на вебинар или скачать сертификат.
        </p>
        <div className={`mt-4`}>
          <Link to={AppRoutes.UserCabinet}>
            <Button
              text={"Учебный кабинет"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
      </div>
      <div className={`mt-16`}>
        <p className={`base text-[36px]`}>Награды и уровни</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          При прохождении курсов каждый пользователь автоматически переходит на
          более сложный уровень, а также накапливает внутреннюю валюту платформы
          — монеты. Монеты нельзя обменять на деньги, но вот купить на них
          полезные подарки можно. После оформления подарок будет доставлен вам
          курьерской службой DHL.
        </p>
        <div className={`mt-4 gap-10 flex-row flex`}>
          <Link to={AppRoutes.Level}>
            <Button
              text={"Награды и уровни"}
              className={`border border-MainAccent text-MainAccent bg-white hover:text-white `}
            />
          </Link>
        </div>
        <div className={`mt-10 mb-20`}>
          <p className={`base`}>
            Все возникающие вопросы можно уточнить в{" "}
            <Link to={AppRoutes.Feedback}>
              <strong>службе поддержки.</strong>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
