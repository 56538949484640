import React from "react";
import down from "../../assets/img/promoDown.svg";

export type SelectorItemType = {
  value: string;
  name: string;
};

type IProps = {
  setInput: (e: string, c: string) => void;
  items: SelectorItemType[]
  select?: string;
  label?: string
  
};

const MainSelector = (p: IProps) => {
  const renderedCountries = p.items.map((el) => {
    return (
      <p
        className="text-sm font-normal text-unselected hover:bg-MainAccent-hover hover:bg-opacity-10 cursor-pointer py-2 px-4 option"
        onClick={(event) => {
          if (selector) {
            selector.blur();
          }
          p.setInput(el.name, el.value || "");
        }}
      >
        {el.name}
      </p>
    );
  });

  const selector = document.getElementById("selector");
  return (
    <div
      className="flex items-center relative rounded-[32px] h-12 w-full group focus:border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark "
      tabIndex={0}
      id="selector"
    >
      <span className={`pl-4 text-sm base`} id="select">
        {!p.select ? p.label : p.items.find(i=> i.value === p.select)?.name }
      </span>
      <img
        src={down}
        className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
        alt=""
      ></img>
      <div className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 shadow-plate rounded-[32px] overflow-scroll max-h-[400px]">
        {renderedCountries}
      </div>
    </div>
  );
};

export default MainSelector;
