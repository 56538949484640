import CommandEndpoints from "../../structure/commandEndpoints";
import { request } from "../mainRequest";
import { MessageRes, StatusRes } from "../universalInterfaces";
import {
  BuySubscriptionReq,
  DeleteSubordinatesReq,
  GetCommandRes,
  GetSubscribeRes,
  GetTeamCoursesReq,
  GetTeamCoursesRes,
  SubscribeListReq,
  SubscribeSubordinatesReq,
  SubscribeSubordinateRes,
} from "./commandInterfaces";

export function getCommand(): Promise<GetCommandRes> {
  return request(CommandEndpoints.GetCommandApi);
}

export function deleteSubordinates(
  data: DeleteSubordinatesReq
): Promise<MessageRes> {
  return request(CommandEndpoints.DeleteSubordinates,"post", data);
}

export function subscribeSubordinates(
  data: SubscribeSubordinatesReq
): Promise<Array<SubscribeSubordinateRes>> {
  return request(CommandEndpoints.SubscribeSubordinates,"post", data);
}

export function getTeamCourses(
  data: GetTeamCoursesReq
): Promise<GetTeamCoursesRes> {
  return request(CommandEndpoints.GetTeamCourses,"post", data);
}

export function getSubscribes(): Promise<Array<GetSubscribeRes>> {
  return request(CommandEndpoints.GetSubscribes);
}

export function subscribe(data: SubscribeListReq): Promise<StatusRes> {
  return request(CommandEndpoints.Subscribe,"post", data);
}

export function buySubscription(
  data: BuySubscriptionReq
): Promise<StatusRes> {
  return request(CommandEndpoints.BuySubscription,"post", data);
}
