import React, { useEffect, useRef, useState } from "react";
import { GetDataRes, SetDataReq } from "../../network/UserApi/userInterfaces";
import { getData, setAvatar, setData } from "../../network/UserApi/userApi";
import Button from "../../components/Button";
import InputForm from "../../components/InputForm";
import { baseURL } from "../../config";

function getResultForm(user: GetDataRes): SetDataReq {
  return {
    name: user.first_name,
    lastname: user.last_name,
    mail: user.email,
    patronomic: user.patronomic,
    phone: user.phone,
    company: user.company,
    select: user.country,
  };
}
export const UserPage = () => {
  const [change, setChange] = useState<boolean>(false);
  const [user, setUser] = useState<GetDataRes>();

  useEffect(() => {
    getData().then((r) => {
      setUser(r);
    });
          window.scrollTo(0,0)
  }, []);

  const file = useRef<any>(null);

  const fields = [
    { name: "Имя*", value: "first_name", data: user?.first_name },
    { name: "Отчество", value: "patronomic", data: user?.patronomic },
    { name: "Фамилия*", value: "last_name", data: user?.last_name },
    { name: "E-mail*", value: "email", data: user?.email },
    { name: "Телефон", value: "phone", data: user?.phone },
    { name: "Компания", value: "company", data: user?.company },
    {
      name: "Страна",
      value: "country",
      selector: true,
      data: user?.country,
      arrow: true,
      userSelector: true,
    },
  ];

  const renderedFields = fields.map((el) => {
    return (
      <div className={`flex flex-row mt-2 ml-[48px]`}>
        <p className={`base w-[100px]`}>{el.name}</p>
        <InputForm
          onChange={(v) => {
            let localUser: any = user;
            localUser[el.value] = v;
            setChange(true);
            setUser({ ...localUser });
          }}
          placeholder={el.data || "не задан"}
          className={`ml-3 border-b w-[300px] outline-0`}
          name={el.name}
          value={el.data}
          isSelector={el.selector}
          arrow={el.arrow}
          userSelector={el.userSelector}
        />
      </div>
    );
  });
  return (
    <div>
      <div className={`flex mt-5`}>
        <div>
          <div className={`w-[192px] h-[216px] relative group`}>
            <img
              src={baseURL + user?.avatar}
              alt="avatar"
              className={"object-cover w-full h-full object-center"}
            />
            <input
              type="file"
              className={`hidden`}
              ref={file}
              onChange={(event) => {
                if (event.currentTarget.files) {
                  setAvatar(event.currentTarget.files[0]);
                }
              }}
            />
            <Button
              onClick={() => {
                file.current.click();
              }}
              text={`Заменить фото`}
              className={`hidden group-hover:block absolute top-[80px] left-[30px] z-50 opacity-70 bg-SecondaryAccent-veryLight text-SecondaryAccent-dark hover:bg-SecondaryAccent hover:text-black`}
            />
          </div>
        </div>
        <div>
          <p className={`ml-[48px] base text-[36px]`}>Личный кабинет</p>
          <div className={`mt-5`}>{renderedFields}</div>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            if (user)
              setData(getResultForm(user)).then(() => {
                setChange(false);
              });
          }}
          text={"Сохранить изменения"}
          className={`ml-[235px] mt-10`}
          disabled={!change}
        />
        {/*<Button*/}
        {/*  text={"Изменить имя и пароль"}*/}
        {/*  className={`ml-[235px] mt-3 bg-white text-MainAccent border border-MainAccent hover:text-white`}*/}
        {/*/>*/}
      </div>
      <div>
        <p className={`ml-[235px] mt-20 base text-[24px]`}>Подписка</p>
      </div>
    </div>
  );
};
