import React from "react";
import { GetLoginsRes } from "../network/UserApi/userInterfaces";
import { getPercent } from "../utils/percentHandler";

type IProps = GetLoginsRes;

const Diagram = (p: IProps) => {
  const months = Object.entries(p.data).map(([key, val]) => {
    return (
      <div
        className={`w-6 h-1 border-l border-b border-l-SecondaryAccent border-b-SecondaryAccent`}
      >
        <div
          className={`absolute w-2 mt-0 ml-2 bottom-[21px] bg-MainAccent max-h-[120px]`}
          style={{ height: `${getPercent(val, p.max)}%` }}
        ></div>
        <p
          className={`pt-1 text-center text-SecondaryAccent baseMicro text-[0.625em]`}
        >
          {key}
        </p>
      </div>
    );
  });

  return <div className={`relative flex pt-[125px]`}>{months}</div>;
};

export default Diagram;
