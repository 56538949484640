import React, { useEffect, useState } from "react";
import RecomendationCard from "./RecomendationCard";
import {
  GetInfoCourseRes,
  GetRecomendationsRes,
} from "../network/CourseApi/courseInterfaces";
import { UserCertificate } from "../network/UserApi/userInterfaces";
import { getCertificates } from "../network/UserApi/userApi";
import Button from "./Button";
import { Link, useLocation } from "react-router-dom";
import AppRoutes from "../structure/routes";
import { buyCourse, getinfoCourse } from "../network/CourseApi/courseApi";
import ModalWrapper from "./ModalWrapper";
import CertSmallCard from "./CertSmallCard";

type IProps = {
  lauraRender: boolean;
  recomendationsRender: boolean;
  certificatesRender: boolean;
  buyRender: boolean;
  recomendations: Array<GetRecomendationsRes>;
};
type ModalStep = {
  step: "init" | "end";
};

const Sidebar = (p: IProps) => {
  const [modalStep, setModalStep] = useState<ModalStep>({ step: "init" });
  const [modal, setModal] = useState<boolean>(false);
  const [certificates, setCertificates] = useState<Array<UserCertificate>>();
  const [course, setCourse] = useState<GetInfoCourseRes>();

  let args: Array<string> = [];
  const handleBuySubmit = (e: any) => {
    e.preventDefault();
    for (let i = 0; i <= 2; i++) {
      args.push(e.target[i].value);
    }
    buyCourse({
      courseId: course?.id.toString() || "",
      course_name: course?.name || "",
      name: args[0],
      mail: args[1],
      phone: args[2],
    }).then((r) => {
      setModalStep({ step: "end" });
    });
  };

  useEffect(() => {
    getCertificates().then((r) => {
      setCertificates(r);
    });
  }, []);
  useEffect(() => {
    getinfoCourse({ id: current ? current : "" }).then((r) => {
      setCourse(r);
    });
  }, []);
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("course");

  const renderedCards = p.recomendations.map(
    (recomendation: GetRecomendationsRes) => {
      return (
        <RecomendationCard
          imgSrc={recomendation.course_image}
          courseId={recomendation.course_id}
          cardText={recomendation.course_name}
        />
      );
    }
  );

  let coursCardSkeletons: any = []

  for (let i = 0; i < 2; i++) {
    coursCardSkeletons.push(<div role="status"
      className="w-[311px] h-[112px] my-5 space-y-8 animate-pulse rtl:space-x-reverse">
      {/* <div className="flex flex-col items-center justify-center w-full h-36 bg-gray-300 rounded dark:bg-gray-400">
        <svg className="w-10 h-10 text-gray-200 dark:bg-gray-400" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div> */}
      <div className="h-full w-full bg-gray-200 dark:bg-gray-400 mb-10"></div>
    </div>)
  }


  return (
    <div className="max-w-[312px] pb-[100px]">
      {p.lauraRender && (
        <div className={`w-[312px] h-[512px] `}>
          <iframe
            src="https://dsr.ispvds.com/"
            width={312}
            height={512}
            title="Laura"
            style={{ zoom: "95%" }}
          ></iframe>
        </div>
      )}
      {p.certificatesRender && (
        <div className="mt-14">
          <p className={`text-xl `}>Мои Сертификаты</p>
          <div className={`mt-[30px] flex flex-wrap justify-between`}>
            <div className="pt-4 pb-4 leading-6 text-SecondaryAccent">
              {certificates?.length && (
                <div>
                  <div className={`flex flex-row`}>
                    {certificates.map((el, key) => {
                      if (key > 1) {
                        return;
                      }
                      return (
                        <CertSmallCard
                          code={el.code}
                          name={el.name}
                          description={el.description}
                          link={el.link}
                          date={el.date}
                        />
                      );
                    })}
                  </div>
                  <Link to={AppRoutes.Certificates}>
                    <Button
                      text={"Все сертификаты"}
                      className={`border border-MainAccent text-MainAccent bg-white hover:text-white`}
                    />
                  </Link>
                </div>
              )}
              {!certificates?.length && (
                <p>
                  Вы ещё не получили сертификатов, как только получите, они
                  появятся здесь
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {p.recomendationsRender && (
        <div>
          <div className={`mt-14 `}>
            <p className="text-xl mb-[5px]">Рекомендованные курсы</p>
            {renderedCards.length > 0 ? renderedCards : coursCardSkeletons}
          </div>
        </div>
      )}
      {p.buyRender && (
        <div className={`flex items-start`}>
          <div>
            <p className={`baseBold text-[14px]`}>
              Стоимость: {course?.price} рублей
            </p>
            <p className={`base text-[14px]`}>
              Можно оплатить курс на сайте партнера или оформить доступ по
              подписке
            </p>
            <Button
              className={`mt-3`}
              text={"Оплатить"}
              onClick={() => {
                setModal(true);
              }}
            />
          </div>
          <ModalWrapper
            header={"Заявка на оплату"}
            text={""}
            onClose={() => {
              setModal(!modal);
              setModalStep({ step: "init" });
            }}
            visible={modal}
          >
            <div className={`flex flex-col items-start base text-[14px] `}>
              {modalStep.step === "init" && (
                <form onSubmit={(event) => handleBuySubmit(event)}>
                  <label className={`mb-2 flex flex-row`}>
                    <p className={`w-[50px]`}>Имя</p>
                    <input
                      type="text"
                      name="name"
                      id=""
                      className={`border ml-6`}
                    />
                  </label>
                  <label className={`mb-2 flex flex-row`}>
                    <p className={`min-w-[50px]`}>E-Mail</p>
                    <input
                      type="text"
                      name="email"
                      id=""
                      className={`border ml-6`}
                    />
                  </label>
                  <label className={`mb-2 flex flex-row`}>
                    <p className={`w-[50px]`}>Телефон</p>
                    <input
                      type="text"
                      name="phone"
                      id=""
                      className={`border ml-6`}
                    />
                  </label>
                  <Button text={"Отправить"} className={`mt-4 mb-6`} />
                </form>
              )}
              {modalStep.step === "end" && (
                <div>
                  <p className={`base`}>
                    Ваша заявка принята и скоро с вами свяжутся
                  </p>
                </div>
              )}
            </div>
          </ModalWrapper>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
