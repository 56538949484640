import ShopEndpoints from "../../structure/shopEndpoints";
import { request } from "../mainRequest";
import { SuccessRes } from "../universalInterfaces";
import { GetShopItemReq, GetShopItemRes, ShopOrderReq } from "./shopInterfaces";

export function getShopItem(data: GetShopItemReq): Promise<GetShopItemRes> {
  return request(ShopEndpoints.GetShopItem,"post", data);
}

export function getShopItems(): Promise<Array<GetShopItemRes>> {
  return request(ShopEndpoints.GetShopItems,"post", { page: 0, page_size: 1000 });
}

export function shopOrder(data: ShopOrderReq): Promise<SuccessRes> {
  return request(ShopEndpoints.ShopOrder,"post", data);
}
