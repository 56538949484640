import React, {useEffect} from 'react';

export const Session = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
  return (
    <div>
      <h1>Session Page</h1>
    </div>
  );
};