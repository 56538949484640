import CourseEndpoints from "../../structure/courseEndpoints";
import { request } from "../mainRequest";
import { MessageRes, OkRes } from "../universalInterfaces";
import {
  AcceptStepCourseReq,
  BuyCourseReq,
  EndTestRes,
  EnrollToLpReq,
  GetArchiveCountRes,
  GetContiuningEducationReq,
  GetContiuningEducationRes,
  GetCoursesReq,
  GetCoursesRes,
  GetEducationCourseReq,
  GetEducationCourseRes,
  GetIframeTestReq,
  GetIframeTestRes,
  GetInfoCourseReq,
  GetInfoCourseRes,
  GetLastSessionsRes,
  GetLpInfoReq,
  GetLpInfoRes,
  GetRecomendationsRes,
  GetRecordOfSessionReq,
  GetRecordOfSessionRes,
  GetStartTestReq,
  GetStartTestRes,
  GetTestNextStepReq,
  GetTestNextStepRes,
  GetVebinarInfoReq,
  GetVebinarInfoRes,
  GetVideoOfLoYoutubeReq,
  GetVideoOfLoYoutubeRes,
  UserRegisterReq,
} from "./courseInterfaces";

export function getContiuningEducation(
  data: GetContiuningEducationReq
): Promise<Array<GetContiuningEducationRes>> {
  return request(CourseEndpoints.GetContiuningEducation,"post", data);
}

export function getLastSessions(): Promise<GetLastSessionsRes> {
  return request(CourseEndpoints.GetLastSessions);
}

export function getCourses(data: GetCoursesReq): Promise<GetCoursesRes> {
  return request(CourseEndpoints.GetCourses,"post", data);
}

export function getLpInfo(data: GetLpInfoReq): Promise<GetLpInfoRes> {
  return request(CourseEndpoints.GetLpInfo,"post", data);
}

export function enrollToLp(data: EnrollToLpReq): Promise<MessageRes> {
  return request(CourseEndpoints.EnrollToLp,"post", data);
}

export function userRegister(data: UserRegisterReq): Promise<MessageRes> {
  return request(CourseEndpoints.UserRegister,"post", data);
}

export function acceptStep(data: AcceptStepCourseReq): Promise<OkRes> {
  return request(CourseEndpoints.AcceptStep,"post", data);
}

export function acceptCourse(data: AcceptStepCourseReq): Promise<OkRes> {
  return request(CourseEndpoints.AcceptCourse,"post", data);
}

export function getArchiveCount(): Promise<GetArchiveCountRes> {
  return request(CourseEndpoints.GetArchiveCount);
}

export function getinfoCourse(
  data: GetInfoCourseReq
): Promise<GetInfoCourseRes> {
  return request(CourseEndpoints.GetInfoCourse,"post", data);
}

export function getVebinarInfo(
  data: GetVebinarInfoReq
): Promise<GetVebinarInfoRes> {
  return request(CourseEndpoints.GetVebinarInfo,"post", data);
}

export function getEducationCourse(
  data: GetEducationCourseReq
): Promise<GetEducationCourseRes> {
  return request(CourseEndpoints.GetEducationCourse,"post", data);
}

export function getVideoOfLo(
  data: GetVideoOfLoYoutubeReq
): Promise<GetVideoOfLoYoutubeRes> {
  return request(CourseEndpoints.GetVideoOfLo,"post", data);
}

export function getYoutubeVideo(
  data: GetVideoOfLoYoutubeReq
): Promise<GetVideoOfLoYoutubeRes> {
  return request(CourseEndpoints.GetYoutubeVideo,"post", data);
}

export function getIframeTest(
  data: GetIframeTestReq
): Promise<GetIframeTestRes> {
  return request(CourseEndpoints.GetIframeTest,"post", data);
}

export function getStartTest(data: GetStartTestReq): Promise<GetStartTestRes> {
  return request(CourseEndpoints.GetStartTest,"post", data);
}

export function getTestNextStep(
  data: GetTestNextStepReq
): Promise<GetTestNextStepRes> {
  return request(CourseEndpoints.GetTestNextStep,"post", data);
}

export function endTest(data: GetTestNextStepReq): Promise<EndTestRes> {
  return request(CourseEndpoints.EndTest,"post", data);
}

export function getRecomendations(): Promise<Array<GetRecomendationsRes>> {
  return request(CourseEndpoints.GetRecomendations);
}

export function getRecordOfSession(
  data: GetRecordOfSessionReq
): Promise<GetRecordOfSessionRes> {
  return request(CourseEndpoints.GetRecordOfSession,"post", data);
}

export function buyCourse(data: BuyCourseReq): Promise<MessageRes> {
  return request(CourseEndpoints.BuyCourse,"post", data);
}
