import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetShopItemRes } from "../../network/ShopApi/shopInterfaces";
import { getShopItem } from "../../network/ShopApi/shopApi";
import Button from "../../components/Button";
import { GetHeaderRes } from "../../network/UserApi/userInterfaces";
import { getHeader } from "../../network/UserApi/userApi";
import AppRoutes from "../../structure/routes";
import { createPath } from "../../utils/linkHandler";

export const ShopItem = () => {
  const [item, setItem] = useState<GetShopItemRes>();
  const [user, setUser] = useState<GetHeaderRes>();

  useEffect(() => {
    getHeader().then((r) => {
      setUser(r);
    });
      window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    getShopItem({ item_id: Number(current) }).then((r) => {
      setItem(r);
    });
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("id");
  const handleClick = () => {
    navigate(
      createPath({
        route: AppRoutes.Order,
        args: [{ key: "id", value: item?.id || 0 }],
      }),
      { replace: true }
    );
  };
  return (
    <div>
      <div className={`mt-5`}>
        <p className={`base text-[24px]`}>{item?.name}</p>
      </div>
      <div className={`flex justify-between mt-6`}>
        <div className={`min-w-[312px]`}>
          <img src={item?.image} alt="item" className={`w-full`} />
        </div>
        <div className={`mr-[48px]`}>
          <p className={`base`}>{item?.description}</p>
          <p className={`base text-SecondaryAccent mt-4 text-[14px]`}>
            {item?.price} монет
          </p>
          <div className={`mt-6`}>
            <div>
              <Button
                onClick={handleClick}
                text={"Заказать"}
                disabled={!((user?.user_coins || 0) >= (item?.price || 1))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
