import { Link, useNavigate } from "react-router-dom";
import Button from "../Button";
import MainInput from "../welcome/MainInput";
import ModalWrapper from "../ModalWrapper";
import { useCallback, useState } from "react";
import arrow from "../../assets/img/welcome/arrow.svg";
import MainSelector, { SelectorItemType } from "../welcome/MainSelector";
import Tabbar from "./Tabbar";
import { checkKeys, isValidINN, isValidNumber } from "../../utils/data";
import { promoAuth, promoRegister } from "../../network/PromoApi/promoApi";
import {
  PromoErrorCode,
  PromoSpecialization,
  PromoType,
  UserPromoRole,
} from "../../network/PromoApi/promoInterfaces";
import AppRoutes from "../../structure/routes";
import { useAuth } from "../../Providers/PromoUserProvider";
import SpecializationSelect from "./SpecializationSelect";

interface IProps {
  promoType: PromoType;
  visible?: boolean;
  onClose?: () => void;
  onOpenRecovery?: () => void
}

export interface IRegisterData {
  login?: string;
  password?: string;
  name?: string;
  lastName?: string;
  INN?: string;
  phone?: string;
  state?: string;
  company?: string;
  type?: PromoType;
  specializtion?: PromoSpecialization;
  patronomyc?: string;
}

type ModalVariants = "waitAlert" | "registered" | "conflictError" | "declined" | "startRecover" ;

const ALRET_TEXT: {
  [key: string]: {
    title: string;
    text: string;
  };
} = {
  waitAlert: {
    title: "Ваша заявка на участие в акции принята",
    text: "Ваша заявка на участие в акции принята. Она находится на рассмотрении. После подтверждения участия со стороны Systeme Electric, Вам будет направлено письмо о подтверждении регистрации.",
  },
  registered: {
    title: "Ваша заявка на участие в акции принята",
    text: "Она находится на рассмотрении. Повторите попытку после получения письма с ID компании",
  },
  declined: {
    title: "Заявка отклонена",
    text: "Ваша заявка на участие в акции была отклонена",
  },
  conflictError: {
    title: "Пользователь уже зарегистрирован",
    text: "Пользователь с таким email уже зарегистрирован",
  },
};
const TABS = ["Авторизация", "Регистрация"];

const LoginModal = (props: IProps) => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useAuth();

  const [passError, setPassError] = useState<boolean>(false);
  const [mailError, setMailError] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<boolean>(false);
  const [isMarked, setIsMarked] = useState<boolean>(false);

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const [copyPassword, setCopyPassword] = useState<string>();

  const [authData, setAuthData] = useState<IRegisterData>({
    type: props.promoType,
  });

  const [modalType, setModalType] = useState<ModalVariants>();

  const copyPasswordError =
    authData.password !== undefined &&
    copyPassword !== undefined &&
    authData.password !== copyPassword;

  const INNError = authData.INN !== undefined && !isValidINN(authData.INN);
  const phoneError =
    authData.phone !== undefined && !isValidNumber(authData.phone);

  const onChangeTab = useCallback((index: number) => {
    setCurrentTabIndex(index);
  }, []);

  const setKey = useCallback(
    (key: keyof IRegisterData, data: string) => {
      setAuthData({ ...authData, [key]: data });
    },
    [authData]
  );

  const checkKeysData = checkKeys<IRegisterData>;

  const tabValue = useCallback(
    (index: number) => currentTabIndex !== index && "hidden",
    [currentTabIndex]
  );

  const toggleMark = useCallback(() => {
    setIsMarked((m) => !m);
  }, []);

  const onAuth = useCallback(() => {
    setPassError(false);
    if (authData.login && authData.password)
      promoAuth({ login: authData.login, password: authData.password }).then(
        (r) => {
          if (r.status == "ok") {
            setUserInfo(r.data);
            if (r.data.role === UserPromoRole.ADMIN)
              navigate(AppRoutes.PromoAdmn);
            else navigate(AppRoutes.PromoCabinet);
          } else {
            if (r.code == PromoErrorCode.NotFound) {
              setPassError(true);
            } else if (r.code == PromoErrorCode.Unauthorized) {
              setModalType("registered");
            } else if (r.code == PromoErrorCode.Decline) {
              setModalType("declined");
            }
          }
        }
      );
  }, [authData]);

  const onRegister = useCallback(() => {
    promoRegister(authData).then((r) => {
      if (r.status == "ok") {
        setModalType("waitAlert");
      } else {
        if (r.code === PromoErrorCode.Conflict) {
          setModalType("conflictError");
        }
      }
    });
  }, [authData]);
  return (
    <ModalWrapper
      mainClass={`rounded-[32px] min-w-[500px] sm:min-w-[350px]`}
      withoutBanner={true}
      header={``}
      text={``}
      onClose={props.onClose}
      visible={props.visible}
      crossClass={`p-[3%] `}
    >
      {modalType === undefined ? (
        <div>
          <div>

        
          <Tabbar titles={TABS} onSelect={onChangeTab} />
          <div className={`flex flex-col items-start pr-4 mb-8 ${tabValue(0)}`}>
            <div className={`base text-[32px] `}>
              <p>Авторизация</p>
            </div>
            <div className={`flex flex-col w-full`}>
              <div className={`mt-10`}>
                <MainInput
                  onChange={(e) => {
                    setKey("login", e);
                  }}
                  id={"mail"}
                  name={"E-mail"}
                />
                <div className={`mt-4`}>
                  <MainInput
                    onChange={(e) => {
                      setKey("password", e);
                    }}
                    id={"password"}
                    name={"Пароль"}
                  />
                  {passError && (
                    <p
                      className={`baseMicro mt-1 text-CloseDeadlineAccent
              `}
                    >
                      Неверный логин или пароль
                    </p>
                  )}
                </div>
                <div className={`mt-8`}>
                  <Button
                    text={"Войти"}
                    className={`h-[62px] rounded-[32px] font-semibold text-[18px] base w-full`}
                    disabled={!checkKeysData(authData, ["login", "password"])}
                    onClick={onAuth}
                  />
                </div>
                <div className={`flex flex-row justify-center base mt-10`}>
                  <p>Забыли пароль?</p>
                  <p
                    className={`ml-3 text-MainAccent cursor-pointer font-semibold`}
                    onClick={() => {
                      props.onOpenRecovery && props.onOpenRecovery()
                    }}
                  >
                    Восстановить
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col items-start px-4 mb-16 ${tabValue(1)}`}
          >
            <div className={`flex flex-col w-full]`}>
              <div>
                <MainInput
                  error={mailError}
                  onChange={(e) => {
                    setRegisterError(false);
                    if (
                      e.match(
                        "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)"
                      ) ||
                      e.length === 0
                    ) {
                      setMailError(false);
                      setKey("login", e);
                    } else {
                      setMailError(true);
                    }
                  }}
                  id={"mail"}
                  name={"E-mail"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  onChange={(e) => {
                    setKey("name", e);
                  }}
                  id={"name"}
                  name={"Имя"}
                />
                <MainInput
                  onChange={(e) => {
                    setKey("lastName", e);
                  }}
                  id={"lastname"}
                  name={"Фамилия"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  onChange={(e) => {
                    setKey("patronomyc", e);
                  }}
                  id={"patronomic"}
                  name={"Отчество"}
                />
                <SpecializationSelect
                  type={props.promoType}
                  onInput={(value) => {
                    setKey("specializtion", value);
                  }}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  error={copyPasswordError}
                  onChange={(e) => {
                    setKey("password", e);
                  }}
                  id={"password"}
                  name={"Пароль"}
                />
                <MainInput
                  error={copyPasswordError}
                  onChange={(e) => {
                    setCopyPassword(e);
                  }}
                  id={"confirmPass"}
                  name={"Повторите пароль"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  onChange={(e) => {
                    setKey("INN", e);
                  }}
                  error={INNError}
                  id={"inn"}
                  name={"ИНН"}
                />
                <MainInput
                  onChange={(e) => {
                    setKey("phone", e);
                  }}
                  error={phoneError}
                  id={"phone"}
                  className="pl-10"
                  prefix="+7 | "
                  name={"Номер телефона"}
                />
              </div>
              <div className={`flex flex-col gap-4 mt-4`}>
                <MainInput
                  onChange={(e) => {
                    setKey("company", e);
                  }}
                  id={"company"}
                  name={"Компания"}
                />
                <MainInput
                  onChange={(e) => {
                    setKey("state", e);
                  }}
                  id={"state"}
                  name={"Должность"}
                />
                <p
                  className={`baseMicro mt-1 text-CloseDeadlineAccent ${
                    registerError ? "" : "hidden"
                  }`}
                >
                  Адрес электронной почты занят
                </p>
              </div>
              <div className="flex items-start my-[30px]">
                <div
                  onClick={toggleMark}
                  className={`min-w-[15px] min-h-[15px] mr-[10px] cursor-pointer border-2 border-SecondaryAccent-one ${
                    isMarked ? "bg-MainAccent" : ""
                  }`}
                ></div>
                <p className="baseMicro text-sm">
                  Заполняя настоящую регистрационную форму, я даю согласие
                  акционерному обществу «Систэм Электрик» на обработку
                  содержащихся в ней персональных данных в соответствии с
                  условиями и порядком обработки моих персональных данных,
                  установленных{" "}
                  <Link
                    className="baseUnderline text-sm text-MainAccent"
                    to="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                  >
                    {" "}
                    Политикой акционерного общества «Систэм Электрик»{" "}
                  </Link>{" "}
                  в отношении обработки персональных данных и сведениями о
                  реализуемых требованиях к защите персональных данных, в целях
                  получения информации о продуктах и доступных решениях Систэм
                  Электрик, акцептом которых является заполнение данной формы
                </p>
              </div>
              <Button
                text={"Зарегистрироваться"}
                className={`h-[72px] rounded-[32px] font-semibold text-[18px] base`}
                disabled={
                  mailError ||
                  !checkKeysData(authData) ||
                  copyPasswordError ||
                  INNError ||
                  phoneError ||
                  !isMarked
                }
                onClick={onRegister}
              />
            </div>
          </div>
        </div>
        </div>
      ) : (
        <div>
          <div>
            <div className={`flex flex-col items-start pr-4 mb-8 mt-4`}>
              <div className={`base text-[32px] `}>
                <p>{ALRET_TEXT[modalType].title}</p>
              </div>
              <div className={`base mt-12`}>
                <p>{ALRET_TEXT[modalType].text}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default LoginModal;
