import clsx from 'clsx';
import React from 'react';
import SortAscIcon from './icons/SortAscIcon';
import SortDescIcon from './icons/SortDescIcon';
import UnsortIcon from './icons/UnsortIcon';

const TableSortIcons = {
	asc: <SortAscIcon />,
	desc: <SortDescIcon />,
	none: <UnsortIcon />,
};

interface Props {
	children?: React.ReactNode;
	active?: boolean;
	direction?: 'asc' | 'desc';
	onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const TableSortLabel = ({
	active = false,
	direction = 'desc',
	children,
	onClick,
}: Props) => {
	return (
		<div
			className='flex items-center gap-2 transition-all hover:text-gray-3'
			onClick={onClick}
		>
			<span className='uppercase'>{children}</span>
			<span
				className={clsx(
					'opacity-0 transition-all',
					direction == 'asc' && '-scale-y-100 transform',
					active && 'opacity-100'
				)}
			>
				<SortAscIcon />
			</span>
		</div>
	);
};

export default TableSortLabel;
