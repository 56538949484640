enum CourseEndpoints {
  GetContiuningEducation = "/get/user/contiuning_enducation",
  GetLastSessions = "/get/courses/last_sessions",
  GetCourses = "/get/courses/courses",
  GetLpInfo = "/get/course/lp_info",
  EnrollToLp = "/set/courses/enroll_to_lp",
  UserRegister = "/set/courses/user_registr",
  AcceptStep = "/set/courses/accept_step",
  AcceptCourse = "/set/courses/accept_course",
  GetArchiveCount = "/get/courses/archive_count",
  GetInfoCourse = "/get/courses/get_info_course",
  GetVebinarInfo = "/get_vebinar_info",
  GetEducationCourse = "/get/courses/education_course",
  GetVideoOfLo = "/get/courses/get_video_of_lo",
  GetYoutubeVideo = "/get/courses/get_youtube_video",
  GetIframeTest = "/get/courses/get_iframe_test",
  GetStartTest = "/get/course/start_test",
  GetTestNextStep = "/get/course/test_next_step",
  EndTest = "/set/course/end_test",
  GetRecomendations = "/get/course/recomendations",
  GetRecordOfSession = "/get/course/get_record_of_session",
  BuyCourse = "/set/course/buy_course",
}

export default CourseEndpoints;
