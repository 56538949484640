import axios from "axios";
import { baseURL } from "../config";
import { PromoResponse } from "./PromoApi/promoInterfaces";

export async function request<T>(
  url: string,
  requestType?: string,
  data?: object
): Promise<T> {
  try {
    const reqestParams = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      withCredentials: true,
    };
    let response;
    switch (requestType) {
      case "post": {
        response = await axios.post<T>(baseURL + url, data || {}, reqestParams);
        break;
      }
      case "put": {
        response = await axios.put<T>(baseURL + url, data || {}, reqestParams);
        break;
      }
      case "get": {
        response = await axios.get<T>(baseURL + url, reqestParams);
        break;
      }
      default: {
        response = await axios.post<T>(baseURL + url, data || {}, reqestParams);
      }
    }
    return response.data;
  } catch (e: any) {
    throw new Error(
      e.response?.data?.message || "An error occurred during the request"
    );
  }
}

export function sendForm<T>(
  url: string,
  form: FormData,
  onCallback: (data: PromoResponse<T>) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open("POST", `${baseURL}${url}`);
  xhr.onload = () => {
    if (xhr.status === 200) {
      onCallback(JSON.parse(xhr.responseText));
    }
  };
  xhr.send(form);
}
