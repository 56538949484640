import React from "react";
import { CoffeeCardReq } from "../interfaces/CoffeeCard";
import { Link } from "react-router-dom";
import angle from "../assets/img/coffeeAngle.svg";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";

type IProps = CoffeeCardReq;

const CoffeeCard = (p: IProps) => {
  const link = createPath({
    route: AppRoutes.Learn,
    args: [
      {
        key: "course",
        value: p.courseId,
      },
      { key: "doEnroll", value: 1 },
    ],
  });

  return (
    <Link to={link} className="text-inherit">
      <div className="relative w-[248px] cursor-pointer my-[10px] mr-4 border-b border-b-solid border-b-SecondaryAccent transition duration-[0.2s] ease-in-out hover:scale-[1.02]">
        <img
          className="w-[248px] h-[152px] object-cover"
          src={p.imgSrc}
          alt="img"
        />
        <img
          className="absolute right-0 top-0 w-auto h-auto"
          src={angle}
          alt="angle"
        />
        <div className="min-h-0 w-[100%] flex flex-col justify-between">
          <div className="pt-3 mb-[14px] base text-SecondaryAccent-dark w-[248px] flex-grow">
            <p className="whitespace-nowrap text-ellipsis overflow-hidden">
              {p.text}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CoffeeCard;
