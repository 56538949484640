import React from "react";
import { CourseStageRowReq } from "../interfaces/CourseStageRow";
import { userProgressIcon } from "../utils/completionIndicators";
import { getColor } from "../utils/colorsHandler";
import block from "../assets/img/block.svg";
import Colors from "../structure/colors";

type IProps = CourseStageRowReq;

const CourseStageRow = (p: IProps) => {
  return (
    <div
      onClick={() => {
        p.onClick(p.step);
      }}
      className={`px-[10px] py-[10px] flex border-b border-b-SecondaryAccent-veryLight`}
    >
      <div className={`flex flex-col justify-center`}>
        {!p.step.user_access ? (
          <img
            className={`h-[30px] w-[30px] min-w-[30px]`}
            src={block}
            alt="img"
          />
        ) : (
          userProgressIcon(p.step.user_percent, getColor(Colors.Main, true))
        )}
      </div>
      <div>
        <p
          className={`block pl-[15px] leading-6 ${
            p.selected === Number(p.step.step_id)
              ? "text-MainAccent"
              : "text-SecondaryAccent"
          } ${
            !p.step.user_access ? "cursor-default" : "cursor-pointer"
          } base text-[14px] select-none`}
        >
          {p.step.step_name}
        </p>
      </div>
    </div>
  );
};

export default CourseStageRow;
