import { IconProps } from './IconBase';

export default function EditIcon({ size = 24, className }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			className={className}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21 21H3M19.88 6.99998L11 15.83L7 17L8.17 13L17.05 4.11998C17.4256 3.71343 17.9471 3.47247 18.5002 3.44997C19.0532 3.42747 19.5926 3.62528 20 3.99998C20.1932 4.20272 20.3437 4.44223 20.4426 4.70427C20.5414 4.96632 20.5866 5.24556 20.5754 5.5254C20.5642 5.80524 20.4968 6.07997 20.3774 6.33329C20.2579 6.5866 20.0888 6.81332 19.88 6.99998Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
