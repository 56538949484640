import React from "react";
import { CalendarDayReq } from "../interfaces/CalendarDay";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../structure/routes";

type IProps = CalendarDayReq;

const CalendarDay = (p: IProps) => {
  const navigate = useNavigate();
  const today = new Date();
  const date = { ...p.date };
  return (
    <div
      onClick={() => {
        if (p.session && date) {
          if (new Date() > new Date(date.date || "")) {
            navigate(`${AppRoutes.Learn}?course=${p.sessionId}`, {
              replace: true,
            });
          } else {
            navigate(
              `${AppRoutes.Vebinar}?course=${p.sessionId}&date=${date.date}&begin=${date.begin}&end=${date.end}`,
              {
                replace: true,
                state: { ...p.date },
              }
            );
          }
        }
      }}
      className={`${
        p.today && !p.session
          ? "border-2 border-MainAccent"
          : p.today && p.session
          ? "border-2  border-l-[12px] border-l-MainAccent bg-MainAccent bg-opacity-20 hover:!bg-MainAccent hover:!bg-opacity-40 border-MainAccent "
          : p.session && today < new Date(date.date || "")
          ? "border-l-[12px] border-l-MainAccent bg-MainAccent bg-opacity-20 hover:!bg-MainAccent hover:!bg-opacity-40"
          : p.session && today > new Date(date.date || "")
          ? "border-l-[12px] border-l-SecondaryAccent bg-SecondaryAccent bg-opacity-20 hover:!bg-SecondaryAccent hover:!bg-opacity-40"
          : "border-r border-r-SecondaryAccent-veryLight"
      } relative flex justify-end pt-3.5 pr-2 h-full cursor-pointer duration-200 hover:bg-SecondaryAccent-veryLight`}
    >
      {p.session && today < new Date(date.date || "") && (
        <p className={`baseMicro mr-10 mt-2 text-[9px]`}>Вебинар</p>
      )}
      {p.session && today > new Date(date.date || "") && (
        <p className={`baseMicro mr-[8px] mt-1 text-[8px]`}>Смотреть запись</p>
      )}
      <p
        className={`${
          p.inactive ? "text-SecondaryAccent-light" : "text-SecondaryAccent"
        }`}
      >
        {p.day}
      </p>
    </div>
  );
};

export default CalendarDay;
