import React from "react";
import angle from "../assets/img/certAngle.svg";
import { UserCertificate } from "../network/UserApi/userInterfaces";

type IProps = UserCertificate;

const CertCard = (p: IProps) => {
  return (
    <a
      href={p.link}
      download={"cert"}
      target={"_blank"}
      className="text-inherit"
      rel="noreferrer"
    >
      <div className="relative w-[180px] h-[250px] cursor-pointer my-[10px] mr-4 border-b border-b-solid border-b-SecondaryAccent transition duration-[0.2s] ease-in-out hover:scale-[1.02]">
        <div className="w-[180px] h-[112px] object-cover justify-center text-center border-2 border-MainAccent baseBold text-MainAccent pt-10">
          <p>СЕРТИФИКАТ</p>
        </div>
        <img
          className="absolute right-0 top-0 w-auto h-auto"
          src={angle}
          alt="angle"
        />
        <div className="min-h-0 w-[100%] flex flex-col justify-between">
          <p className={`base`}>{p.name}</p>
          <div className="pt-3 mb-[14px] base text-SecondaryAccent-dark w-[180px] flex-grow">
            <p className="text-ellipsis overflow-hidden">{p.description}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CertCard;
