export function buildObject(e: any, fields: number, id?: number) {
  let obj: any = {};
  for (let i = 0; i <= fields - 1; i++) {
    obj[e.target[i].name] = e.target[i].value;
  }
  if (id) {
    obj["item_id"] = id;
  }
  return obj;
}
