import React, { useState } from "react";
import { TableHeadReq } from "../interfaces/TableHead";
import TableHeadElement from "./TableHeadElement";
import { Employer } from "../network/CommandApi/commandInterfaces";

type IProps = {
  cards: Array<TableHeadReq>;
  sortBy: (sort: string) => void;
  currentSort: string;
  orderBy: (order: boolean) => void;
  currentOrder: boolean;
};
const TableHead = (p: IProps) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const renderedHead = p.cards.map((card) => {
    return (
      <TableHeadElement
        orderBy={p.orderBy}
        element={card}
        sortBy={p.sortBy}
        currentCort={p.currentSort}
        currentOrder={p.currentOrder}
      />
    );
  });
  return (
    <thead className={`relative w-full`}>
      <tr
        className={`ml-[14px] w-full h-10 cursor-pointer border-b border-b-SecondaryAccent-veryLight items-center flex`}
      >
        <th
          className={`base text-xs text-SecondaryAccent text-left items-center flex mr-1`}
        >
          <div
            className={`w-3 h-3 bg-SecondaryAccent-light rounded-[100%] relative mr-3`}
          ></div>
        </th>
        <th
          onClick={handleClick}
          className={`tableSelector tableData mr-3 min-w-0 ${
            isClicked ? "bg-MainAccent" : ""
          }`}
        ></th>
        {renderedHead}
      </tr>
    </thead>
  );
};

export default TableHead;
