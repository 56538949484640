import React, { useState } from "react";
import { InputFormReq } from "../interfaces/InputForm";
import InputForm from "./InputForm";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { buildObject } from "../utils/buildObject";
import { userRegister } from "../network/UserApi/userApi";
import AppRoutes from "../structure/routes";

type IProps = {
  cards: Array<InputFormReq>;
  onAuth: (r: boolean) => void;
  className?: string;
};

const RegisterBlock = (p: IProps) => {
  const [isMarked, setIsMarked] = useState(false);
  const toggleMark = () => {
    setIsMarked(!isMarked);
  };
  const navigate = useNavigate();
  const handleRegister = (e: any) => {
    e.preventDefault();
    const data = buildObject(e, 8);
    userRegister(data).then(() => {
      p.onAuth(true);
      navigate(AppRoutes.Main, { replace: true });
    });
  };
  const renderedCards = p.cards.map((card) => {
    return (
      <InputForm
        placeholder={card.placeholder}
        isSelector={card.isSelector}
        name={card.name}
      />
    );
  });

  return (
    <form
      className={`w-full mt-0 ${p.className}`}
      method="POST"
      onSubmit={(event) => {
        handleRegister(event);
      }}
    >
      {renderedCards}
      <div className="flex items-start my-[10px]">
        <div
          onClick={toggleMark}
          className={`min-w-[15px] min-h-[15px] mr-[10px] cursor-pointer border-2 border-SecondaryAccent-one ${
            isMarked ? "bg-MainAccent" : ""
          }`}
        ></div>
        <p className="baseMicro text-sm">
          Заполняя настоящую регистрационную форму, я даю согласие акционерному
          обществу «Систэм Электрик» на обработку содержащихся в ней
          персональных данных в соответствии с условиями и порядком обработки
          моих персональных данных, установленных{" "}
          <Link
            className="baseUnderline text-sm"
            to="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
          >
            {" "}
            Политикой акционерного общества «Систэм Электрик»{" "}
          </Link>{" "}
          в отношении обработки персональных данных и сведениями о реализуемых
          требованиях к защите персональных данных, в целях получения информации
          о продуктах и доступных решениях Систэм Электрик, акцептом которых
          является заполнение данной формы
        </p>
      </div>
      <Button
        className="w-full h-14 font-bold"
        text="Зарегистрироваться"
        disabled={!isMarked}
      />
    </form>
  );
};

export default RegisterBlock;
