const CourseDirections: any = {
  bim: "Информационное моделирование",
  bms: "Автоматизация зданий",
  ia: "АСУТП",
  lv: "Низкое напряжение",
  mv: "Среднее напряжение",
  soft: "Расчеты и проектирование",
};

export default CourseDirections;
