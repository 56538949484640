import "./App.css";
import "./index.css";
import { AuthProvider } from "./Providers/PromoUserProvider";
import PageRouter from "./PageRouter";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <PageRouter />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
