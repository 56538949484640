import React from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../structure/routes";

const Footer = () => {
  return (
    <footer className="h-[80px] bg-SecondaryAccent-one absolute bottom-0 w-[100%] block">
      <div className="max-w-[1440px] m-auto px-[32px] py-[11px] flex justify-between baseMicro text-white">
        <div className="block">
          <Link className="block" to={AppRoutes.Feedback}>
            Обратная связь
          </Link>
          <Link
            to={
              "https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
            }
          >
            Политика конфиденциальности
          </Link>
        </div>
        <div className="block">
          <p>
            АО "Систэм Электрик"
            <br></br>
            г. Москва, ул. Двинцев, д. 12, корп.1, здание «А»
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
