import { useEffect, useState } from "react";
import Button from "../../components/Button";
import down from "../../assets/img/promoDown.svg";
import usePrompContext from "../../components/PromoComtextProvider";
import { useNavigate, useNavigation } from "react-router-dom";
import AppRoutes from "../../structure/routes";

const MAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

interface IProps {}

const PromoRegistration = (props: IProps) => {
  const navigate = useNavigate();

  const { input, setInput } = usePrompContext();

  const [mailError, setMailError] = useState<boolean>(false);

  const [rules, setRules] = useState<boolean>(false);
  const [eula, setEula] = useState<boolean>(false);

  const selector = document.getElementById("selector");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`!w-full`}>
      <div
        className={`bg-MainAccent sm:rounded-bl-[30px] rounded-bl-[127px] !w-full sm:pl-4 pl-32 pt-12 pb-16 overflow-auto`}
      >
        <p
          className={`text-white font-semibold sm:text-[20px] sm:leading-[25px] text-[36px] leading-[48px]`}
        >
          Зарегистрировать проект для участия
        </p>
        <p
          className={`text-white font-semibold sm:text-[20px] sm:leading-[25px] text-[36px] leading-[48px]`}
        >
          в акции Развивай SYSTEME
        </p>
      </div>
      <div className={`flex gap-5 sm:ml-4 ml-24 mt-4`}>
        <p className={`leading-6 text-[22px] text-MainAccent`}>Шаг</p>
        <span
          className={`circle relative bottom-[54px] !bg-MainAccent border border-white`}
        >
          1
        </span>
        <p className={`sm:hidden leading-6 text-[22px] text-MainAccent`}>
          Заполните данные участника
        </p>
      </div>
      <div
        className={`flex flex-col sm:relative sm:bottom-[40px] items-start sm:ml-4 sm:pr-4 ml-24 sm:w-auto w-[840px] gap-3`}
      >
        <p className={`sm:block hidden leading-6 text-[22px] text-MainAccent`}>
          Заполните данные участника
        </p>
        <div className="relative w-full ">
          <input
            type="text"
            id="lastname"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
            placeholder=" "
            required={true}
            value={input.lastname}
            onChange={(event) => {
              setInput({ ...input, lastname: event.currentTarget.value });
            }}
          />

          <label
            htmlFor="lastname"
            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Фамилия *
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="name"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
            placeholder=" "
            required={true}
            value={input.name}
            onChange={(event) => {
              setInput({ ...input, name: event.currentTarget.value });
            }}
          />

          <label
            htmlFor="name"
            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Имя *
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="patronomic"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
            placeholder=" "
            value={input.patronomic}
            onChange={(event) => {
              setInput({ ...input, patronomic: event.currentTarget.value });
            }}
          />

          <label
            htmlFor="patronomic"
            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Отчество
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="mail"
            className={`block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none focus:outline-none focus:ring-0 focus:border-MainAccent peer ${
              mailError ? "border-CloseDeadlineAccent" : ""
            } first_page`}
            placeholder=" "
            value={input.mail}
            onChange={(event) => {
              if (
                event.currentTarget.value.match(MAIL_REGEX) ||
                event.currentTarget.value.length === 0
              ) {
                setMailError(false);
              } else {
                setMailError(true);
              }
              setInput({ ...input, mail: event.currentTarget.value });
            }}
            required={true}
          />
          {mailError && (
            <p className="text-[12px] leading-4 text-CloseDeadlineAccent mt-0.5 base">
              Пожалуйста, проверьте введенный адрес
            </p>
          )}

          <label
            htmlFor="mail"
            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            E-mail *
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="phone"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
            placeholder=" "
            required={true}
            value={input.phone}
            onChange={(event) => {
              setInput({ ...input, phone: event.currentTarget.value });
            }}
          />

          <label
            htmlFor="phone"
            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Контактный номер телефона *
          </label>
        </div>
        <div
          className="flex items-center relative px-4 rounded-lg h-12 w-full group focus:border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark "
          tabIndex={0}
          id="selector"
        >
          <span id="select">
            {!input.select ? "Регион участника*" : input.select}
          </span>
          <img
            src={down}
            className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
            alt=""
          ></img>
          <div className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 p-6 shadow-plate">
            <p
              className="text-sm font-normal text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Центр
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Северо-Западный Федеральный округ
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Приволжский Федеральный округ
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Южный Федеральный округ
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Урал
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Сибирь
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={(event) => {
                if (selector) {
                  selector.blur();
                }
                setInput({
                  ...input,
                  select: event.currentTarget.textContent || "",
                });
              }}
            >
              Дальний Восток
            </p>
          </div>
        </div>
        <div className="flex gap-2 mt-4">
          <input
            type="checkbox"
            className="check"
            onChange={() => {
              setRules(!rules);
            }}
          ></input>
          <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
            Подтверждаю, что ознакомлен и согласен с{" "}
            <a
              href="https://workspace.systeme.ru/s/9ZsF4iY32tzdJcy"
              className="text-systeme underline"
            >
              Правилами акции
            </a>
          </p>
        </div>
        <div className="flex gap-2 items-start">
          <input
            type="checkbox"
            className="check"
            onChange={() => {
              setEula(!eula);
            }}
          ></input>
          <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
            Заполняя настоящую регистрационную форму, я даю согласие
            акционерному обществу «Систэм Электрик» на обработку содержащихся в
            ней персональных данных в соответствии с условиями и порядком
            обработки моих персональных данных, установленных{" "}
            <a
              href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
              className="text-systeme underline"
            >
              Политикой акционерного общества «Систэм Электрик»
            </a>{" "}
            в отношении обработки персональных данных и сведениями о реализуемых
            требованиях к защите персональных данных, в целях получения
            информации о продуктах и доступных решениях Систэм Электрик,
            акцептом которых является заполнение данной формы
          </p>
        </div>
        <div className="w-full">
          <Button
            text={`Продолжить`}
            onClick={() => {
              navigate(AppRoutes.PromoRegistrationFinal);
            }}
            className={`sm:w-full mt-8 h-14 w-40 rounded-full`}
            disabled={
              !(
                input.select &&
                input.lastname &&
                input.name &&
                (input.mail || "").match(MAIL_REGEX) &&
                input.phone &&
                rules &&
                eula
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PromoRegistration;
