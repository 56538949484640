import React from "react";
import CourseStageRow from "./CourseStageRow";
import { Step } from "../network/CourseApi/courseInterfaces";

type IProps = {
  rows: Array<Step>;
  onClick: (step: Step) => void;
  selectedStep?: number;
};

const CourseStageBlock = (p: IProps) => {
  const renderedRows = p.rows.map((el) => {
    return (
      <CourseStageRow step={el} onClick={p.onClick} selected={p.selectedStep} />
    );
  });

  return (
    <div
      className={`border-t-2 border-t-SecondaryAccent h-[calc(100%-2px)] border-b-2 border-b-SecondaryAccent min-h-[421px] max-h-[421px] w-[300px] mt-[1px] overflow-y-scroll scroll-smooth`}
    >
      {renderedRows}
    </div>
  );
};

export default CourseStageBlock;
