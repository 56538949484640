import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getColor } from "../utils/colorsHandler";
import angle from "../assets/img/courseAngle.svg";
import arrowDown from "../assets/img/arrowDownCourse.svg";
import { CourseCardReq } from "../interfaces/CourseCard";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { userProgressIcon } from "../utils/completionIndicators";
import paid from "../assets/img/paid.svg";

type IProps = CourseCardReq;

const CourseCard = (p: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const link = createPath({
    route: p.cost === 0 || p.can_enter ? AppRoutes.Learn : AppRoutes.Info,
    args: [{ key: "course", value: p.courseId }],
  });

  return (
    <div
      className={`${
        isOpen ? "h-max" : ""
      } relative mt-[25px] mb-6 h-[105px] px-4 pt-4 flex cursor-pointer overflow-hidden border-t border-solid	border-SecondaryAccent`}
    >
      <div className="pt-7 pr-[25px] block mr-[25px]">
        {Number(p.cost) === 0 || p.can_enter ? (
          userProgressIcon(p.progress, getColor(p.color, true))
        ) : (
          <img src={paid} alt=""></img>
        )}
      </div>
      <div className="w-[176px] flex flex-col items-center relative mr-4 z-[2]">
        <img
          className="absolute right-0 top-0 z-[3] w-auto h-[60px]"
          src={angle}
          alt="angle"
        />
        <Link to={link}>
          <img className="h-[88px] mb-[10px]" src={p.imgSrc} alt="img" />
        </Link>
        <Link
          to={link}
          className="squareButton mt-4 max-w-[144px] w-[100%] px-[10px]"
        >
          {p.user_subscribed || p.can_enter
            ? "Продолжить"
            : p.cost === 0
            ? "Записаться"
            : "Купить доступ"}
        </Link>
      </div>
      <div className="relative">
        <div className="h-[88px] relative">
          <Link to={link}>
            <p className="w-[450px] baseBold text-SecondaryAccent-dark">
              {p.name}
            </p>
          </Link>
          <p className="h-[100%] w-[100%]"></p>
          <p className="absolute bottom-0 text-[0.562em] leading-[11px] uppercase text-SecondaryAccent-dark mt-[-3px]">
            {p.cost === 0 ? "БЕСПЛАТНО" : p.cost + " РУБ."}
          </p>
        </div>
        <div
          className="mt-[20px] w-[450px]"
          dangerouslySetInnerHTML={{ __html: p.body }}
        ></div>
      </div>
      <div
        onClick={toggleDropdown}
        className="absolute right-0 pl-[15px] pt-8 cursor-pointer"
      >
        <img src={arrowDown} alt="down" />
      </div>
    </div>
  );
};

export default CourseCard;
