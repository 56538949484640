import React from "react";
import logo from "../assets/img/logo.svg";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

const SiteLogo = () => {
  return (
    <div className="">
      <Link to={AppRoutes.Main}>
        <img src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default SiteLogo;
