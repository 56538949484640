import axios from "axios";
import userEndpoints from "../structure/userEndpoints";
import { baseURL } from "../config";

export function sendAvatar(avatar: Blob) {
  const formData = new FormData();
  formData.append("avatar", avatar);
  return axios({
    method: "post",
    url: baseURL + userEndpoints.SetAvatar,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    withCredentials: true,
  });
}
