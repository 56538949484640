import { Coordinates } from "../interfaces/Coordinates";
import React from "react";

export const polar_to_cartesian = (
  center_x: number,
  center_y: number,
  radius: number,
  angle_in_degrees: number
) => {
  let angle_in_radians = ((angle_in_degrees - 90) * Math.PI) / 180.0;

  return {
    x: center_x + radius * Math.cos(angle_in_radians),
    y: center_y + radius * Math.sin(angle_in_radians),
  };
};

export const describe_arc = (
  percent: number,
  access: boolean = true,
  x: number,
  y: number,
  radius: number
) => {
  const end_angle = ((percent === 100 ? 99.999 : percent) * 360) / 100;
  let start, end: Coordinates;
  if (access) {
    start = polar_to_cartesian(x, y, radius, end_angle);
    end = polar_to_cartesian(x, y, radius, 0);
  } else {
    end = polar_to_cartesian(x, y, radius, end_angle);
    start = polar_to_cartesian(x, y, radius, 0);
  }

  let largeArcFlag = end_angle <= 180 ? "0" : "1";

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

export const completedIcon = (color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="6" fill={color} />
      <path d="M10 12L13 15L16 12" stroke="white" />
    </svg>
  );
};

export const playIcon = (color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 13L10.5 17.3301L10.5 8.66987L18 13Z" fill={color} />
    </svg>
  );
};

export const learnProgress = (percent: number, color: string) => {
  return (
    <path
      fill="none"
      stroke={color}
      strokeWidth="3"
      d={describe_arc(percent, true, 21, 21, 16)}
    ></path>
  );
};

export const userProgressIcon = (percent: number, color: string) => {
  return (
    <div className="relative">
      {percent === 100 ? completedIcon(color) : playIcon(color)}
      <svg
        width="26px"
        height="26px"
        style={{ position: "absolute", left: 0, top: 0 }}
      >
        <path
          fill="none"
          stroke="#D7D7D7"
          strokeWidth="2"
          d={describe_arc(99.9, true, 13, 13, 11.5)}
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth="2"
          d={describe_arc(percent - 0.1, true, 13, 13, 11.5)}
        />
      </svg>
    </div>
  );
};
