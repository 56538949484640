import { ReactNode, createContext, useContext, useState } from "react";


export type input = {
  lastname?: string;
  name?: string;
  patronomic?: string;
  mail?: string;
  phone?: string;
  select?: string;
  organization?: string;
  project_name?: string;
  orderer?: string;
  files?: any;
};

const PromoContext = createContext<{input:input,setInput: (data:input)=> void}>({input:{},setInput:(v:input)=>{}});

interface IProps {
  children: ReactNode | ReactNode[];
}

export const PromoComtextProvider = (props: IProps) => {
  const [input, setInput] = useState<input>({});
  return <PromoContext.Provider value={{input,setInput}}>{props.children}</PromoContext.Provider>;
};

const usePrompContext = () => useContext<any>(PromoContext );
export default usePrompContext
