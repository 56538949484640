import { useCallback, useEffect, useMemo, useState } from "react";
import logo from "../../assets/img/systemeLogo.svg";
import chinaWall from "../../assets/img/china_wall.jpg";
import circul from "../../assets/img/circul.svg";
import monitor from "../../assets/img/monitor.png";
import travel from "../../assets/img/travel.png";

import pc from "../../assets/img/pc.svg";
import pig from "../../assets/img/pig.svg";
import box from "../../assets/img/box.svg";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";

import AppRoutes from "../../structure/routes";
import CreateHeader from "../../components/Create/CreateHeader";
import LoginModal from "../../components/Create/LoginModal";
import { PromoType } from "../../network/PromoApi/promoInterfaces";

import barter from "../../assets/img/barter.png";
import project from "../../assets/img/first_project.png";
import presents from "../../assets/img/welcome/presents.png";
import LiderTables from "../../components/Create/LiderTables";

export interface IProps {}

type steps = {
  step: "init" | "submit" | "over";
};

type input = {
  lastname?: string;
  name?: string;
  patronomic?: string;
  mail?: string;
  phone?: string;
  select?: string;
  organization?: string;
  project_name?: string;
  orderer?: string;
  files?: any;
};

export const OldCreate = (props: IProps) => {
  const navigate = useNavigate();

  const [openLoginModal, setopenLoginModal] = useState<boolean>(false);

  const [step, setStep] = useState<steps>({ step: "init" });

  const isNewYear = useMemo(
    () => new Date() > new Date(2025, 0, 1, 0, 0, 0),
    []
  );

  const openModal = useCallback(() => {
    if (!isNewYear) navigate(AppRoutes.PromoRegistration);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tourUsersData = [
    { num: "1", user: "S1317", points: 23304 },
    { num: "2", user: "SIB9330", points: 13639 },
    { num: "3", user: "C8630", points: 12672 },
    { num: "4", user: "C7456", points: 12052 },
    { num: "5", user: "NW7235", points: 7535 },
    { num: "6", user: "C2543", points: 6835 },
    { num: "7", user: "C1815", points: 6323 },
    { num: "8", user: "C9656", points: 6111 },
    { num: "9", user: "NW4229", points: 5076 },
    { num: "10", user: "NW7387", points: 4723 },
    { num: "11", user: "S1504", points: 4431 },
    { num: "12", user: "FE4900", points: 3597 },
    { num: "13", user: "NW6416", points: 3530 },
    { num: "14", user: "C2400", points: 3189 },
    { num: "15", user: "NW7453", points: 2925 },
    { num: "16", user: "U8512", points: 2458 },
    { num: "17", user: "FE1667", points: 2428 },
    { num: "18", user: "C8433", points: 2173 },
    { num: "19", user: "NW4726", points: 1995 },
    { num: "20", user: "C7953", points: 1989 },
    { num: "21", user: "NW3252", points: 1981 },
    { num: "22", user: "C6424", points: 1969 },
    { num: "23", user: "FE3943", points: 1940 },
    { num: "24", user: "U6961", points: 1795 },
    { num: "25", user: "C9635", points: 1751 },
    { num: "26", user: "V7414", points: 1739 },
    { num: "27", user: "V9330", points: 1719 },
    { num: "28", user: "NW8635", points: 1687 },
    { num: "29", user: "C3482", points: 1671 },
    { num: "30", user: "S1464", points: 1658 },
    { num: "31", user: "C5744", points: 1540 },
    { num: "32", user: "NW1563", points: 1523 },
    { num: "33", user: "SIB4951", points: 1330 },
    { num: "34", user: "C6034", points: 1253 },
    { num: "35", user: "SIB5180", points: 1203 },
    { num: "36", user: "S4810", points: 1180 },
    { num: "37", user: "U8699", points: 1155 },
    { num: "38", user: "SIB5984", points: 1123 },
    { num: "39", user: "FE6554", points: 1016 },
    { num: "40", user: "NW3511", points: 889 },
    { num: "41", user: "NW6018", points: 752 },
    { num: "42", user: "NW6662", points: 700 },
    { num: "43", user: "NW7876", points: 694 },
    { num: "44", user: "C8320", points: 689 },
    { num: "45", user: "SIB1262", points: 635 },
    { num: "46", user: "C7195", points: 603 },
    { num: "47", user: "C3499", points: 602 },
    { num: "48", user: "NW2962", points: 598 },
    { num: "49", user: "U9186", points: 533 },
    { num: "50", user: "C3383", points: 521 },
    { num: "51", user: "SIB4133", points: 521 },
    { num: "52", user: "C2677", points: 511 },
    { num: "53", user: "NW5032", points: 509 },
    { num: "54", user: "FE9758", points: 470 },
    { num: "55", user: "C9896", points: 454 },
    { num: "56", user: "SIB7972", points: 441 },
    { num: "57", user: "SIB7271", points: 436 },
    { num: "58", user: "U2173", points: 433 },
    { num: "59", user: "SIB4811", points: 432 },
    { num: "60", user: "SIB4996", points: 423 },
    { num: "61", user: "S5313", points: 407 },
    { num: "62", user: "SIB3539", points: 398 },
    { num: "63", user: "SIB7486", points: 388 },
    { num: "64", user: "NW3904", points: 373 },
    { num: "65", user: "NW9784", points: 370 },
    { num: "66", user: "NW1461", points: 353 },
    { num: "67", user: "SIB5462", points: 342 },
    { num: "68", user: "V2958", points: 331 },
    { num: "69", user: "NW3567", points: 326 },
    { num: "70", user: "NW1870", points: 277 },
    { num: "71", user: "NW5676", points: 276 },
    { num: "72", user: "NW8700", points: 272 },
    { num: "73", user: "S5923", points: 253 },
    { num: "74", user: "C8640", points: 246 },
    { num: "75", user: "NW7739", points: 240 },
    { num: "76", user: "C8905", points: 239 },
    { num: "77", user: "SIB5719", points: 225 },
    { num: "78", user: "FE7875", points: 225 },
    { num: "79", user: "FE2541", points: 207 },
    { num: "80", user: "NW2217", points: 203 },
    { num: "81", user: "C8595", points: 187 },
    { num: "82", user: "C3280", points: 182 },
    { num: "83", user: "NW3164", points: 181 },
    { num: "84", user: "C5878", points: 180 },
    { num: "85", user: "NW9990", points: 174 },
    { num: "86", user: "SIB7201", points: 174 },
    { num: "87", user: "C4605", points: 171 },
    { num: "88", user: "NW9446", points: 170 },
    { num: "89", user: "C9186", points: 154 },
    { num: "90", user: "U7493", points: 147 },
    { num: "91", user: "NW3419", points: 143 },
    { num: "92", user: "NW6069", points: 142 },
    { num: "93", user: "S2288", points: 140 },
    { num: "94", user: "C3099", points: 117 },
    { num: "95", user: "SIB6039", points: 113 },
    { num: "96", user: "SIB9997", points: 103 },
    { num: "97", user: "NW2378", points: 102 },
    { num: "98", user: "FE4699", points: 98 },
    { num: "99", user: "U2385", points: 95 },
    { num: "100", user: "NW4940", points: 85 },
    { num: "101", user: "C5376", points: 84 },
    { num: "102", user: "S2564", points: 80 },
    { num: "103", user: "U9287", points: 80 },
    { num: "104", user: "C7589", points: 75 },
    { num: "105", user: "C1862", points: 71 },
    { num: "106", user: "C3199", points: 68 },
    { num: "107", user: "C1910", points: 67 },
    { num: "108", user: "U4310", points: 60 },
    { num: "109", user: "U4118", points: 60 },
    { num: "110", user: "NW8008", points: 52 },
    { num: "111", user: "NW6225", points: 51 },
    { num: "112", user: "S2627", points: 49 },
    { num: "113", user: "SIB3011", points: 47 },
    { num: "114", user: "S2389", points: 38 },
    { num: "115", user: "C7315", points: 38 },
    { num: "116", user: "U9289", points: 34 },
    { num: "117", user: "SIB3972", points: 29 },
    { num: "118", user: "U4748", points: 28 },
    { num: "119", user: "FE2156", points: 22 },
    { num: "120", user: "SIB5741", points: 21 },
    { num: "121", user: "U3237", points: 20 },
    { num: "122", user: "C9563", points: 14 },
    { num: "123", user: "NW6790", points: 7 },
    { num: "124", user: "C7549", points: 6 },
    { num: "125", user: "U5753", points: 4 },
    { num: "126", user: "SIB1235", points: 0 },
    { num: "127", user: "V2646", points: 0 },
    { num: "128", user: "U8623", points: 0 },
    { num: "129", user: "V2345", points: 0 },
    { num: "130", user: "C1861", points: 0 },
    { num: "131", user: "C4530", points: 0 },
    { num: "132", user: "V7305", points: 0 },
    { num: "133", user: "NW5832", points: 0 },
    { num: "134", user: "SIB7409", points: 0 },
    { num: "135", user: "U7066", points: 0 },
    { num: "136", user: "C4615", points: 0 },
    { num: "137", user: "C3256", points: 0 },
    { num: "138", user: "C7795", points: 0 },
    { num: "139", user: "S1725", points: 0 },
    { num: "140", user: "NW8150", points: 0 },
    { num: "141", user: "SIB1003", points: 0 },
    { num: "142", user: "C8884", points: 0 },
    { num: "143", user: "C5552", points: 0 },
    { num: "144", user: "NW3368", points: 0 },
    { num: "145", user: "U7932", points: 0 },
    { num: "146", user: "NW8899", points: 0 },
    { num: "147", user: "S9316", points: 0 },
    { num: "148", user: "C9313", points: 0 },
    { num: "149", user: "NW9248", points: 0 },
    { num: "150", user: "C6757", points: 0 },
    { num: "151", user: "S2200", points: 0 },
    { num: "152", user: "V6956", points: 0 },
    { num: "153", user: "V8352", points: 0 },
    { num: "154", user: "S7209", points: 0 },
    { num: "155", user: "U6616", points: 0 },
    { num: "156", user: "SIB8134", points: 0 },
  ];

  const tourTableRows = tourUsersData
    .sort((a, b) => b.points - a.points)
    .map((user, i) => {
      return (
        <tr
          className={
            "border-b [&>td]:pl-10 [&>td]:py-3 [&>td]:text-[16px] [&>td]:text-[#626469] [&>td]:font-normal [&>td]:leading-6 peer-[td]:bg-red-200"
          }
          key={user.user}
        >
          <td>{i + 1}</td>
          <td>{user.user}</td>
          <td>{user.points}</td>
        </tr>
      );
    });

  return (
    <div className={`!w-full !min-w-full`}>
      {step.step === "init" && (
        <div className={`sm:bg-white bg-MainAccent !pb-0`}>
          <div className={`flex xl:flex-col flex-row justify-stretch `}>
            <div
              className={`sm:bg-MainAccent  flex flex-col justify-between items-start`}
            >
              <img
                src={logo}
                alt="logo"
                className={`sm:mt-4 sm:w-28 w-64 mt-14 mx-7`}
              />
              <div className="ml-4 mt-4 mb-4 sm:block hidden">
                <p
                  className={`sm:block hidden text-white text-4xl leading-[48px] font-semibold`}
                >
                  Развивай SYSTEME
                </p>
                <p
                  className={`sm:block hidden base text-white font-medium text-lg leading-[30px]`}
                >
                  и выигрывай от правильных решений!
                </p>
              </div>
              <img
                src={monitor}
                alt="monitor"
                className={`sm:max-w-full sm:min-w-0 min-w-[640px]`}
              />
            </div>
            <div className={`flex flex-col w-full`}>
              <div
                className={`bg-white flex flex-col items-start sm:pl-4 sm:pr-4 pl-16 pt-12 pr-36 rounded-bl-[127px] w-full`}
              >
                <div className="sm:hidden">
                  <p
                    className={`text-[50px] leading-[48px] font-semibold xl:mb-7`}
                  >
                    Развивай SYSTEME
                  </p>
                  <p
                    className={`base text-[#626469] font-medium mb-16 text-[22px] leading-[30px]`}
                  >
                    и выигрывай от правильных решений!
                  </p>
                </div>
                <p
                  className={`sm:mb-4 mb-8 font-normal sm:text-2xl sm:font-semibold text-3xl leading-[48px]`}
                >
                  Применяете в проектах оборудование Systeme Electric, Dekraft
                  или Механотроника?
                </p>
                <p className={`sm:w-full mb-8 text-[22px] leading-[30px]`}>
                  Регистрируйте проекты и выигрывайте призы!
                </p>
                <Button
                  text={"Участвовать"}
                  className={`mb-11 w-full `}
                  onClick={openModal}
                  disabled={isNewYear}
                />
              </div>

              <div className={`sm:ml-4 ml-16 mb-4 mt-10`}>
                <p className={`sm:text-MainAccent-dark text-[16px] leading-6`}>
                  Сроки проведения акции
                </p>
                <p
                  className={`sm:text-black text-white sm:text-base text-[22px] leading-6`}
                >
                  с 1 апреля по 31 декабря 2024 г.
                </p>
              </div>
              <div className={`sm:ml-4 ml-16`}>
                <p className={`sm:text-MainAccent-dark text-[16px] leading-6`}>
                  Подведение итогов состоится
                </p>
                <p
                  className={`sm:text-black text-white sm:text-base text-[22px] leading-6 mb-10`}
                >
                  с 1 января по 15 февраля 2025 г.
                </p>
              </div>
            </div>
          </div>
          {isNewYear && (
            <p className="my-5 mx-7 text-white text-xl font-bold">
              Прием проектов завершен, подведение итогов состоится в феврале
              2025 г. Ожидайте анонс новой акции в 2025 году!
            </p>
          )}

          <div
            className={`rounded-tr-[127px] flex flex-col items-start bg-white sm:px-4 px-[8%] sm:pt-16 pt-20`}
          >
            <p className={`font-semibold text-[36px] leading-[48px`}>
              Как принять участие
            </p>
            <div className={`flex w-full justify-around mt-20`}>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={circul} alt="circul" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Проектируйте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  с применением оборудования Systeme Electric, Dekraft и
                  Механотроника
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={pc} alt="pc" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Загружайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  документацию по проекту
                  <p
                    className={`underline text-MainAccent cursor-pointer`}
                    onClick={openModal}
                  >
                    в форме регистрации
                  </p>
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={pig} alt="pig" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Получайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  баллы SystemePoints за примененное в проекте оборудование
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={box} alt="box" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Обменивайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  накопленные баллы на ценные призы
                </p>
              </div>
            </div>
            <a
              href="https://workspace.systeme.ru/systeme/s/9ZsF4iY32tzdJcy"
              className={`mt-24 mx-auto`}
            >
              <Button
                text={"Правила акции"}
                className={`sm:w-full border border-MainAccent bg-white text-MainAccent hover:text-white h-12 flex items-center rounded-3xl`}
              />
            </a>
            <p className={`font-semibold text-[36px] leading-[48px] mt-20`}>
              Как увеличить шансы на победу?
            </p>
            <div className={`flex flex-col relative gap-8 mt-16`}>
              <span className={`line absolute left-10 top-10 h-5/6`}></span>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>1</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  загружайте как можно больше проектов
                </p>
              </div>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>2</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  применяйте в проекте максимальное количество оборудования
                  Systeme Electric, Dekraft и Механотроника
                </p>
              </div>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>3</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  проектируйте комплексно: удваивайте баллы за применение
                  различных типов оборудования
                </p>
              </div>
            </div>
            <Button
              text={"Участвовать"}
              className={`sm:w-full mx-auto mt-16 mb-20`}
              onClick={openModal}
              disabled={isNewYear}
            />
            <p className={`font-semibold text-[36px]  leading-[48px]`}>
              Призы за баллы
            </p>
            <div className={`flex justify-around w-full mt-14`}>
              <div className={`flex items-center flex-col w-80 group`}>
                <img src={travel} alt="travel" className={`w-[90%]`} />
                <p
                  className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                >
                  Путешествие для победителей
                </p>
              </div>

              <div className={`flex flex-col w-80 group`}>
                <Link to={AppRoutes.PrizesForSystemepoints}>
                  <img src={barter} alt="barter" className={`w-[90%]`} />
                  <p
                    className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                  >
                    Приз в обмен на полученные баллы
                  </p>
                </Link>
              </div>
              <div className={`flex flex-col w-80 group`}>
                <Link to={AppRoutes.PrizeOfFirstProject}>
                  <img src={project} alt="project" className={`w-[90%]`} />
                  <p
                    className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                  >
                    Приз за первый проект
                  </p>
                </Link>
              </div>
              <div className={`flex flex-col w-80 group`}>
                <img src={presents} alt="project" className={`w-[90%]`} />
                <p
                  className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                >
                  Специальный приз за наибольшее
                  <br />
                  количество примененного <br />в проектах оборудования: <br />
                  Шинопровод SystemeLine B <br />
                  НКУ SystemeBlock <br />
                  КРУ Systeme RS <br />
                  Оборудование Dekraft
                </p>
              </div>
            </div>

            <div className={"sm:w-full flex flex-col w-[100%]  mt-16 mb-20"}>
              <p className={`font-semibold text-[36px] leading-[48px] mb-16`}>
                Турнирная таблица (обновлена 28.12.2024)
              </p>
              <table className={"w-[100%]"}>
                <thead
                  className={
                    "border-b text-[17px] text-[#626469] font-normal leading-6"
                  }
                >
                  <td>
                    <p className="pl-10">№ п/п</p>
                  </td>
                  <td>Номер участника</td>
                  <td>Баллы SystemePoints</td>
                </thead>
                <tbody>{tourTableRows}</tbody>
              </table>
              <Button
                text={"Участвовать"}
                className={`mx-auto  mb-20 mt-5`}
                onClick={openModal}
                disabled={isNewYear}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
