import React from "react";
import ProgressCircle from "./ProgressCircle";
import { ProgressCircleReq } from "../interfaces/ProgressCircle";
import { getColor } from "../utils/colorsHandler";
import Diagram from "./Diagram";
import { GetLoginsRes } from "../network/UserApi/userInterfaces";

type IProps = {
  circles: Array<ProgressCircleReq>;
  months: GetLoginsRes;
};

const ProgressBlock = (p: IProps) => {
  const renderedCircles = p.circles.map((el) => {
    return (
      <ProgressCircle
        color={getColor(el.color, true)}
        done={el.done}
        all={el.all}
        insideCircle={el.insideCircle}
        circleDescription={el.circleDescription}
      />
    );
  });

  return (
    <div
      className={`mt-[30px] max-w-[650px] mb-[100px] flex justify-between flex-wrap`}
    >
      {renderedCircles}
      <Diagram data={p.months.data} max={p.months.max} />
    </div>
  );
};

export default ProgressBlock;
