enum PromoEndpoints {
  CreateProject = "/set/promo/create_project",
  OredrPrise = "/set/promo/prise_mail",
  OredrShop = "/set/promo/request",
  Auth = "/promo/auth",
  Rregister = "/promo/register",
  UserInfo = "/promo/user/info",
  Users = "/promo/admin/users",
  UserEdit = "/promo/admin/edit",
  UserConfirm = "/promo/admin/confirm",
  UploadFile = "/promo/admin/upload",
  DownloadFile = "/promo/admin/download",
  PromoLogout = "/promo/logout",
  PromoLiders = "/promo/liders",
  PromoStatus = "/promo/admin/status",
  PromoSetStatus = "/promo/admin/set_status",
  DownloadUsers = "/promo/admin/users/download"
}

export default PromoEndpoints;
