import React from "react";
import Typewriter from "typewriter-effect";

import certs from "../../assets/img/welcome/certs.svg";
import versatility from "../../assets/img/welcome/versatility.svg";
import continuity from "../../assets/img/welcome/continuity.svg";
import mobility from "../../assets/img/welcome/mobility.svg";
import rewards from "../../assets/img/welcome/rewards.svg";

const MainAbout = () => (
    <div
        id={`about`}
        className={`flex flex-col -mt-10 bg-white rounded-tl-[48px] rounded-tr-[48px]`}
    >
        <div
            id={`content`}
            className={`mt-[20vh] lg:mt-[10vh] base flex flex-col items-stretch px-[5%]`}
        >
            <div>
                <p className={`text-[72px] md:text-[50px] sm:text-[40px]`}>
                    О программе
                </p>
            </div>
            <div
                className={`flex flex-initial lg:flex-col lg:w-full justify-between mt-20 sm:mt-10`}
            >
                <div>
                    <p
                        className={`font-light text-[36px] w-[800px] lg:w-full leading-[60px] md:text-[28px] sm:text-[24px]`}
                    >
                        Life-long Learning — это платформа для развития компетенций
                        инженеров-проектировщиков, на которой собраны курсы по{" "}
                        <br className={"hidden lg:block md:hidden sm:block"}/>
                        <span className={`inline-flex text-MainAccent`}>
              <Typewriter
                  options={{
                      strings: [
                          "низкому напряжению",
                          "среднему напряжению",
                          "информационному моделированию",
                          "АСУТП",
                          "автоматизации зданий",
                      ],
                      autoStart: true,
                      loop: true,
                  }}
              />
            </span>
                    </p>
                </div>
                <p
                    className={`w-[800px] ml-[10vw] lg:ml-0 lg:w-full text-[22px] leading-[40px] lg:mt-16 md:text-[18px] md:mt-12`}
                >
                    Узнавайте о современных тенденциях в области проектирования,
                    технических решениях и новинках оборудования. Составляйте
                    индивидуальный план обучения в соответствии с вашей специализацией,
                    получайте сертификаты и приятные сувениры за прохождение тренингов.
                    Большинство курсов на платформе абсолютно бесплатны.
                </p>
            </div>
            <div
                id={`cards`}
                className={`mt-48 lg:mt-24 flex flex-row lg:flex-wrap justify-between sm:justify-center mb-48 base font-medium text-[24px] md:mt-12 sm:overflow-visible sm:text-[18px]`}
            >
                <div
                    className={`bg-SecondaryAccent-veryLight w-[330px] lg:w-[298px] lg:h-[276px] md:w-[223px] h-[368px] rounded-[32px] flex flex-col items-center justify-center sm:w-[200px] sm:h-[226px]`}
                >
                    <img src={certs} alt=""/>
                    <p className={`pt-8`}>Сертификаты</p>
                </div>
                <div
                    className={`bg-SecondaryAccent-veryLight w-[330px] lg:w-[298px] lg:h-[276px] md:w-[223px]  h-[368px] rounded-[32px] flex flex-col items-center justify-center sm:w-[200px] sm:h-[226px] sm:mt-4`}
                >
                    <img src={versatility} alt=""/>
                    <p className={`pt-8`}>Гибкость</p>
                </div>
                <div
                    className={`bg-SecondaryAccent-veryLight w-[330px] lg:w-[298px] lg:h-[276px] md:w-[223px]  h-[368px] rounded-[32px] flex flex-col items-center justify-center sm:w-[200px] sm:h-[226px] sm:mt-4`}
                >
                    <img src={continuity} alt=""/>
                    <p className={`pt-8`}>Непрерывность</p>
                </div>

                <div
                    className={`bg-SecondaryAccent-veryLight w-[330px] lg:w-[298px] lg:h-[276px] md:w-[223px]  h-[368px] rounded-[32px] flex flex-col items-center justify-center lg:mt-9 sm:w-[200px] sm:h-[226px] sm:mt-4`}
                >
                    <img src={mobility} alt=""/>
                    <p className={`pt-8`}>Мобильность</p>
                </div>
                <div
                    className={`bg-SecondaryAccent-veryLight w-[330px] lg:w-[298px] lg:h-[276px] md:w-[223px]  h-[368px] rounded-[32px] flex flex-col items-center justify-center lg:mt-9 sm:w-[200px] sm:h-[226px] sm:mt-4`}
                >
                    <img src={rewards} alt=""/>
                    <p className={`pt-8`}>Награды</p>
                </div>
            </div>
        </div>
    </div>
);

export default MainAbout;
