import React from "react";
import { CoffeeCardReq } from "../interfaces/CoffeeCard";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import angle from "../assets/img/coffeeAngle.svg";

type IProps = CoffeeCardReq;

const CoffeeSmallCard = (p: IProps) => {
  const link = createPath({
    route: AppRoutes.Learn,
    args: [
      {
        key: "course",
        value: p.courseId,
      },
      { key: "doEnroll", value: 1 },
    ],
  });
  return (
    <Link to={link}>
      <div
        className={`w-[384px] h-[120px] flex justify-between defaultTransition shadow-recomendation`}
      >
        <div className={`h-full max-w-[50%] w-[183px] relative`}>
          <img
            className={`w-full h-full max-w-none object-cover`}
            src={p.imgSrc}
            alt="img"
          />
          <img
            className={`absolute right-0 top-0 w-auto h-auto`}
            src={angle}
            alt="angle"
          />
        </div>
        <div className={`w-[50%] px-4 py-[6px]`}>
          <p className={`base min-h-[90px]`}>{p.text}</p>
          <p
            className={`baseMicro text-SecondaryAccent leading-[11px] text-[0.562em]`}
          >
            БЕСПЛАТНО
          </p>
        </div>
        <div className={`w-[4.2%] h-full bg-MainAccent`}></div>
      </div>
    </Link>
  );
};

export default CoffeeSmallCard;
