import React, { useEffect, useState } from "react";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Sort from "../../components/Sort";
import { UserCertificate } from "../../network/UserApi/userInterfaces";
import { getCertificates } from "../../network/UserApi/userApi";
import CertBlock from "../../components/CertBlock";

const categories = [
  { value: "mv", text: "среднее напряжение" },
  { value: "lv", text: "низкое напряжение" },
  {
    value: "bim",
    text: "информационное моделирование",
  },
  { text: "АСУТП", value: "asutp" },
  { text: "автоматизация зданий", value: "bms" },
  { text: "расчеты и проектирование", value: "soft" },
  { text: "особенности решений Систэм Электрик", value: "segments" },
];

export const Certificates = () => {
  const [category, setCategory] = useState<any>();
  const [isAsc, setIsAsc] = useState<boolean>(true);
  const [certificates, setCertificates] = useState<Array<UserCertificate>>([]);

  useEffect(() => {
    getCertificates().then((r) => {
      setCertificates(r);
    });
      window.scrollTo(0,0)
  }, []);
  const clearCategory = () => {
    setCategory("");
  };

  return (
    <div>
      <div>
        <p className={`base text-[24px]`}>Сертификаты</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          За прохождение Профессиональных курсов вы можете получить сертификат,
          который подтвердит ваши новые знания. Все ваши сертификаты хранятся в
          специальном разделе Сертификаты, откуда вы можете скачать их в любой
          момент.
        </p>
      </div>
      <div className={`flex-row flex justify-between mt-8 `}>
        <Select
          placeholder={"Выберите категорию"}
          options={categories}
          onSelect={setCategory}
          selected={category}
          dropdownClassName={``}
          labelClassName={``}
        />
        {category && (
          <div className={`ml-8`}>
            <Button
              text={"СБРОСИТЬ"}
              onClick={clearCategory}
              className={`!rounded-none border border-SecondaryAccent-one bg-white text-SecondaryAccent baseMicro h-[26px] flex items-center hover:bg-SecondaryAccent hover:text-white`}
            />
          </div>
        )}
        <Sort isAsc={isAsc} setIsAsc={setIsAsc} />
      </div>
      <div>
        <CertBlock cards={certificates} />
      </div>
    </div>
  );
};
