import React from "react";
import CourseCard from "./CourseCard";
import { CatalogCourse } from "../network/CourseApi/courseInterfaces";
import Colors from "../structure/colors";

type IProps = {
  courseCards: Array<CatalogCourse>;
  filter?: filter;
};

type filter = {
  register?: any;
};

const CourseBlock = (p: IProps) => {
  const renderedCards = p.courseCards.map((card) => {
    const filtered =
      p.filter?.register?.value === "none"
        ? card.user_percent === 0 && card.user_subscribed
        : p.filter?.register?.value === "progress"
        ? card.user_percent > 0 && card.user_percent < 100
        : p.filter?.register?.value === "end"
        ? card.user_percent === 100
        : p.filter?.register?.value === "register"
        ? !card.user_subscribed
        : true;
    if (filtered) {
      return (
        <CourseCard
          can_enter={card.can_enter}
          user_subscribed={card.user_subscribed}
          color={Colors.Main}
          imgSrc={card.course_image}
          progress={card.user_percent}
          name={card.course_name}
          cost={card.course_price}
          body={card.course_description}
          courseId={card.course_id}
        />
      );
    }
  });

  return <div className="block">{renderedCards}</div>;
};

export default CourseBlock;
