import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { sendForm } from "../../network/mainRequest";
import PromoEndpoints from "../../structure/promoEndpoints";
import { baseURL } from "../../config";
import { useAuth } from "../../Providers/PromoUserProvider";
import { ImportError, PromoType } from "../../network/PromoApi/promoInterfaces";
import ModalWrapper from "../../components/ModalWrapper";
import Table, { TableTemplate } from "../../components/Create/Table";
import { getStatus, setStatus } from "../../network/PromoApi/promoApi";
import LiderTables from "../../components/Create/LiderTables";

interface IProps {
  selectedTabIndex: number;
  tabIndex: number;
}

const StatisticTab = (props: IProps) => {
  const [info, setInfo] = useAuth();

  const [statisticIsPublic, setStatisticIsPublic] = useState<boolean>(false);

  const files = useRef<any>(null);

  const [errors, setErrors] = useState<ImportError[]>([]);
  const [openSuccessModal, setopenSuccessModal] = useState<boolean>(false);

  const tableTemplate: TableTemplate<ImportError> = {
    data: [
      {
        title: "Строка",
        render: (item, index) => <p>{item.row}</p>,
      },
      {
        title: "Ошибка",
        render: (item, index) => <p>{item.error}</p>,
      },
    ],
  };

  const sendFiles = (file: any) => {
    const form = new FormData();
    form.append("excel", file);
    sendForm<ImportError[]>(PromoEndpoints.UploadFile, form, (r) => {
      if (r.status === "ok") {
        if (r.data.length > 0) setErrors(r.data);
        else setopenSuccessModal(true);
      }
    });
  };

  const changeStatus = useCallback(() => {
    setStatus(!statisticIsPublic).then((r) => {
      setStatisticIsPublic(!statisticIsPublic);
    });
  }, [statisticIsPublic]);

  useEffect(() => {
    getStatus().then((r) => {
      if (r.status === "ok") {
        setStatisticIsPublic(r.data.status);
      }
    });
  }, []);

  return props.selectedTabIndex === props.tabIndex ? (
    <div className="flex flex-col gap-10 justify-center items-center h-full w-full">
      <div className="flex flex-col justify-between h-60">
        <Button
          text={"Загрузить"}
          onClick={() => {
            files.current.click();
          }}
        />
        <a
          href={`${baseURL}${PromoEndpoints.DownloadFile}/${info?.type}`}
          download
          className="text-center underline text-blue-700"
        >
          Скачать текущий селлаут
        </a>
        <a
          href={`/template.xlsx`}
          download
          className="text-center underline text-blue-700"
        >
          Скачать шаблон таблицы
        </a>
        <Button
          onClick={changeStatus}
          text={statisticIsPublic ? "Закрыть таблицы" : "Открыть таблицы"}
        />
      </div>
      {!openSuccessModal && (
          <LiderTables type={PromoType.ONE} fromAdmin />
      )}

      <input
        onChange={(event) => {
          if (
            event.currentTarget.files &&
            event.currentTarget.files.length > 0
          ) {
            sendFiles(event.currentTarget.files[0]);
          }
        }}
        type="file"
        ref={files}
        id="fileInput"
        name="project3"
        accept=".xlsx, .xls"
        className="hidden"
      ></input>
      <ModalWrapper
        header={""}
        text={""}
        visible={errors.length > 0}
        onClose={() => {
          setErrors([]);
        }}
      >
        <Table template={tableTemplate} data={errors} />
      </ModalWrapper>
      <ModalWrapper
        header={"Данные успешно загружены"}
        text={""}
        visible={openSuccessModal}
        onClose={() => setopenSuccessModal(false)}
      ></ModalWrapper>
    </div>
  ) : null;
};

export default StatisticTab;
