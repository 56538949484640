import React from "react";
import TimerSection from "./TimerSection";
import useTimer from "../utils/timeHandler";

type IProps = {
  sessionDate: Date;
};

const TimerBlock = (p: IProps) => {
  const val = useTimer(p.sessionDate);
  return (
    <div className={`flex flex-row`}>
      <TimerSection description={`Дней`} value={val.days > 0 ? val.days : 0} />
      <TimerSection
        description={`Часов`}
        value={val.hours > 0 ? val.hours : 0}
      />
      <TimerSection
        description={`Минут`}
        value={val.minutes > 0 ? val.minutes : 0}
      />
      <TimerSection
        description={`Секунд`}
        value={val.seconds > 0 ? val.seconds : 0}
      />
    </div>
  );
};

export default TimerBlock;
