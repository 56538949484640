import React from "react";
import logo from "../assets/img/arrDowwn.png";

interface IProps {
  ascText?: string;
  decText?: string;
  isAsc: boolean;
  setIsAsc(v: boolean): void;
  sortLabel?: string;
}

const Sort = (p: IProps) => {
  const handleSortClick = () => {
    p.setIsAsc(!p.isAsc);
  };

  return (
    <div
      onClick={() => handleSortClick()}
      className="flex items-center cursor-pointer"
    >
      <p className="sortfirstLabel">
        {p.sortLabel ? p.sortLabel : "сортировать:"}
      </p>
      <p className="sortAscOrDesc">
        {p.isAsc
          ? p.ascText
            ? p.ascText
            : "сначала новые"
          : p.decText
          ? p.decText
          : "сначала старые"}
      </p>
      <img
        src={logo}
        className={`${p.isAsc ? "rotate-180" : ""} duration-300 w-2 h-2 mt-0.5`}
        alt="arrow"
      />
    </div>
  );
};

export default Sort;
