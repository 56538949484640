import React, { useEffect, useState } from "react";
import TableBlock from "./TableBlock";
import ModalWrapper from "./ModalWrapper";
import Button from "./Button";
import arrow from "../assets/img/arrowSelected.svg";
import close from "../assets/img/close.svg";
import { GetCommandRes } from "../network/CommandApi/commandInterfaces";
import {
  deleteSubordinates,
  getCommand,
  getTeamCourses,
  subscribe,
  subscribeSubordinates,
} from "../network/CommandApi/commandApi";
import CourseDirections from "../structure/courseDirections";

type steps = {
  step: "init" | "module" | "course" | "end";
};

const CommandBlock = () => {
  const [multiSelected, setMultiSelected] = useState<Array<number>>([]);
  const [haveSubscription, setHaveSubscription] = useState<number>(0);
  const [teamCourses, setTeamCourses] = useState<any>();
  const [selectedTeamCourse, setSelectedTeamCourse] = useState<string>("");
  const [team, setTeam] = useState<GetCommandRes>();
  const [step, setStep] = useState<steps>({ step: "module" });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [coursesSelected, setCoursesSelected] = useState<Array<number>>([]);

  const subscribeUsers = (multiSelected: Array<number>) => {
    if ((team?.user_all_subscribes || 0) - multiSelected.length < 0) {
      setStep({ step: "init" });
      setModalVisible(true);
      setMultiSelected([]);
    }
    let arr: Array<string> = [];
    multiSelected.map((el) => {
      arr.push(el.toString());
    });
    subscribe(arr).then();
    setMultiSelected([]);
    getCommand().then((r) => {
      setTeam(r);
    });
  };

  const subscribeToCourses = () => {
    let arr: Array<string> = [];
    multiSelected.map((el) => {
      arr.push(el.toString());
    });
    const coursesObj: any = {};
    for (const u of coursesSelected) {
      coursesObj[u] = "";
    }
    subscribeSubordinates({
      users: arr,
      courses: coursesObj,
    }).then(() => {
      setCoursesSelected([]);
      setSelectedTeamCourse("");
      setMultiSelected([]);
      setStep({ step: "end" });
      getCommand().then((r) => {
        setTeam(r);
      });
    });
  };

  const deleteUsers = (multiSelected: Array<number>) => {
    let arr: Array<string> = [];
    multiSelected.map((el) => {
      arr.push(el.toString());
    });
    deleteSubordinates({ user_ids: arr }).then();
    setMultiSelected([]);
    getCommand().then((r) => {
      setTeam(r);
    });
  };

  useEffect(() => {
    const count = multiSelected.length;
    let subs = 0;
    for (const u of multiSelected) {
      team?.employers.map((el) => {
        if (el.employee_id === u) {
          if (el.subscribe) {
            subs++;
          }
        }
      });
    }
    if (subs === count) {
      setHaveSubscription(1);
    } else {
      setHaveSubscription(0);
    }
  }, [multiSelected]);

  useEffect(() => {
    getTeamCourses({ user_id: team?.employers[0].employee_id || 0 }).then(
      (r) => {
        setTeamCourses(r);
      }
    );
    getCommand().then((r) => {
      setTeam(r);
    });
  }, []);
  const toggleModal = () => {
    setModalVisible(!modalVisible);
    setStep({ step: "module" });
  };
  return (
    <div>
      <article className={`base text-3xl leading-[49x] text-DarkAccent`}>
        Команда
      </article>
      {(team?.user_used_subscribes  && team?.user_all_subscribes ) ? (
        <div className={`flex flex-row items-center mb-6`}>
          <div
            className={`w-3 h-3 bg-SecondaryAccent-light rounded-[100%] relative mr-3 `}
          ></div>
          <p className={`base text-SecondaryAccent-dark`}>
            <span className={`text-MainAccent `}>
              {team?.user_used_subscribes}
            </span>
            /
            {(team?.user_all_subscribes || 0) +
              (team?.user_used_subscribes || 0)}{" "}
            подписок активированы
          </p>
        </div>
      ):null}
      {team && (
        <TableBlock
          team={{ ...team }}
          selectedUsers={multiSelected}
          setSelectedUsers={setMultiSelected}
        />
      )}

      <ModalWrapper
        header={
          step.step === "init"
            ? "НАЧНАЧИТЬ ПОДПИСКУ"
            : step.step === "module"
            ? "ВЫБЕРИТЕ МОДУЛЬ"
            : step.step === "course"
            ? "ВЫБЕРИТЕ КУРС"
            : step.step === "end"
            ? "УСПЕШНО!"
            : ""
        }
        visible={modalVisible}
        text={""}
        onClose={toggleModal}
      >
        {step.step === "init" && (
          <div>
            {team && (
              <div>
                <p className={`base mb-4`}>
                  {`Вы не можете назначить подписки всем выбранным сотрудникам, количество
          доступных подписок: ${team.user_all_subscribes}`}
                </p>
              </div>
            )}
          </div>
        )}
        {step.step === "module" && (
          <div>
            {Object.keys(teamCourses?.courses || {}).map((el) => {
              return (
                <div
                  className={`flex flex-row mb-4`}
                  onClick={() => {
                    setSelectedTeamCourse(el);
                    setStep({ step: "course" });
                  }}
                >
                  <img src={arrow} alt="arrow" />
                  <p className={`ml-2 base`}>{CourseDirections[el]}</p>
                </div>
              );
            })}
          </div>
        )}
        {step.step === "course" && (
          <div>
            {teamCourses?.courses[selectedTeamCourse].map((el: any) => 
               (
                <div className={`flex flex-row base items-start mb-1`}>
                  {haveSubscription === 1 && (
                    <input
                      onChange={() => {
                        if (!coursesSelected.includes(el.id)) {
                          setCoursesSelected([...coursesSelected, el.id]);
                        } else {
                          setCoursesSelected(
                            coursesSelected.filter((e: any) => e !== el.id)
                          );
                        }
                      }}
                      checked={coursesSelected.includes(el.id)}
                      className={`mt-1 mr-2`}
                      type="checkbox"
                      name=""
                      id={el.id}
                    />
                  )}
                  {haveSubscription === 0 && el.price > 0 && (
                    <img className={`mt-1 mr-2`} src={close} alt="close" />
                  )}
                  {haveSubscription === 0 && !el.price && (
                    <input
                      onClick={() => {
                        if (!coursesSelected.includes(el.id)) {
                          setCoursesSelected([...coursesSelected, el.id]);
                        } else {
                          setCoursesSelected(
                            coursesSelected.filter((e: any) => e !== el.id)
                          );
                        }
                      }}
                      className={`mt-1 mr-2`}
                      checked={coursesSelected.includes(el.id)}
                      type="checkbox"
                      name=""
                      id={el.id}
                    />
                  )}
                  <p className="cursor-pointer">{el.name}</p>
                </div>
              )
            )}
            <div className={`flex-row flex justify-between items-center`}>
              <div
                className={`flex w-[72px] mt-[14px] pb-4 justify-between cursor-pointer base items-center text-MainAccent`}
                onClick={() => {
                  setStep({ step: "module" });
                }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 2L2 7L7 12"
                    stroke="#00AC86"
                    stroke-width="2"
                    stroke-linecap="square"
                  ></path>
                  <line
                    x1="2"
                    y1="7"
                    x2="16"
                    y2="7"
                    stroke="#00AC86"
                    stroke-width="2"
                  ></line>
                </svg>
                <p>Назад</p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    subscribeToCourses();
                  }}
                  text={"Записать"}
                  className={`mr-5`}
                  disabled={coursesSelected.length === 0}
                />
              </div>
            </div>
          </div>
        )}
        {step.step === "end" && (
          <div>
            <div>
              <p className={`base`}>Пользователи успешно записаны на курсы!</p>
            </div>
            <div className={`flex flex-row justify-center mt-6`}>
              <Button
                text={"Продолжить"}
                onClick={() => {
                  setModalVisible(false);
                  setStep({ step: "init" });
                }}
              />
            </div>
          </div>
        )}
      </ModalWrapper>
      {team && (
        <div className={`flex flex-row mt-8`}>
          <Button
            text={"Назначить курс"}
            disabled={!multiSelected.length}
            onClick={() => {
              setModalVisible(true);
            }}
            className={`bg-white border border-MainAccent text-MainAccent hover:text-white`}
          />

          <Button
            onClick={() => {
              deleteUsers(multiSelected);
            }}
            text={"Удалить"}
            className={`ml-3 bg-white border border-CloseDeadlineAccent text-CloseDeadlineAccent hover:text-white hover:bg-CloseDeadlineAccent`}
            disabled={!multiSelected.length}
          />
        </div>
      )}
    </div>
  );
};

export default CommandBlock;
