import React, { useEffect, useState } from "react";
import CourseStageBlock from "../../components/CourseStageBlock";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetEducationCourseRes,
  Step,
} from "../../network/CourseApi/courseInterfaces";
import {
  acceptStep,
  getEducationCourse,
  getIframeTest,
  getStartTest,
  getVideoOfLo,
} from "../../network/CourseApi/courseApi";
import Button from "../../components/Button";
import UserTest from "../../components/UserTest";

const VIDEO_SECONDS = 20;

export const Learn = () => {
  const [course, setCourse] = useState<GetEducationCourseRes>();
  const [currentStep, setCurrentStep] = useState<Step>();
  const [learnInterface, setLearnInterface] = useState<any>();
  const [frame, setFrame] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);

  let flag = true;

  useEffect(() => {
      window.scrollTo(0,0)
    getEducationCourse({ course_id: Number(current) }).then((r) => {
      setCourse(r);
      for (const u of r.steps) {
        if (u.user_percent < 100) {
          setCurrentStep(u);
          break;
        }
      }
    });
    window.addEventListener("message", (e) => {
      console.log("NETWORK", e.origin,e.data);
      
      if(e.origin == "http://127.0.0.1:5500"){
        alert(e.data)
      if (e.data == true && currentStep) {
        handleAccept(currentStep);
      }}
    });
  }, []);

  const handleAccept = (currentStep: { step_id: string }) => {
    acceptStep({ course_id: currentStep.step_id, bookmark: 0 }).then(() => {
      getEducationCourse({ course_id: Number(current) }).then((r) => {
        setCourse(r);
      });
    });
  };

  useEffect(() => {
    switch (currentStep?.type) {
      case "link":
        getVideoOfLo({
          device: "desktop",
          lo_id: Number(currentStep?.step_id),
        }).then((r) => {
          setLearnInterface(
            <video
              className={`w-full h-full`}
              src={r.video_url + "/download"}
              controls={true}
              autoPlay={true}
              onTimeUpdate={(event) => {
                if (
                  event.currentTarget.duration -
                    event.currentTarget.currentTime <=
                    VIDEO_SECONDS &&
                  flag
                ) {
                  handleAccept(currentStep);
                  flag = false;
                }
              }}
              controlsList={"nodownload"}
            ></video>
          );
        });
        break;
      case "scormorg":
        getIframeTest({
          device: "desktop",
          lo_id: currentStep?.step_id,
        }).then((r) => {
          setFrame(r.test_url);
          setLearnInterface(
            <Button
              text={"Начать"}
              onClick={() => {
                setShow(true);
              }}
            />
          );
        });
        break;
      case "test":
        getStartTest({ test_id: Number(currentStep?.step_id) }).then((r) => {
          setLearnInterface(
            <UserTest
              test_id={r.test_id}
              items={r.items}
              handleAccept={handleAccept}
            />
          );
        });
        break;
    }
  }, [currentStep]);
  console.log(currentStep);
  console.log(learnInterface);
  const handleClick = (step: Step) => {
    setCurrentStep({ ...step });
  };
  const navigate = useNavigate();
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("course");
  return (
    <div className={`w-[795px]`}>
      <div
        className={`flex w-[72px] mt-[14px] pb-4 justify-between cursor-pointer base items-center text-MainAccent`}
        onClick={() => {
          navigate(-1);
        }}
      >
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2L2 7L7 12"
            stroke="#00AC86"
            stroke-width="2"
            stroke-linecap="square"
          ></path>
          <line
            x1="2"
            y1="7"
            x2="16"
            y2="7"
            stroke="#00AC86"
            stroke-width="2"
          ></line>
        </svg>
        <p>Назад</p>
      </div>
      {course?.steps && course.steps.length < 1 && (
        <div>
          <div>
            <p className={`base text-[20px]`}>{course?.course_name}</p>
          </div>
          <div className={`grid grid-cols-[1fr,337px]`}>
            <div
              className={`flex justify-center items-center mr-2 relative min-h-[390px] w-[750px]`}
            >
              <p className={`baseBold`}>
                На данный момент ничего нет, проверьте позже
              </p>
            </div>
          </div>
        </div>
      )}
      {course?.steps && course.steps.length >= 1 && (
        <div>
          <div>
            <p className={`base text-[20px]`}>{course?.course_name}</p>
          </div>
          <div className={`grid grid-cols-[1fr,337px]`}>
            <div
              className={`flex justify-center items-center mr-2 relative min-h-[390px] w-[750px]`}
            >
              {learnInterface}
            </div>

            <div>
              <CourseStageBlock
                rows={course?.steps}
                onClick={handleClick}
                selectedStep={Number(currentStep?.step_id) || 0}
              />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: course?.course_description || "",
              }}
            ></div>
            <div className={`${show ? "" : "hidden"}`}>
              <div
                className={`bg-MainAccent w-full h-[100px] fixed top-0 left-0`}
              >
                <svg
                  onClick={() => {
                    setShow(!show);
                  }}
                  className={`cursor-pointer w-[24px] h-[24px] mt-1 fixed right-2 top-1`}
                  width={20}
                  height={20}
                  viewBox={`0 0 13 13`}
                  fill={`none`}
                  xmlns={`http://www.w3.org/2000/svg`}
                >
                  <line
                    x1="0.353553"
                    y1="0.353539"
                    x2="12.3744"
                    y2="12.3744"
                    stroke="#FFF"
                  ></line>
                  <line
                    x1="12.3747"
                    y1="0.353869"
                    x2="0.353237"
                    y2="12.3539"
                    stroke="#FFF"
                  ></line>
                </svg>
              </div>
              <iframe
                className={`fixed top-10 left-0 bottom-0 right-0 w-full h-full p-0 overflow-hidden z-40 `}
                src={frame}
                title={`scorm`}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
