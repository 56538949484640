import clsx from "clsx";
import { ReactNode } from "react";

interface IStickProps {
  children: ReactNode;
}

interface IProps {
  countA: number;
  countB: number;
  countC: number;
}

const StickLabel = (props: IStickProps) => {
  return (
    <div className="relative w-[2px] h-full bg-gray-500">
      <p className="absolute top-full pt-3 text-center transform -translate-x-1/2">
        {props.children}
      </p>
    </div>
  );
};

const CountProgrees = (props: IProps) => {
  const points = [props.countB, props.countC];
  points.sort((a,b)=> a-b)
  console.log(points);
  

  const progressPercent = (props.countC * 100) / props.countB;
  const  realPercent = progressPercent <= 100 ? progressPercent : 100
  return (
    <div>
      <div
        className={clsx(
          "flex relative h-12 bg-slate-400",
          props.countC < props.countB ? "justify-end" : "justify-start"
        )}
      >
        <div
          className="bg-MainAccent flex justify-end h-full absolute left-0"
          style={{
            width: `${realPercent}%`,
          }}
        >
          <StickLabel>{props.countC}</StickLabel>
        </div>
        <StickLabel>{props.countB}</StickLabel>
      </div>
      <p className="mt-20 text-3xl font-bold text-center">
      {Math.round(progressPercent)} %
      </p>
    </div>
  );
};

export default CountProgrees;
