import { IconProps } from './IconBase';

export default function SortAscIcon({ size = 24, className }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			className={className}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20 17H12M20 12H8.8M20 7H4'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
