import { useCallback, useEffect, useState } from "react";
import Tabbar from "./Tabbar";
import Table, { TableTemplate } from "./Table";
import {
  PromoLider,
  PromoLidersResp,
  PromoType,
} from "../../network/PromoApi/promoInterfaces";
import { getLiders } from "../../network/PromoApi/promoApi";

interface IProps {
  type: PromoType;
  fromAdmin?:boolean
}
interface ISubTabProps {
  baseData: PromoLider[];
  heightData: PromoLider[];
}

const TABS = ["Дилеры", "Щитовики", "OEM","CMO"];
const SUB_TABS = ["Селлаут", "Рост"];

const tableTemplate: TableTemplate<PromoLider> = {
  data: [
    {
      title: "Место",
      render: (item, index) => <p>{index + 1}</p>,
    },
    {
      title: "Код компании",
      render: (item, index) => <p>{item.id}</p>,
    },
    {
      title: "Средневзвешенный оборот 2023-2024г. (млн. руб.)",
      render: (item, index) => <p>{item.count}</p>,
    },
  ],
};

const LidersTab = (props: ISubTabProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const hiddenByIndex = useCallback(
    (index: number) => {
      return tabIndex === index ? "block" : "hidden";
    },
    [tabIndex]
  );
  return (
    <div>
      <Tabbar onSelect={setTabIndex} titles={SUB_TABS} />
      <div className={hiddenByIndex(0)}>
        <Table template={tableTemplate} data={props.baseData} />
      </div>
      <div className={hiddenByIndex(1)}>
        <Table template={tableTemplate} data={props.heightData} />
      </div>
    </div>
  );
};

const LiderTables = (props: IProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [data, setData] = useState<PromoLidersResp>({
    base: {
      diler: [],
      oem: [],
      shield: [],
      cmo: [],
    },
    height: {
      diler: [],
      oem: [],
      shield: [],
      cmo: [],
    },
  });
  const [visibleData, setVisibleData] = useState<boolean>(false);

  const hiddenByIndex = useCallback(
    (index: number) => {
      return tabIndex === index ? "block" : "hidden";
    },
    [tabIndex]
  );

  useEffect(() => {
    getLiders(props.type,  props.fromAdmin).then((r) => {
      if (r.status == "ok") {
        setData(r.data);
        setVisibleData(true);
      }
    });
  }, []);

  return visibleData ? (
    <div className={`bg-white mb-10 px-5 ${props.fromAdmin && "w-full"}`}>
      <Tabbar onSelect={setTabIndex} titles={TABS} />
      <div className={hiddenByIndex(0)}>
        <LidersTab baseData={data.base.diler} heightData={data.height.diler} />
      </div>
      <div className={hiddenByIndex(1)}>
        <LidersTab
          baseData={data.base.shield}
          heightData={data.height.shield}
        />
      </div>
      <div className={hiddenByIndex(2)}>
        <LidersTab baseData={data.base.oem} heightData={data.height.oem} />
      </div>
      <div className={hiddenByIndex(3)}>
        <LidersTab baseData={data.base.cmo} heightData={data.height.cmo} />
      </div>
    </div>
  ) : null;
};

export default LiderTables;
