import React, { useState } from "react";
import InputForm from "../InputForm";
import Button from "../Button";
import logo from "../../assets/img/logo.svg";
import vk from "../../assets/img/welcome/vk.svg";
import youtube from "../../assets/img/welcome/youtube.svg";
import telegram from "../../assets/img/welcome/telegram.svg";
import ok from "../../assets/img/welcome/ok.svg";

type IProps = {
  button: (email: string) => void;
};

const MainFooter = (p: IProps) => {
  const [email, setEmail] = useState<string>("");
  return (
    <div
      className={`w-full h-[500px] lg:h-[550px] mt-[-60px] rounded-tl-[48px] rounded-tr-[48px] bg-white z-10 relative flex justify-between flex-col`}
    >
      <div
        className={`flex items-center flex-row px-[10%] mt-[2%] text-[48px] lg:flex-col lg:justify-start lg:items-start lg:text-[38px]`}
      >
        <p>
          Начните <span className={`text-MainAccent`}>обучение</span> прямо
          сейчас
        </p>
        <div
          className={`flex-row flex lg:w-full lg:mt-10 lg:items-start sm:flex-col`}
        >
          <InputForm
            onChange={(e) => {
              setEmail(e);
            }}
            placeholder={"E-mail"}
            name={"email"}
            className={`w-[400px] lg:w-full text-[24px] ml-[7%] lg:ml-0 border pl-6 rounded-[32px] h-[72px]`}
          />
          <Button
            onClick={() => {
              p.button(email);
            }}
            text={"Зарегистрироваться"}
            className={`text-[18px] ml-[1%] rounded-[32px] h-[72px] w-[284px] sm:mt-4 sm:mb-4 `}
          />
        </div>
      </div>
      <div
        className={`h-[300px] bg-SecondaryAccent-veryLight w-full rounded-tl-[48px] rounded-tr-[48px]`}
      >
        <div className={`flex flex-row justify-between px-[10%]`}>
          <div className={`mt-[6%] `}>
            <img
              src={logo}
              alt=""
              className={`w-[527px] h-[80px] lg:w-[300px]`}
            />
          </div>
          <div className={`mt-[6%] flex flex-row`}>
            <a target="_blank" href="https://vk.com/systemeelectric" rel="noreferrer">
              <img src={vk} alt="" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCfSQiFykGRQRb6dq1-mznrw" rel="noreferrer">
              <img src={youtube} alt="" />
            </a>
            <a target="_blank" href="https://t.me/systemeelectric_official" rel="noreferrer">
              <img src={telegram} alt="" />
            </a>
            <a target="_blank" href="https://ok.ru/group/70000000047930" rel="noreferrer">
              <img src={ok} alt="" />
            </a>
          </div>
        </div>
        <div
          className={`px-[10%] mt-[1.5%] lg:mt-[4%] flex-row flex justify-between`}
        >
          <div>
            <a target="_blank" href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh" rel="noreferrer">Политика конфиденциальности</a>
          </div>
          <div>
            <p>© 2023 «Систэм Электрик» </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
