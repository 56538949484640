import React from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../structure/routes";
import { createPath } from "../utils/linkHandler";

type IProps = {
  courseId: number;
  imgSrc: string;
  cardText: string;
};

const RecomendationCard = (p: IProps) => {
  const args = {
    route: AppRoutes.Learn,
    args: [{ key: "course", value: p.courseId }],
  };
  return (
    <Link to={createPath(args)}>
      <div className="relative mt-[22px] h-[112px] flex justify-between cursor-pointer transition duration-200 ease-in-out shadow-recomendation hover:scale-[1.02] hover:shadow-recomendationHover">
        <img
          className="object-cover w-[36%] h-[112px]"
          src={p.imgSrc}
          alt="testpic"
        />
        <div className="w-[59.2%] pl-4 pt-[6px] block">
          <p className="leading-6 max-w-[150px] text-DarkAccent base overflow-hidden max-h-full">
            {p.cardText}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default RecomendationCard;
