import { useCallback, useEffect, useState } from "react";
import Table, { TableTemplate } from "../../components/Create/Table";
import EditIcon from "../../components/Create/icons/EditIcon";
import {
  PromoUserResp,
  PromoUser,
} from "../../network/PromoApi/promoInterfaces";
import UserEditModal from "../../components/Create/UserEditModal";
import DeleteIcon from "../../components/Create/icons/DeleteIcon";
import CheckIcon from "../../components/Create/icons/CheckIcon";
import {
  confirmUser,
  editPromoUser,
  getPromoUsers,
} from "../../network/PromoApi/promoApi";
import Button from "../../components/Button";
import { baseURL } from "../../config";
import PromoEndpoints from "../../structure/promoEndpoints";

interface IProps {
  selectedTabIndex: number;
  tabIndex: number;
  users: PromoUser[];
  setUsers: React.Dispatch<React.SetStateAction<PromoUser[]>>;
  isActive?: boolean;
}

const UsersTab = (props: IProps) => {
  const [selectedUser, setSetselectedUser] = useState<PromoUser>();

  const [saveIndex, setSaveIndex] = useState<number>(0);

  const onSave = useCallback(
    (user: PromoUser) => {
      props.setUsers((u) => {
        u[saveIndex] = user;
        return [...u];
      });
      editPromoUser(user);
      setSetselectedUser(undefined);
    },
    [saveIndex]
  );

  const removeByIndex = useCallback((index: number) => {
    props.setUsers((u) => {
      u.splice(index, 1);
      return [...u];
    });
  }, []);

  const onConfirm = useCallback(
    (index: number) => {
      confirmUser({ ids: [props.users[index].id], status: true });
      removeByIndex(index);
    },
    [removeByIndex, props.users]
  );

  const onDenied = useCallback(
    (index: number) => {
      console.log(index);
      confirmUser({ ids: [props.users[index].id], status: false });

      removeByIndex(index);
    },
    [removeByIndex, props.users]
  );

  const getUsers = useCallback(() => {
    getPromoUsers(Boolean(props.isActive)).then((r) => {
      if (r.status === "ok") props.setUsers(r.data);
    });
  }, [props]);

  useEffect(() => {
    if (props.selectedTabIndex === props.tabIndex) getUsers();
  }, [props.selectedTabIndex]);

  const tableTemplate: TableTemplate<PromoUser> = {
    data: [
      {
        title: "ФИО",
        render: (item, index) => (
          <p key={index}>
            {item.name} {item.lastname} {item.patronomyc}
          </p>
        ),
      },
      {
        title: "Компания",
        render: (item, index) => <p key={index}>{item.company}</p>,
      },
      {
        title: "Должность",
        render: (item, index) => <p key={index}>{item.state}</p>,
      },
      {
        title: "Телефон",
        render: (item, index) => <p key={index}>{item.phone}</p>,
      },
      {
        title: "Почта",
        render: (item, index) => <p key={index}>{item.login}</p>,
      },
      {
        title: "Код компании",
        render: (item, index) => <p key={index}>{item.companyId}</p>,
      },
      {
        render: (item, index) => (
          <div
            onClick={() => {
              setSetselectedUser(item);
              setSaveIndex(index);
            }}
            className="cursor-pointer"
          >
            <EditIcon />
          </div>
        ),
      },
      !props.isActive
        ? {
            render: (item, index) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  onConfirm(index);
                }}
              >
                <CheckIcon className="fill-green-400" />
              </div>
            ),
          }
        : {},
      {
        render: (item, index) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              onDenied(index);
            }}
          >
            <DeleteIcon className="fill-red-400" />
          </div>
        ),
      },
    ],
  };

  return props.selectedTabIndex === props.tabIndex ? (
    <div>
      <Button
        className="mb-5"
        text={"Обновить"}
        onClick={() => {
          getUsers();
        }}
      />
      {props.isActive && (
        <a
          href={`${baseURL}${PromoEndpoints.DownloadUsers}`}
          className="ml-4 underline text-blue-700"
          download
        >
          Скачать таблицу
        </a>
      )}

      <div>
        <Table template={tableTemplate} data={props.users} />
      </div>
      {selectedUser && (
        <UserEditModal
          visible
          onClose={() => {
            setSetselectedUser(undefined);
          }}
          user={selectedUser}
          onSave={onSave}
        />
      )}
    </div>
  ) : null;
};

export default UsersTab;
