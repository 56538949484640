import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import { Link, redirect, useNavigate } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import arrow from "../../assets/img/arrow-left.svg";
import InputForm from "../../components/InputForm";
import Button from "../../components/Button";
import { buildObject } from "../../utils/buildObject";
import { passwordReset } from "../../network/UserApi/userApi";

import BackgroundImage from "../../assets/img/welcome/recoveryBack.png";
import Footer from "../../components/Footer";
import PromoFooter from "../../components/PromoFooter";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [copyPassword, setCopyPassword] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const params: any = new URL(String(url)).searchParams;
    if (!params.get("key")) {
      redirect(AppRoutes.Welcome);
    } else setKey(params.get("key"));
  }, []);
  const handleReset = () => {
    passwordReset({ password, key }).then((r: any) => {
      if (r.isPromo) {
        navigate(AppRoutes.CreateOne);
      } else navigate(AppRoutes.Welcome);
    });
  };

  return (
    <div>
      <div className=" relative flex flex-col h-screen pb-0">
        <img
          className=" z-0 absolute top-0 left-0 w-full h-full object-cover"
          src={BackgroundImage}
          alt=""
        />
        <div className="flex relative justify-between ml-32 mt-5 flex-grow-0 min-h-[70px]">
          <img className="h-12" src={logo} alt="logo" />
        </div>
        <main className="flex z-10 justify-center items-start w-[100%] bg-contain	flex-grow">
          <div className="mt-[200px] mb-[100px] flex flex-col items-center min-w-[450px] max-w-[450px] p-[40px] border-[16px] border-white bg-white rounded-xl">
            <div className={`mt-[-15px] text-center`}>
              <p className={`base text-[20px]`}>Восстановление пароля</p>
              <p className={`base text-SecondaryAccent mt-4 mb-4`}>
                Вы перешли по ссылке сброса пароля для вашего аккаунта.
                Пожалуйста, установите новый пароль
              </p>
            </div>
            <div className={`w-full mt-0 `}>
              <InputForm
                name={"new_password"}
                placeholder={"Новый пароль"}
                required={true}
                onChange={setPassword}
              />
              <InputForm
                name={"password_copy"}
                placeholder={"Подтвердите пароль"}
                required={true}
                onChange={setCopyPassword}
              />
              <Button
                className="w-full h-14 font-bold"
                text="Сменить пароль"
                disabled={password.length < 3 || password !== copyPassword}
                isAttention={false}
                onClick={handleReset}
              />
            </div>
          </div>
        </main>
      </div>
      <PromoFooter />
    </div>
  );
};
