import React from "react";
import CertCard from "./CertCard";
import { UserCertificate } from "../network/UserApi/userInterfaces";

type IProps = {
  cards: Array<UserCertificate>;
};

const CertBlock = (p: IProps) => {
  const renderedCards = p.cards.map((card) => {
    return (
      <CertCard
        name={card.name}
        link={card.link}
        description={card.description}
        code={card.code}
        date={card.date}
      />
    );
  });

  let skeletons = [];

  for (let i = 0; i < 2; i++) {
      skeletons.push(<div role="status"
          className="w-48 h-[270px] mt-3 mr-6 mb-2 space-y-8 animate-pulse rtl:space-x-reverse">
          <div className="flex flex-col items-center justify-center w-full h-28 bg-gray-300 rounded dark:bg-gray-400">
              <svg className="w-10 h-10 text-gray-200 dark:bg-gray-400" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path
                      d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>
          </div>
          <div className="w-full">
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
          </div>
          <span className="sr-only">Loading...</span>
      </div>)
  }

  return (
    <div className="flex flex-wrap mt-[15px] ml-[-9px]">{renderedCards.length > 0 ? renderedCards : skeletons}</div>
  );
};

export default CertBlock;
