import React from "react";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

type IProps = {
  username: string;
  picture: string;
};

const UserProfile = (p: IProps) => {
  return (
    <div className="flex ">
      <div className="pt-[10px] text-SecondaryAccent-dark">
        <Link to={AppRoutes.UserPage}>
          <p>{p.username}</p>
        </Link>
      </div>
      <div className="ml-4 w-10 h-10 rounded-full overflow-hidden">
        <Link to={AppRoutes.UserPage}>
          <img className="w-[100%]" src={p.picture} alt="profile" />
        </Link>
      </div>
      <div className="ml-[24px] mt-[8px] w-6 h-6 text-center bg-SecondaryAccent-table text-white leading-6 rounded-md font-[11px]">
        <p>RU</p>
      </div>
    </div>
  );
};

export default UserProfile;
