import React from "react";
import background from "../../assets/img/welcome/prizesBg.svg";
import "keen-slider/keen-slider.min.css";
import {useKeenSlider} from "keen-slider/react";
import cofer from "../../assets/img/welcome/prizes/cofer.png";
import backpack from "../../assets/img/welcome/prizes/backpack.png";
import umbrella from "../../assets/img/welcome/prizes/umbrella.png";
import pled from "../../assets/img/welcome/prizes/pled.png";
import speaker from "../../assets/img/welcome/prizes/speaker.png";
import headlight from "../../assets/img/welcome/prizes/headlight.png";
import fri from "../../assets/img/welcome/prizes/fri.png";
import birka from "../../assets/img/welcome/prizes/birka.png";
import note from "../../assets/img/welcome/prizes/note.png";
import PrizeCard from "./prizeCard";

const prizes = [
    {img: cofer, name: "Кофер", cost: 130},
    {img: backpack, name: "Рюкзак для ноутбука", cost: 250},
    {img: umbrella, name: "Зонт", cost: 200},
    {img: pled, name: "Плед", cost: 70},
    {img: speaker, name: "Колонка", cost: 250},
    {img: headlight, name: "Налобный фонарь", cost: 200},
    {img: fri, name: "Сумка-холодильник", cost: 180},
    {img: birka, name: "Бирка для багажа", cost: 20},
    {img: note, name: "Ежедневник", cost: 70},
];
const MainPrizes = () => {
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            loop: true,
            slides: {perView: 3.2},
            breakpoints: {
                "(min-width: 1920px)": {
                    slides: {perView: 5.2},
                },
                "(max-width: 1025px)": {
                    slides: {perView: 2.2},
                },
                "(max-width: 769px)": {
                    slides: {perView: 1.6},
                },
                "(max-width: 450px)": {
                    slides: {perView: 1.2},
                },
            },
        },
        [
            // add plugins here
        ]
    );

    const renderedPrizes = prizes.map((el, key) => {
        return (
            <div className={`keen-slider__slide number-slide${key + 1}`}>
                <PrizeCard {...el} />
            </div>
        );
    });

    return (
        <div id="Prizes"
            className={`w-full h-[118vh] bg-[#E2E5EB] z-10 relative flex flex-col items-center justify-center`}
        >
            <div
                className={`flex-col flex justify-between items-center text-center relative`}
            >
                <div className={`pb-[8%] text-[48px]`}>
                    <p>Обменивай на призы</p>
                </div>
                <div className={`pb-[15%] text-[24px] w-[70%] font-light`}>
                    <p>
                        Получайте монеты за прохождение курсов и обменивайте их на подарки в
                        магазине платформы Life-long Learning
                    </p>
                </div>
            </div>
            <div className={`w-full`}>
                <div ref={sliderRef} className="keen-slider">
                    {renderedPrizes}
                </div>
            </div>
            <div className={`absolute left-0 bottom-0 -z-10`}>
                <img src={background} alt=""/>
            </div>
        </div>
    );
};

export default MainPrizes;
