import React from "react";
import progress from "../../assets/img/welcome/progress.png";
import study from "../../assets/img/welcome/studyVector.svg";

const MainStudy = () => (
    <div
        className={`w-full mt-[-60px] rounded-tl-[48px] rounded-tr-[48px] bg-SecondaryAccent-veryLight z-10 relative flex  justify-center overflow-hidden`}
    >
        <div className={`flex flex-col text-center items-center relative`}>
            <div className={`pt-[10%] lg:pt-[15%] w-[60%]`}>
                <p className={`text-[48px]`}>Обучайся</p>
                <div className={`pt-[10%] text-[24px] font-light`}>
                    <p>
                        Формируйте индивидуальный план обучения, отслеживайте прогресс и
                        получайте сертификаты
                    </p>
                </div>
            </div>
            <div className={`pt-[5%]`}>
                <img src={progress} alt=""/>
            </div>
        </div>
        <div className={`absolute left-0 bottom-0 -z-10`}>
            <img src={study} alt=""/>
        </div>
    </div>
);

export default MainStudy;
