import clsx from 'clsx';
import React from 'react';
import Typography from './Typography';

interface Props {
	children: React.ReactNode;
	className?: string;
}

const TableHeader = (props: Props) => {
	return (
		<Typography
			size='h6'
			className={clsx('uppercase outline-none transition-all', props.className)}
		>
			{props.children}
		</Typography>
	);
};

export default TableHeader;
