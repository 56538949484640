import React from "react";
import { learnProgress } from "../utils/completionIndicators";
import { ProgressCircleReq } from "../interfaces/ProgressCircle";
import { getPercent } from "../utils/percentHandler";

type IProps = ProgressCircleReq;
const ProgressCircle = (p: IProps) => {
  return (
    <div className={`relative h-[150px] w-[150px] `}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 42 42"
        className="donut"
        id="user_courses_circle"
      >
        <circle
          className="donut-hole"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
        ></circle>
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#C4C4C4"
          strokeWidth="2"
        ></circle>

        {learnProgress(getPercent(p.done, p.all), p.color)}
      </svg>
      <p
        className={`absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 baseMicro text-SecondaryAccent text-center`}
      >
        <strong
          className={`leading-6 text-[1.33em] text-center text-SecondaryAccent-table`}
        >
          {p.done}/{p.all}
        </strong>
        <br />
        {p.insideCircle}
      </p>
      <p className={`baseMicro text-SecondaryAccent mt-2 text-center `}>
        {p.circleDescription}
      </p>
    </div>
  );
};

export default ProgressCircle;
