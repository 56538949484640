import React from "react";
import CoffeeCard from "./CoffeeCard";
import { GetCoffeeRes } from "../network/CoffeeApi/coffeeInterfaces";
import CoffeeSmallCard from "./CoffeeSmallCard";

type IProps = {
  cards: Array<GetCoffeeRes>;
  small?: boolean;
};

const CoffeeBlock = (p: IProps) => {
  const renderedCards = p.cards.map((card) => {
    if (p.small) {
      return (
        <CoffeeSmallCard
          imgSrc={card.coffee_image}
          text={card.coffee_name}
          courseId={card.coffee_id}
        />
      );
    } else {
      return (
        <CoffeeCard
          imgSrc={card.coffee_image}
          text={card.coffee_name}
          courseId={card.coffee_id}
        />
      );
    }
  });

  return (
    <div
      className={`${
        p.small ? "gap-x-4" : "ml-[-9px]"
      } flex flex-wrap mt-[15px]`}
    >
      {renderedCards}
    </div>
  );
};

export default CoffeeBlock;
