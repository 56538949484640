import { ReactNode, useCallback, useRef, useState } from "react";

interface IProps {
  className?: string;
  children?: ReactNode;
  onChange?: (files: FileList) => void;
}

const DragDropFileContainer = (props: IProps) => {
  const [dragging, setDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      dragCounter.current = 0;
      setDragging(false);

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        props.onChange && props.onChange(event.dataTransfer.files);
        event.dataTransfer.clearData();
      }
    },
    [props]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      
    },
    []
  );

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setDragging(true);
      }
    },
    []
  );
  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setDragging(false);
      }
    },
    []
  );

  return (
    <div
      className={props.className}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {props.children}
      {dragging && (
        <div className="flex justify-center items-center w-[100vw] h-[100vh] fixed left-0 top-0 bg-black opacity-50">
          <p className="text-white font-semibold text-[30px] leading-[48px] z-30">
            Перетащите файл
          </p>
        </div>
      )}
    </div>
  );
};

export default DragDropFileContainer;
