import React, { useState } from "react";
import logo from "../../assets/img/logo.svg";
import arrow from "../../assets/img/arrow-left.svg";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import LoginBlock from "../../components/LoginBlock";
import RegisterBlock from "../../components/RegisterBlock";

const loginCards = [
  {
    placeholder: "Электронная почта",
    name: "email",
  },
  {
    placeholder: "Пароль",
    name: "password",
  },
];

const registerCards = [
  {
    placeholder: "Электронная почта",
    name: "email",
    required: true,
  },
  {
    placeholder: "Имя",
    name: "name",
    required: true,
  },
  {
    placeholder: "Фамилия",
    name: "lastname",
    required: true,
  },
  {
    placeholder: "Пароль",
    name: "password",
    required: true,
  },
  {
    placeholder: "Подтвердите пароль",
    name: "password_copy",
    required: true,
  },
  {
    isSelector: true,
    name: "country",
  },
  {
    placeholder: "Город",
    name: "city",
    required: true,
  },
  { placeholder: "Название компании", name: "company", required: true },
];

type IProps = {
  onAuth: (r: boolean) => void;
};

const Auth = (p: IProps) => {
  const [isRegister, setIsRegister] = useState(false);

  const trueRegister = () => {
    setIsRegister(true);
  };
  const falseRegister = () => {
    setIsRegister(false);
  };

  return (
    <div className="flex flex-col h-[117.6vh] pb-0">
      <div className="flex relative justify-between px-[calc(50%-620px)] flex-grow-0 min-h-[90px]">
        <img src={logo} alt="logo" />
        <Link to={AppRoutes.Home}>
          <div className="flex items-center absolute bottom-[-70%] left-[70px] cursor-pointer">
            <img className="mr-3 h-[15px]" src={arrow} alt="arrow" />
            <p className="base text-white">Назад</p>
          </div>
        </Link>
      </div>
      <main className="flex justify-center items-start w-[100%] bg-gradient-to-r to-[#3dcd58] from-[#0e0353] bg-contain	flex-grow">
        <div className="mt-[200px] mb-[100px] flex flex-col items-center min-w-[450px] max-w-[450px] p-[40px] border-[16px] border-white bg-white rounded-xl">
          <div className="flex relative w-[100%] mb-[30px]">
            <p
              onClick={falseRegister}
              className="w-[50%] my-[10px] baseBold text-center cursor-pointer"
            >
              Вход
            </p>
            <p
              onClick={trueRegister}
              className="w-[50%] my-[10px] baseBold text-center cursor-pointer"
            >
              Регистрация
            </p>
            <span
              className={`absolute ${
                isRegister ? "left-1/2" : "left-0"
              } bottom-0 h-[3px] w-[50%] rounded-sm bg-MainAccent duration-500`}
            ></span>
          </div>
          <LoginBlock
            onAuth={p.onAuth}
            className={isRegister ? "hidden" : ""}
            cards={loginCards}
          />
          <RegisterBlock
            onAuth={p.onAuth}
            className={isRegister ? "" : "hidden"}
            cards={registerCards}
          />
        </div>
      </main>
    </div>
  );
};

export default Auth;
