import React, { useEffect, useState } from "react";
import TableSelector from "./TableSelector";
import { Employer } from "../network/CommandApi/commandInterfaces";

type IProps = {
  rows: Array<Employer>;
  sort: string;
  order: boolean;
  selectedUsers: any;
  setSelectedUsers: (team: any) => void;
};

const TableBody = (p: IProps) => {
  const [rows, setRows] = useState<Array<Employer>>(p.rows);

  const obj = {
    asc: (a: any, b: any) => (a[p.sort] >= b[p.sort] ? 1 : -1),
    desc: (a: any, b: any) => (a[p.sort] <= b[p.sort] ? 1 : -1),
  };

  useEffect(() => {
    const arr = p.rows.sort(obj[p.order ? "asc" : "desc"]);
    setRows([...arr]);
  }, [p.rows, p.sort, p.order]);
  console.log(rows);
  const renderedRows = rows.map((row, key) => {
    return (
      <tr
        className={`w-full h-14 cursor-pointer border-b border-b-SecondaryAccent-veryLight p-[14px] flex items-center`}
      >
        <th
          className={`base text-xs text-SecondaryAccent text-left items-center flex`}
        >
          <div
            className={`w-3 h-3 ${
              row.subscribe ? "bg-MainAccent" : "bg-SecondaryAccent-light"
            } rounded-[100%] relative mr-4`}
          ></div>
        </th>
        <TableSelector
          selectedUsers={p.selectedUsers}
          user={row.employee_id}
          onChange={() => {
            if (!p.selectedUsers.includes(row.employee_id)) {
              p.setSelectedUsers([...p.selectedUsers, row.employee_id]);
            } else {
              p.setSelectedUsers(
                p.selectedUsers.filter((e: any) => e !== row.employee_id)
              );
            }
          }}
        />
        <td
          id={`name`}
          className={`ml-3 min-w-[265px] cursor-pointer text-left baseBold text-[13px] leading-6 flex items-center`}
        >
          {row.full_name}
        </td>
        <td className={`tableData `} id={`count`}>
          {" "}
          {row.courses_count + " курсов"}
        </td>
        <td className={`tableData mr-4`} id={`finish`}>
          {row.completed_courses_count + " курсов"}
        </td>
        <td className={`tableData`} id={`status`}>
          {row.seted_course_count === 0
            ? "Нет назначенных"
            : row.seted_course_count + " назначенных"}
        </td>
      </tr>
    );
  });

  return (
    <tbody className={`mt-[50px] ml-[-10px] w-full relative`}>
      {renderedRows}
    </tbody>
  );
};

export default TableBody;
