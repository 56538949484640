import React from "react";

type IProps = {
  name: string;
  img: string;
  cost: number;
};
const PrizeCard = (p: IProps) => (
  <div
    className={`w-[420px] h-[484px] bg-white rounded-[32px] sm:w-[320px] sm:h-[384px] sm:ml-6`}
  >
    <div className={`flex flex-col justify-start items-center`}>
      <div className={`mt-10`}>
        <img src={p.img} alt="" />
      </div>
    </div>
    <div className={`flex flex-col items-start mx-[10%] mt-5`}>
      <div className={`base text-[28px] font-medium`}>
        <p>{p.name}</p>
      </div>
      <div className={`base text-MainAccent font-semibold mt-16 text-[24px]`}>
        {p.cost} монет
      </div>
    </div>
  </div>
);

export default PrizeCard;
