import React from "react";

type IProps = {
  description: string;
  value: number;
};

const TimerSection = (p: IProps) => {
  return (
    <div
      className={`base border border-SecondaryAccent-one h-[80px] w-16 rounded-[3px] first:ml-0 ml-3`}
    >
      <p
        className={`base bg-white text-center mt-5 text-[36px] text-SecondaryAccent-dark`}
      >
        {p.value}
      </p>
      <p className={`text-SecondaryAccent-dark text-center mt-2 text-[12px]`}>
        {p.description}
      </p>
    </div>
  );
};

export default TimerSection;
