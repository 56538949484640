import React, {useEffect, useState} from "react";
import Select, { Option } from "../../components/Select";
import Button from "../../components/Button";
import { sendFeedback } from "../../network/UserApi/userApi";

const options: Array<Option> = [
  {
    value: "text",
    text: "Экспертные сессии",
  },
  {
    value: "text",
    text: "Получение наград",
  },
  {
    value: "text",
    text: "Вопрос по работе платформы",
  },
  {
    value: "text",
    text: "Другое",
  },
];

export const Feedback = () => {
  const [category, setCategory] = useState<any>({
    value: "text",
    text: "Экспертные сессии",
  });
  const [feedback, setFeedback] = useState<string>("");
  const clearCategory = () => {
    setCategory("");
  };

  const clearStates = () => {
    setFeedback("");
    setCategory("");
  };

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className={`w-[795px]`}>
      <div className={`mt-5`}>
        <p className={`base text-[24px]`}>Обратная связь</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Задайте вопрос или внесите предложение. Ответ поступит на электронную почту, указанную при регистрации.
        </p>
      </div>
      <div className={`mt-5 flex flex-row justify-between mr-80`}>
        <Select
          placeholder={"Выберите категорию"}
          options={options}
          onSelect={setCategory}
          selected={category}
          dropdownClassName={``}
          labelClassName={`w-[350px] !border-SecondaryAccent-one `}
        />
        {category && (
          <div className={`ml-8`}>
            <Button
              text={"СБРОСИТЬ"}
              onClick={clearCategory}
              className={`!rounded-none border border-SecondaryAccent-one bg-white text-SecondaryAccent baseMicro h-[26px] flex items-center hover:bg-SecondaryAccent hover:text-white`}
            />
          </div>
        )}
      </div>
      <div className={`flex flex-col items-start`}>
        <textarea
          className={`w-[350px] border h-[184px] border-SecondaryAccent-one focus:outline-0 px-4 py-[6px] base text-[14px]`}
          placeholder={"Обращение"}
          name="feedback"
          id="feedback"
          value={feedback}
          onChange={(event) => {
            setFeedback(event.currentTarget.value);
          }}
        ></textarea>
        <Button
          text={"Отправить"}
          className={`mt-8 rounded-none border border-MainAccent text-MainAccent bg-white hover:text-white h-[32px] flex items-center`}
          disabled={!feedback}
          onClick={() => {
            sendFeedback({ body: feedback, title: category.text }).then(() => {
              clearStates();
              alert("Сообщение отправлено");
            });
          }}
        />
      </div>
    </div>
  );
};
