import { useEffect, useState } from "react";
import ModalWrapper from "../ModalWrapper";
import arrow from "../../assets/img/welcome/arrow.svg";
import MainInput from "../welcome/MainInput";
import Button from "../Button";
import { passwordRecovery } from "../../network/UserApi/userApi";
import { isValidEmail } from "../../utils/data";
interface IProps {
  visible?: boolean;
  onClose?: () => void;
}

type RecoverStatusType = "start" | "wait";

const RecoverModal = (props: IProps) => {
  const [status, setStatus] = useState<RecoverStatusType>("start");

  const [mail, setMail] = useState<string>();
  const [recoverError, setRecoverError] = useState<boolean>(false);

  const handleRecover = () => {
    const data = {
      email: mail || "",
      isPromo:true
    };
    passwordRecovery(data).then((e) => {
      // @ts-ignore
      if (e.status == "ok") {
        setStatus("wait");
      } else {
        setRecoverError(true);
      }
    });
  };

  useEffect(() => {
    setStatus("start");
  }, [props.visible]);
  return (
    <ModalWrapper
      mainClass={`rounded-[32px] min-w-[500px] sm:min-w-[350px]`}
      withoutBanner={true}
      header={``}
      text={``}
      onClose={props.onClose}
      visible={props.visible}
      crossClass={`p-[3%] `}
    >
      {status === "start" && (
        <div>
          <div
            className={`flex-row flex items-center cursor-pointer`}
            onClick={() => {
              props.onClose && props.onClose();
            }}
          >
            <img src={arrow} alt="" />
            <p className={`ml-4 text-SecondaryAccent base`}>Назад ко входу</p>
          </div>
          <div className={`flex flex-col items-start pr-4 mb-8 mt-4`}>
            <div className={`base text-[32px] `}>
              <p>Восстановление пароля</p>
            </div>
            <div className={`base mt-4`}>
              <p>
                Пожалуйста, введите адрес электронной почты, которая привязана к
                вашему аккаунту.
              </p>
            </div>
            <div className={`w-full mt-8`}>
              <MainInput
                onChange={(e) => {
                  setMail(e);
                }}
                value={mail}
                id={"mail"}
                name={"E-mail"}
              />
              <p
                className={`baseMicro mt-1 text-CloseDeadlineAccent ${
                  recoverError ? "" : "hidden"
                }`}
              >
                Пользователь с таким email не найден
              </p>
            </div>
            <div className={`w-full mt-10`}>
              <Button
                text={"Продолжить"}
                className={`h-[62px] rounded-[32px] font-semibold text-[18px] base w-full`}
                disabled={!isValidEmail(mail)}
                onClick={handleRecover}
              />
            </div>
          </div>
        </div>
      )}
      {status === "wait" && (
        <div>
          <div
            className={`flex-row flex items-center cursor-pointer`}
            onClick={() => {
              props.onClose && props.onClose();
            }}
          >
            <img src={arrow} alt="" />
            <p className={`ml-4 text-SecondaryAccent base`}>Назад ко входу</p>
          </div>
          <div className={`flex flex-col items-start pr-4 mb-8 mt-4`}>
            <div className={`base text-[32px] `}>
              <p>Восстановление пароля</p>
            </div>
            <div className={`base mt-12`}>
              <p>
                На вашу электронную почту была направлена ссылка для сброса
                пароля
              </p>
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default RecoverModal;
