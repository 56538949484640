import { request } from "../mainRequest";
import {
  PromoRegisterRsp,
  PromoResponse,
  priseOrderReq,
  promoAuthReq,
  PromoUser,
  promoProjectReq,
  shopOrderReq,
  ConfirmUserreq,
  PromoInfoResp,
  PromoType,
  PromoLidersResp,
  PromoStatusResp,
} from "./promoInterfaces";
import { OkPromoRes, OrderPriseRes } from "../universalInterfaces";
import PromoEndpoints from "../../structure/promoEndpoints";
import { IRegisterData } from "../../components/Create/LoginModal";

export function createProject(data: promoProjectReq): Promise<OkPromoRes> {
  return request(PromoEndpoints.CreateProject, "post", data);
}

export function orderPrise(data: priseOrderReq): Promise<OrderPriseRes> {
  return request(PromoEndpoints.OredrPrise, "post", data);
}

export function orderPromoShop(data: shopOrderReq): Promise<OrderPriseRes> {
  return request(PromoEndpoints.OredrShop, "post", data);
}

export function promoAuth(
  data: promoAuthReq
): Promise<PromoResponse<PromoUser>> {
  return request(PromoEndpoints.Auth, "post", data);
}

export function promoRegister(
  data: IRegisterData
): Promise<PromoResponse<PromoRegisterRsp>> {
  return request(PromoEndpoints.Rregister, "post", data);
}

export function promoUserInfo(): Promise<PromoResponse<PromoInfoResp>> {
  return request(PromoEndpoints.UserInfo, "post", {});
}

export function getPromoUsers(
  active: boolean
): Promise<PromoResponse<PromoUser[]>> {
  return request(PromoEndpoints.Users, "post", { active });
}

export function editPromoUser(data: PromoUser): Promise<PromoResponse<null>> {
  return request(PromoEndpoints.UserEdit, "post", data);
}

export function confirmUser(
  data: ConfirmUserreq
): Promise<PromoResponse<null>> {
  return request(PromoEndpoints.UserConfirm, "post", data);
}

export function logout(): Promise<PromoResponse<null>> {
  return request(PromoEndpoints.PromoLogout, "post", {});
}

export function getLiders(
  type: PromoType,
  fromAdmin?:boolean
): Promise<PromoResponse<PromoLidersResp>> {
  return request(PromoEndpoints.PromoLiders, "post", { type, fromAdmin });
}

export function getStatus(): Promise<PromoResponse<PromoStatusResp>> {
  return request(PromoEndpoints.PromoStatus, "post", {});
}

export function setStatus(
  status: boolean
): Promise<PromoResponse<PromoStatusResp>> {
  return request(PromoEndpoints.PromoSetStatus, "post", { status });
}
