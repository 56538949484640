import React from "react";
import { ArchiveCardReq } from "../interfaces/ArchiveCard";
import ArchiveCard from "./ArchiveCard";

type IProps = {
    archiveCards: Array<ArchiveCardReq>
}


const ArchiveBlock = (p: IProps) => {

    const renderedCards = p.archiveCards.map(card => {
        return (
            <ArchiveCard imgSrc={card.imgSrc} name={card.name} count={card.count} courseId={card.courseId} />
        )
    })

    return (
        <div className="flex flex-wrap mt-[15px] ml-[-9px]">
            {renderedCards}
        </div>
    )
}

export default ArchiveBlock;