import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetInfoCourseRes } from "../../network/CourseApi/courseInterfaces";
import { getinfoCourse } from "../../network/CourseApi/courseApi";
import angle from "../../assets/img/courseAngle.svg";

const Info = () => {
  const [course, setCourse] = useState<GetInfoCourseRes>();
  const navigate = useNavigate();
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("course");
  useEffect(() => {
    getinfoCourse({ id: current ? current : "" }).then((r) => {
      setCourse(r);
    });
      window.scrollTo(0,0)
  }, []);
  return (
    <div>
      <div
        className={`flex w-[72px] mt-[14px] pb-4 justify-between cursor-pointer base items-center text-MainAccent`}
        onClick={() => {
          navigate(-1);
        }}
      >
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2L2 7L7 12"
            stroke="#3DCD58"
            stroke-width="2"
            stroke-linecap="square"
          ></path>
          <line
            x1="2"
            y1="7"
            x2="16"
            y2="7"
            stroke="#3DCD58"
            stroke-width="2"
          ></line>
        </svg>
        <p>Назад</p>
      </div>
      <div className={`flex`}>
        <div className={`relative`}>
          <img
            className={`min-w-[248px] h-[152px]`}
            src={course?.image}
            alt="img"
          />
          <img className={`absolute top-0 right-0`} src={angle} alt="angle" />
        </div>
        <div className={`ml-5`}>
          <p className={`base text-[20px] text-SecondaryAccent-dark`}>
            {course?.name}
          </p>
          <div
            className={`mt-4`}
            dangerouslySetInnerHTML={{ __html: course?.description || "" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Info;
