import React from "react";
import ModalCross from "./ModalCross";

type IProps = {
  header: string;
  text: string;
  visible?: boolean;
  onClose?: () => void;
  children?: any;
  withoutBanner?: boolean;
  mainClass?: string;
  crossClass?: string;
  isError?: boolean;
};

const ModalWrapper = (p: IProps) => {
  return (
    <div
      // onClick={p.onClose}
      className={` h-full w-full flex items-start fixed justify-center z-[9999] right-0 left-0 bottom-0 top-0 pt-60 overflow-scroll ${
        p.visible ? "backdrop-blur-sm" : "hidden"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${
          p.mainClass ? p.mainClass : ""
        } min-h-[20%] min-w-[20%] max-w-[40px] block bg-white shadow z-10 px-8 pt-2 mb-60`}
      >
        <div
          className={`${
            p.crossClass ? p.crossClass : " "
          } mt-2 flex justify-end`}
        >
          <div onClick={p.onClose}>
            <ModalCross />
          </div>
        </div>
        <div className={`flex flex-row`}>
          <div
            className={`${p.isError ? "bg-red-600" : "bg-MainAccent"} h-[30px] w-[6px] mr-4 ${
              p.withoutBanner ? "hidden" : ""
            }`}
          ></div>
          <article
            className={`base text-[18px] leading-[30px] mb-[33px] text-SecondaryAccent ${
              !p.header ? "hidden" : ""
            } `}
          >
            {p.header}
          </article>
        </div>
        <div>{p.children}</div>
        <div className={`flex flex-col gap-5 pb-4 flex-wrap`}>
          <p className={`base `}>{p.text}</p>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
