import UserEndpoints from "../../structure/userEndpoints";
import { request } from "../mainRequest";
import { MessageRes, StatusRes } from "../universalInterfaces";
import {
  CheckKeyReq,
  GetDataRes,
  GetHeaderRes,
  GetLoginsRes,
  GetSubscribesRes,
  PasswordRecovery,
  PasswordReset,
  SendFeedbackReq,
  SetDataReq,
  UserCertificate,
  UserRegister,
} from "./userInterfaces";
import { sendAvatar } from "../sendAvatar";

export function login(mail: string, password: string) {
  return request(UserEndpoints.Login, "post", { email: mail, password: password });
}


export function getHeader(): Promise<GetHeaderRes> {
  return request(UserEndpoints.GetHeader);
}

export function getLogins(): Promise<GetLoginsRes> {
  return request(UserEndpoints.GetLogins);
}

export function userRegister(data: UserRegister) {
  return request(UserEndpoints.Register,"post", data);
}

export function passwordRecovery(data: PasswordRecovery) {
  return request(UserEndpoints.Recovery,"post", data);
}

export function passwordReset(data: PasswordReset) {
  return request(UserEndpoints.Reset,"post", data);
}

export function setData(data: SetDataReq): Promise<GetDataRes> {
  return request(UserEndpoints.SetData,"post", data);
}

export function setAvatar(data: any) {
  debugger
  return sendAvatar(data);
}

export function getLogout(): Promise<StatusRes> {
  return request(UserEndpoints.GetLogout);
}

export function getData(): Promise<GetDataRes> {
  return request(UserEndpoints.GetData);
}

export function checkKey(data: CheckKeyReq): Promise<MessageRes> {
  return request(UserEndpoints.CheckKey,"post", data);
}

export function getCertificates(): Promise<Array<UserCertificate>> {
  return request(UserEndpoints.GetCertificates);
}

export function getSubscribes(): Promise<GetSubscribesRes> {
  return request(UserEndpoints.GetSubscribes);
}

export function sendFeedback(data: SendFeedbackReq): Promise<MessageRes> {
  return request(UserEndpoints.SendFeedback,"post", data);
}
