import React from "react";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

type IProps = {
  level: number;
  nextLevel: number;
  currentExp: number;
  coins: number;
};

const UserLevel = (p: IProps) => {
  const progress = (p.currentExp * 100) / p.nextLevel;
  return (
    <div className="flex justify-between ">
      <div className="flex">
        <Link className="" to={AppRoutes.Level}>
          <p className="flex baseUnderline text-SecondaryAccent">
            Уровень: {p.level}
          </p>
        </Link>
        <div className="w-[224px] bg-SecondaryAccent-one h-2 ml-[10px] mt-[9px] rounded-md relative">
          <div
            className={`bg-MainAccent h-2 rounded-md`}
            style={{ width: `${progress}%` }}
          ></div>
          <p className="absolute right-[0] top-[0] text-[0.5em] mr-[1px] leading-[8px] text-white">
            {p.currentExp} / {p.nextLevel}
          </p>
        </div>
      </div>
      <div className="ml-[65px] flex base text-SecondaryAccent">
        <p>
          Монеты: <strong>{p.coins}</strong>
        </p>
      </div>
    </div>
  );
};

export default UserLevel;
