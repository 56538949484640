import React, { useEffect, useState } from "react";
import CalendarBlock from "../../components/CalendarBlock";
import {
  GetHeaderRes,
  GetLoginsRes,
} from "../../network/UserApi/userInterfaces";
import { getContiuningEducation } from "../../network/CourseApi/courseApi";
import { getHeader, getLogins } from "../../network/UserApi/userApi";
import {
  GetContiuningEducationReq,
  GetContiuningEducationRes,
} from "../../network/CourseApi/courseInterfaces";
import { ProgressCircleReq } from "../../interfaces/ProgressCircle";
import Colors from "../../structure/colors";
import ProgressBlock from "../../components/ProgressBlock";

import ContinueCourse from "../../components/ContinueCourse";
import {
  GetCoffeeReq,
  GetCoffeeRes,
} from "../../network/CoffeeApi/coffeeInterfaces";
import { getCoffee } from "../../network/CoffeeApi/coffeeApi";
import CoffeeBlock from "../../components/CoffeeBlock";
import Select from "../../components/Select";
import AppRoutes from "../../structure/routes";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { getSessionsForCalendar } from "../../network/SessionApi/sessionApi";
import { CalendarSessions } from "../../network/SessionApi/sessionInterfaces";

const CONTINUE_FILTER: GetContiuningEducationReq = {};
const COFFEE_FILTER: GetCoffeeReq = { limit: 0 };

export const UserCabinet = () => {
  const [sessions, setSessions] = useState<CalendarSessions>();
  const [directionSelected, setDirectionSelected] = useState<any>();
  const [statusSelected, setStatusSelected] = useState<any>();
  const [deadlineSelected, setDeadlineSelected] = useState<any>();
  const [courses, setCourses] = useState<Array<GetContiuningEducationRes>>([]);
  const [header, setHeader] = useState<GetHeaderRes>();
  const [logins, setLogins] = useState<GetLoginsRes>();
  const [coffee, setCoffee] = useState<Array<GetCoffeeRes>>([]);
  const [coursesloaded, setCoursesLoaded] = useState<boolean>(false)

  const clearStates = () => {
    setDirectionSelected("");
    setStatusSelected("");
    setDeadlineSelected("");
  };

  useEffect(() => {
    getSessionsForCalendar().then((r) => {
      setSessions(r);
    });
      window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    getHeader().then((r) => {
      setHeader(r);
    });
  }, []);
  useEffect(() => {
    getContiuningEducation(CONTINUE_FILTER).then((r) => {
      setCourses(r);
      setCoursesLoaded(true)
    });
  }, []);
  useEffect(() => {
    getLogins().then((r) => {
      setLogins(r);
    });
  }, []);

  useEffect(() => {
    getCoffee(COFFEE_FILTER).then((r) => {
      setCoffee(r);
    });
  }, []);
  let done = 0;

  courses?.map((el) => {
    if (el.user_percent === 100) {
      done++;
    }
  });

  const circles: Array<ProgressCircleReq> = [
    {
      color: Colors.Main,
      done: done,
      all: courses?.length || 0,
      insideCircle: "завершены",
      circleDescription: "курсы",
    },
    {
      color: Colors.Main,
      done: header?.user_level_experience || 0,
      all: header?.user_next_level_points || 0,
      insideCircle: "баллов",
      circleDescription: "до следующего уровня",
    },
  ];

  const firstSelector = [
    { value: "mv", text: "среднее напряжение" },
    { value: "lv", text: "низкое напряжение" },
    {
      value: "bim",
      text: "информационное моделирование",
    },
    { text: "АСУТП", value: "asutp" },
    { text: "автоматизация зданий", value: "bms" },
    { text: "расчеты и проектирование", value: "soft" },
  ];
  const secondSelector = [
    { text: "не начат", value: "none" },
    { text: "в процессе", value: "progress" },
    { text: "завершенные", value: "end" },
  ];

  const thirdSelector = [
    { text: "нет", value: "none" },
    { text: "просрочено", value: "dead" },
    { text: "сегодня", value: "day" },
    { text: "на этой неделе", value: "week" },
    { text: "в этом месяце", value: "month" },
    { text: "в этом году", value: "year" },
  ];

  const months = {
    data: logins?.data || {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    max: logins?.max || 0,
  };
  return (
    <div className={`w-[795px]`}>
      <div>
        <p className={`base text-[36px]`}>Прогресс обучения</p>
        <ProgressBlock circles={circles} months={months} />
      </div>
      <div className={`mt-20`}>
        <div className={`flex flex-row mb-10`}>
          <div className={``}>
            <Select
              options={firstSelector}
              placeholder={"направление"}
              selected={directionSelected}
              onSelect={setDirectionSelected}
            />
          </div>
          <div className={`relative ml-[175px]`}>
            <Select
              labelClassName={`!w-[120px]`}
              options={secondSelector}
              placeholder={"статус"}
              selected={statusSelected}
              onSelect={setStatusSelected}
              dropdownClassName={`min-w-[120px]`}
            />
          </div>
          <div className={`relative ml-[140px]`}>
            <Select
              labelClassName={``}
              options={thirdSelector}
              placeholder={"крайний срок"}
              selected={deadlineSelected}
              onSelect={setDeadlineSelected}
            />
          </div>
          {deadlineSelected || statusSelected || directionSelected ? (
            <div className={`relative ml-[200px]`}>
              <Button
                className={`!rounded-none border border-SecondaryAccent-table bg-white text-SecondaryAccent baseMicro h-[26px] flex items-center hover:bg-SecondaryAccent hover:text-white`}
                text={"сбросить"}
                onClick={clearStates}
              />
            </div>
          ) : null}
        </div>
        <p className={`base text-[36px]`}>Профессиональные курсы</p>
        <ContinueCourse cardsLoad = {coursesloaded}
          filter={{
            direction: directionSelected || "-",
            status: statusSelected,
            deadline: deadlineSelected,
          }}
          continueCards={courses}
        />
        <Link to={AppRoutes.Courses}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Выбрать программу`}
          />
        </Link>
      </div>
      <div className={`mt-20`}>
        <p className={`base text-[36px]`}>Экспертные сессии</p>
        {sessions && <CalendarBlock sessions={sessions.sessions} />}

        <Link to={`${AppRoutes.Courses}#expert`}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Экспертные сессии`}
          />
        </Link>
      </div>
      <div className={`mt-20 mb-24`}>
        <p className={`base text-[36px]`}>Кофе с экспертом</p>
        <CoffeeBlock cards={coffee} />
        <Link to={`${AppRoutes.Courses}#coffee`}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Выбрать`}
          />
        </Link>
      </div>
    </div>
  );
};
