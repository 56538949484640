import { useCallback, useMemo, useState } from "react";

interface IProps {
  titles: string[];
  onSelect: (index: number) => void;
}

const Tabbar = (props: IProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const carretWidth = useMemo(() => 100 / props.titles.length, [props.titles]);

  const selectIndex = useCallback(
    (index: number) => {
      setCurrentIndex(index);
      props.onSelect(index);
    },
    [props.onSelect]
  );

  return (
    <div className="mb-[30px] flex flex-col w-full gap-4">
      <div className="flex font-semibold select-none">
        {props.titles.map((t, i) => (
          <p
            onClick={() => selectIndex(i)}
            className={`flex-1 w-[50%] text-gray-4 ${
              currentIndex === i && "!text-accent"
            } text-sm text-center cursor-pointer`}
            key={i}
          >
            {t}
          </p>
        ))}
      </div>
      <div className="relative rounded-sm h-[2px] bg-gray-400">
        <span
          className={`absolute bottom-0 h-[2px] rounded-sm bg-MainAccent duration-200`}
          style={{ width: `${carretWidth}%`, left: `${carretWidth * currentIndex}%` }}
        ></span>
      </div>
    </div>
  );
};

export default Tabbar;
