import React, { useState } from "react";

type IProps = {
  onChange: (users: any) => void;
  user: number;
  selectedUsers: any;
};

const TableSelector = (p: IProps) => {
  return (
    <td
      onClick={p.onChange}
      className={`tableSelector tableData mr-0 min-w-0 ${
        p.selectedUsers.includes(p.user) ? "bg-MainAccent" : ""
      }`}
    ></td>
  );
};

export default TableSelector;
