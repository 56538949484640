enum CommandEndpoints {
  GetCommandApi = "/get/user/command",
  DeleteSubordinates = "/set/user/delete_subordinates",
  SubscribeSubordinates = "/set/user/subscribe_subordinates",
  GetTeamCourses = "/get/course/team_courses",
  GetSubscribes = "/get/subscribes",
  Subscribe = "/set/command/subscribe",
  BuySubscription = "/subscribe/buy",
}

export default CommandEndpoints;
