import React, { useEffect, useState } from "react";
import arrow from "../assets//img/arrow.svg";
import activeArrow from "../assets/img/arrowSelected.svg";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "../interfaces/NavLink";
import { getLogout } from "../network/UserApi/userApi";

type IProps = {
  navLinks: Array<NavLink>;
};

const MainNav = (p: IProps) => {
  const [scrollTop, setScrollTop] = useState(0);
  const usePathName = () => {
    const location = useLocation();
    return location.pathname;
  };

  const CheckPath = (arg: string) => {
    return usePathName() === arg;
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderedLinks = p.navLinks.map((link: NavLink) => {
    return (
      <Link to={link.appRoute}>
        <div
          className={`${CheckPath(link.appRoute) ? "navActive" : "navMain"}`}
        >
          <img
            src={CheckPath(link.appRoute) ? activeArrow : arrow}
            alt="arrow"
          />
          <p className={`block ml-4 ${link.textStyle}`}>{link.linkName}</p>
        </div>
      </Link>
    );
  });

  return (
    <nav
      className={`${
        scrollTop < 120 ? "absolute" : "fixed"
      }  top-0 w-[190px] pt-4 pb-4 `}
    >
      {renderedLinks}
      <div
        className="navLogout cursor-pointer"
        onClick={() => {
          getLogout().then(() => {
            window.location.href = "/welcome";
          });
        }}
      >
        <img src={arrow} alt="arrow" />
        <p className="block ml-4">Выход</p>
      </div>
    </nav>
  );
};

export default MainNav;
