import React from "react";
import { SubscriptionTableElementReq } from "../interfaces/SubscriptionTableElement";

type IProps = SubscriptionTableElementReq;
const SubscriptionTableElement = (p: IProps) => {
  return (
    <tr>
      <td className={`baseBold text-left pl-4 border-b py-4 max-w-[160px]`}>
        {p.package}
      </td>
      <td className={`base text-center border-b py-4 max-w-[160px]`}>
        {p.count}
      </td>
      <td className={`base text-center border-b py-4 max-w-[160px]`}>
        {p.duration}
      </td>
      <td className={`base text-center border-b py-4 max-w-[160px]`}>
        {p.cost}
      </td>
      <td className={`base text-center border-b py-4 max-w-[160px]`}>
        <button
          className={`w-max border border-MainAccent text-center text-MainAccent py-[6px] px-4 baseMicro cursor-pointer rounded-mainButton`}
          id={p.id.toString()}
          onClick={p.onClick}
        >
          Оформить подписку
        </button>
      </td>
    </tr>
  );
};

export default SubscriptionTableElement;
