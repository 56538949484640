import React, { useState } from "react";
import logo from "../assets/img/arrdownSelect.png";
import logoWhite from "../assets/img/downArrWhite.png";

export type Option = {
  text: string;
  value: string;
};

type IProps = {
  options: Array<Option>;
  selected?: Option;
  onSelect: (v: Option) => void;
  placeholder?: string;
  labelClassName?: string;
  dropdownClassName?: string;
  children?: any;
};

const CustomDropdownMenu = (p: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: Option) => {
    p.onSelect(option);
    setIsOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const renderedOptions = p.options.map((option: Option) => (
    <li
      className="selectorStyleListList"
      key={option.value}
      onClick={() => handleSelect(option)}
    >
      {option.text}
    </li>
  ));

  return (
    <div className="flex pb-10">
      <div
        className={`items-center ${p.labelClassName} ${
          p.selected ? "" : "border-[1px] border-SecondaryAccent-dark"
        } ${
          isOpen ? "border-[1px] border-SecondaryAccent-dark z-10" : "w-[150px]"
        } whitespace-nowrap absolute bg-white `}
        onClick={toggleDropdown}
        onMouseLeave={isOpen ? toggleDropdown : () => {}}
      >
        <div>{isOpen}</div>
        <div
          className={`${
            p.selected
              ? isOpen
                ? "selectorStyleOpen"
                : "selectorStyleSelected"
              : "selectorStyleClosed"
          } selectorStyleListWrapper `}
        >
          <p className="text-ellipsis overflow-hidden">
            {p.selected
              ? isOpen
                ? p.placeholder
                : p.selected.text
              : p.placeholder}{" "}
          </p>
          <div
            className={` ${
              p.selected?.text ? (isOpen ? "ml-auto" : "mr-[10px]") : "ml-auto"
            }`}
          >
            <img
              className={`${isOpen ? "rotate-180" : ""} duration-300 ml-3`}
              src={p.selected ? (isOpen ? logo : logoWhite) : logo}
              alt="arrow"
            ></img>
          </div>
        </div>
        {isOpen && (
          <div
            className={`px-2.5 top-0 left-0 z-10 min-w-[150px] ${p.dropdownClassName}`}
          >
            <ul
              className="selectorStyleList mb-1 z-10 bg-white "
              onKeyDown={handleKeyDown}
              onMouseLeave={toggleDropdown}
            >
              {renderedOptions}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdownMenu;
