import React from "react";
import { InputFormReq } from "../interfaces/InputForm";
import InputForm from "./InputForm";
import Button from "./Button";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "../structure/routes";
import { login } from "../network/UserApi/userApi";

type IProps = {
  cards: Array<InputFormReq>;
  className?: string;
  onAuth: (r: boolean) => void;
};

const LoginBlock = (p: IProps) => {
  const navigate = useNavigate();

  let args: Array<string> = [];
  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    for (let i = 0; i <= 1; i++) {
      args.push(e.target[i].value);
    }
    login(args[0], args[1]).then(() => {
      p.onAuth(true);
      navigate(AppRoutes.Main, { replace: true });
    });
  };
  const renderedCards = p.cards.map((card) => {
    return <InputForm placeholder={card.placeholder} name={card.name} />;
  });

  return (
    <form
      method={"POST"}
      className={`w-full mt-0 ${p.className}`}
      onSubmit={(event) => handleLoginSubmit(event)}
    >
      {renderedCards}
      <Button
        className="w-full h-14 font-bold"
        text="Войти"
        isAttention={false}
      />
      <Link className="" to={AppRoutes.Recovery}>
        <p className="baseUnderline mt-[20px] text-center cursor-pointer text-base">
          Забыли пароль? <b>Восстановить</b>
        </p>
      </Link>
    </form>
  );
};

export default LoginBlock;
