import React from "react";
import lllLogo from "../../assets/img/llllogo.svg";

const MainScreen = () => (
  <div id={`main_screen`} className={"relative w-full h-[110vh] sm:h-[100vh]"}>
    <video
      className={`absolute rounded-tl-[48px] rounded-tr-[48px] z-[-1] sm:object-cover object-cover w-full h-full`}
      src="/mainscreen.mp4"
      autoPlay={true}
      muted={true}
      loop={true}
    ></video>
    <div
      id={"overlay"}
      className={`absolute top-0 left-0 h-full w-full z-[-1] rounded-tl-[48px] rounded-tr-[48px] opacity-30 bg-gradient-to-br from-[#787F86] from-10% via-[#18434B] to-[#787F86]`}
    ></div>
    <div
      id={`text_on_screen`}
      className={`pt-[35vh] pl-[5%] text-white text-[80px] w-full xl:text-[60px] sm:text-[40px] xl:pt-[60vh] xl:pl-[2vh] sm:pt-[50%] base leading-[112px] lg:leading-normal items-baseline`}
    >
      <p>
        Платформа <img src={lllLogo} alt="" className={`inline`} /> для
        <span className={`text-MainAccent`}> развития</span>
      </p>
      <p className={`text-MainAccent`}>
        компетенций <span className={`text-white`}>инженеров-</span>
      </p>
      <p>проектировщиков</p>
    </div>
  </div>
);
export default MainScreen;
