import React from "react";

const ModalCross = () => {
  return (
    <svg
      className={`cursor-pointer w-[18px] h-[18px]`}
      width={13}
      height={13}
      viewBox={`0 0 13 13`}
      fill={`none`}
      xmlns={`http://www.w3.org/2000/svg`}
    >
      <line
        x1="0.353553"
        y1="0.353539"
        x2="12.3744"
        y2="12.3744"
        stroke="#9FA0A4"
      ></line>
      <line
        x1="12.3747"
        y1="0.353869"
        x2="0.353237"
        y2="12.3539"
        stroke="#9FA0A4"
      ></line>
    </svg>
  );
};

export default ModalCross;
