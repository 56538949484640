import React, { useEffect } from "react";
import graph from "../../assets/img/graph.png";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";

export const Level = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`w-[795px]`}>
      <div className={`mt-5`}>
        <p className={`base text-[36px]`}>Награды и уровни</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          При прохождении курсов каждый пользователь автоматически переходит на
          более высокий уровень, а также накапливает внутреннюю валюту платформы
          — монеты. Монеты можно обменять в можетеагазине платформы на сувениры,
          которые будут доставлены вам курьерской службой.
        </p>
      </div>
      <div className={`mt-14`}>
        <p className={`base text-[36px]`}>Уровни</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Чем больше курсов прошел участник, тем выше его уровень в системе, а
          значит, он может получить полезные подарки и доступ к специальным
          курсам специалистов высокого уровня. В описании каждого курса вы
          можете увидеть, сколько баллов вы получите по окончании.
        </p>
        <img className={`mt-8`} src={graph} alt="graph" />
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Переход на следующий уровень происходит по мере накопления баллов,
          система считает их автоматически. Вы можете посмотреть прогресс
          прохождения уровня в Учебном кабинете.
        </p>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-[36px]`}>Монеты и призы</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Вместе с баллами каждый участник получает монеты – это внутренняя
          валюта платформы. Монеты можно обменять в Магазине платформы на
          сувениры – вы можете тратить монеты сразу или накопить на желаемый
          подарок. По мере перехода на более высокий уровень вам станут доступны
          специальные награды.
        </p>
      </div>
    </div>
  );
};
