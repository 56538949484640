import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GetInfoCourseRes } from "../../network/CourseApi/courseInterfaces";
import sessionAngle from "../../assets/img/sessionAngle.svg";
import calendar from "../../assets/img/calendar.svg";
import clock from "../../assets/img/clock_min.svg";
import { getinfoCourse } from "../../network/CourseApi/courseApi";
import TimerBlock from "../../components/TimerBlock";
import Button from "../../components/Button";
import { createPath } from "../../utils/linkHandler";
import AppRoutes from "../../structure/routes";

const Vebinar = () => {
  const [vebinar, setVebinar] = useState<GetInfoCourseRes>();
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("course");
  const date = new URLSearchParams(location.search).get("date");
  const begin = new URLSearchParams(location.search).get("begin");
  const end = new URLSearchParams(location.search).get("end");
  const time = new Date(`${date}T${begin}`);
  useEffect(() => {
    getinfoCourse({ id: current ? current.toString() : "" }).then((r) => {
      setVebinar(r);
    });
      window.scrollTo(0,0)
  }, []);
  return (
    <div>
      <div className={`flex justify-between mt-5`}>
        <div className={`relative`}>
          <img
            className={`w-[313px] h-[184px] object-cover`}
            src={vebinar?.image}
            alt="img"
          />
          <img
            className={`absolute right-0 top-0`}
            src={sessionAngle}
            alt="angle"
          />
        </div>
        <div className={`relative h-[189px] w-[55%]`}>
          <p className={`base text-[1.375em]`}>{vebinar?.name}</p>
          <div className={`absolute !left-0 !bottom-0`}>
            <div className={`flex flex-row`}>
              <div className={`flex items-center`}>
                <img className={`w-4 h-4`} src={calendar} alt="calendar" />
                <p className={`base ml-2`}>{date}</p>
              </div>
              <div className={`flex items-center ml-7`}>
                <img className={`w-4 h-4`} src={clock} alt="clock" />
                <p className={`base ml-2`}>
                  {begin}-{end}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`mt-5`}>
        <p className={`baseBold text-SecondaryAccent-dark`}>
          Описание вебинара:
        </p>
        <p>{vebinar?.description}</p>
      </div>
      <div className={`mt-5`}>
        <p className={`baseBold text-SecondaryAccent-dark`}>
          До вебинара осталось:
        </p>
        <div className={`mt-5`}>
          <TimerBlock sessionDate={time} />
        </div>
      </div>
      <div className={`mt-5`}>
        <p className={`baseBold text-SecondaryAccent-dark`}>
          {vebinar?.status === "subscribed" ? "Вы записаны" : "Вы не записаны"}
        </p>
        <p className={`mt-2 base text-SecondaryAccent-dark`}>
          Кнопка станет активной перед началом трансляции
        </p>
        <Link
          to={createPath({
            route: AppRoutes.Learn,
            args: [{ key: "course", value: vebinar?.id || 0 }],
          })}
        >
          <Button
            text={"Присоединиться"}
            disabled={!(new Date() > time)}
            className={`mt-4 mb-10`}
          />
        </Link>
      </div>
    </div>
  );
};

export default Vebinar;
