import React from "react";
import logo from "../../assets/img/logo.svg";
import Button from "../Button";
import menu from "../../assets/img/welcome/menu.svg";

type stages = "login" | "register";
type IProps = {
    setModal: (stage: stages) => void;
    onClick: () => void;
    scrollTo: (page: number) => void;
    scrollIds: any
};

const WelcomeHeader = (p: IProps) => {
    return (
        <div
            id={`header`}
            className={`min-h-[92px] flex flex-row items-center base justify-between pl-24 pr-24 md:px-12 sm:pl-4`}
        >
            <div id={`logo`} className={``}>
                <img src={logo} alt="logo"/>
            </div>
            <div id={`anchors`} className={`font-medium xl:hidden`}>
                <a className="hover:cursor-pointer"
                    onClick={() => {
                        const el = document.getElementById(p.scrollIds[0])
                        el?.scrollIntoView()
                    }}
                >
                    О программе
                </a>
                <a
                    className={`ml-8 hover:cursor-pointer`}
                    onClick={() => {
                        const el = document.getElementById(p.scrollIds[1])
                        el?.scrollIntoView()
                    }}
                >
                    Форматы
                </a>
                <a
                    className={`ml-8 hover:cursor-pointer`}
                    onClick={() => {
                        const el = document.getElementById(p.scrollIds[2])
                        el?.scrollIntoView()
                    }}
                >
                    Бонусы и награды
                </a>
                <a
                    className={`ml-8 hover:cursor-pointer`}
                    onClick={() => {
                        const el = document.getElementById(p.scrollIds[3])
                        el?.scrollIntoView()
                    }}
                >
                    Для компаний
                </a>
            </div>
            <div>
                <Button
                    text={"Вход"}
                    className={
                        "bg-SecondaryAccent-veryLight text-MainAccent mr-4 h-[60px] w-[124px] rounded-full hover:bg-SecondaryAccent-light font-medium sm:hidden"
                    }
                    onClick={() => {
                        p.setModal("login");
                    }}
                />
                <Button
                    text={"Регистрация"}
                    className={`h-[60px] rounded-full w-[194px] font-medium sm:hidden`}
                    onClick={() => {
                        p.setModal("register");
                    }}
                />
                <img
                    src={menu}
                    alt=""
                    className={`hidden lg:inline-flex lg:ml-4`}
                    onClick={p.onClick}
                />
            </div>
        </div>
    );
};

export default WelcomeHeader;
