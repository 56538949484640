import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { GetCoursesRes } from "../../network/CourseApi/courseInterfaces";
import { getCourses } from "../../network/CourseApi/courseApi";
import CourseBlock from "../../components/CourseBlock";
import Select from "../../components/Select";
import Button from "../../components/Button";

const registerSelector = [
  { text: "не начат", value: "none" },
  { text: "в процессе", value: "progress" },
  { text: "завершенные", value: "end" },
  { text: "не зарегистрирован", value: "register" },
];

export const Archive = () => {
  const [course, setCourse] = useState<GetCoursesRes>();
  const [registerSelected, setRegisterSelected] = useState<any>();

  useEffect(() => {
    getCourses({ archive_id: Number(current) }).then((r) => {
      setCourse(r);
    });
    window.scrollTo(0,0)
  }, []);

  const clearStates = () => {
    setRegisterSelected("");
  };

  const navigate = useNavigate();
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("id");
  return (
    <div>
      <div
        className={`flex w-[72px] mt-[14px] pb-4 justify-between cursor-pointer base items-center text-MainAccent`}
        onClick={() => {
          navigate(-1);
        }}
      >
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2L2 7L7 12"
            stroke="#00AC86"
            stroke-width="2"
            stroke-linecap="square"
          ></path>
          <line
            x1="2"
            y1="7"
            x2="16"
            y2="7"
            stroke="#00AC86"
            stroke-width="2"
          ></line>
        </svg>
        <p>Назад</p>
      </div>
      <div>
        <p className={`base text-[34px] mt-4 mb-8`}>{course?.catalog_name}</p>
        <div className={`flex flex-row mb-[-20px]`}>
          <Select
            labelClassName={``}
            options={registerSelector}
            selected={registerSelected}
            onSelect={setRegisterSelected}
            placeholder={"регистрация"}
          />
          {registerSelected && (
            <div className={`ml-44`}>
              <Button
                className={`!rounded-none border border-SecondaryAccent-table bg-white text-SecondaryAccent baseMicro h-[26px] flex items-center hover:bg-SecondaryAccent hover:text-white`}
                text={"сбросить"}
                onClick={clearStates}
              />
            </div>
          )}
        </div>
        {course && (
          <CourseBlock
            courseCards={course?.catalog_courses}
            filter={{ register: registerSelected }}
          />
        )}
      </div>
    </div>
  );
};
