import React, { useEffect, useState } from "react";
import firstFeature from "../../assets/img/firstFeature.png";
import secondFeature from "../../assets/img/secondFeature.png";
import thirdFeature from "../../assets/img/thirdFeature.png";
import SubscriptionTableElement from "../../components/SubscriptionTableElement";
import ModalWrapper from "../../components/ModalWrapper";
import Button from "../../components/Button";
import {
  buySubscription,
  getSubscribes,
} from "../../network/CommandApi/commandApi";
import { GetSubscribeRes } from "../../network/CommandApi/commandInterfaces";

export const Subscription = () => {
  const [complete, setComplete] = useState<boolean>(false);
  const [target, setTarget] = useState<string>("card");
  const [id, setId] = useState<number>();
  const [subscriptions, setSubscriptions] = useState<Array<GetSubscribeRes>>(
    []
  );
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    getSubscribes().then((r) => {
      setSubscriptions(r);
    });
      window.scrollTo(0,0)
  }, []);

  const renderedElements = subscriptions.map((el) => {
    return (
      <SubscriptionTableElement
        package={el.name}
        count={el.count}
        duration={el.duration.toString()}
        cost={el.price.toString()}
        id={el.id}
        onClick={() => {
          setId(el.id);
          setIsModal(true);
        }}
      />
    );
  });

  const child = (
    <div>
      <div
        className={`flex flex-col baseMicro gap-4 ${complete ? "hidden" : ""}`}
      >
        <div>
          <input
            className={`mr-2`}
            type="radio"
            name={`buy`}
            value={`card`}
            id="card"
            checked={true}
            onClick={(event) => {
              setTarget(event.currentTarget.value);
            }}
          />
          <label htmlFor="card">Оплата картой</label>
        </div>
        <div>
          <input
            className={`mr-2`}
            type="radio"
            name={`buy`}
            value={`req`}
            id="req"
            onClick={(event) => {
              setTarget(event.currentTarget.value);
            }}
          />
          <label htmlFor="req">Оплата по реквизитам</label>
        </div>
        <div className={`flex justify-end mt-3`}>
          <Button
            text={`Приобрести пакет`}
            className={`bg-white border border-MainAccent text-MainAccent hover:text-white`}
            onClick={() => {
              buySubscription({ id: id || 0, type: target || "" }).then(() => {
                setComplete(true);
              });
            }}
          />
        </div>
      </div>
      <div className={`${complete ? "" : "hidden"}`}>
        <p className={`base`}>Оператор скоро с вами свяжется</p>
      </div>
    </div>
  );

  return (
    <div>
      <div className={`mt-5`}>
        <h1 className={`base text-[36px]`}>Подписка на контент</h1>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Подписка на контент позволяет сотрудникам компаний проходить любые
          платные курсы, доступные на платформе Life-long Learning, в течение
          периода подписки. Курс может быть назначен руководителем, либо
          сотрудник может записаться на обучение самостоятельно.
        </p>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-2xl`}>Преимущества подписки</p>
        <div className={`flex flex-row justify-around mt-10`}>
          <div className={``}>
            <img src={firstFeature} alt="feature" className={`ml-12`} />
            <p
              className={`base text-SecondaryAccent-dark mt-4 text-[15px] text-center w-[170px]`}
            >
              Выгода по сравнению с разовой покупкой курсов до 50%
            </p>
          </div>
          <div>
            <img src={secondFeature} alt="feature" className={`ml-11`} />
            <p
              className={`base text-SecondaryAccent-dark mt-4 text-[15px] text-center w-[165px]`}
            >
              Безлимитный доступ к платным продуктам для всей команды
            </p>
          </div>
          <div>
            <img src={thirdFeature} alt="feature" className={`ml-10`} />
            <p
              className={`base text-SecondaryAccent-dark mt-4 text-[15px] text-center w-[170px]`}
            >
              Удобное назначение курсов сотрудникам
            </p>
          </div>
        </div>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-2xl`}>Как включить подписку</p>
        <div className={`flex flex-col gap-x-4`}>
          <div
            className={`flex relative before:content-[''] before:bg-SecondaryAccent before:w-[1px] before:absolute before:h-[150%] before:top-1/2 before:left-4 before:-z-10`}
          >
            <div
              className={`baseBold text-center border rounded-[50%] min-w-[32px] min-h-[32px] my-auto mt-4 mr-[14px] border-SecondaryAccent bg-white`}
            >
              <span className={`inline-block align-middle text-MainAccent`}>
                1
              </span>
            </div>
            <div className={`text-SecondaryAccent-dark mt-2`}>
              Определите, кому из ваших сотрудников нужно оформить подписку.
            </div>
          </div>
          <div
            className={`flex relative before:content-[''] before:bg-SecondaryAccent before:w-[1px] before:absolute before:h-[150%] before:top-1/2 before:left-4 before:-z-10`}
          >
            <div
              className={`baseBold text-center border rounded-[50%] min-w-[32px] min-h-[32px] my-auto mt-4 mr-[14px] border-SecondaryAccent bg-white`}
            >
              <span className={`inline-block align-middle text-MainAccent`}>
                2
              </span>
            </div>
            <div className={`text-SecondaryAccent-dark mt-2`}>
              Выберите подходящий пакет подписки.
            </div>
          </div>
          <div
            className={`flex relative before:content-[''] before:bg-SecondaryAccent before:w-[1px] before:absolute before:h-[150%] before:top-1/2 before:left-4 before:-z-10`}
          >
            <div
              className={`baseBold text-center border rounded-[50%] min-w-[32px] min-h-[32px] my-auto mt-3 mr-[14px] border-SecondaryAccent bg-white`}
            >
              <span className={`inline-block align-middle text-MainAccent`}>
                3
              </span>
            </div>
            <div className={`text-SecondaryAccent-dark mt-4`}>
              Нажмите «Отправить заявку» напротив выбранного пакета. Мы свяжемся
              с Вами по электронной почте для уточнения деталей и оплаты
              подписки.
            </div>
          </div>
          <div
            className={`flex relative before:content-[''] before:bg-SecondaryAccent before:w-[1px] before:absolute before:h-[130%] before:top-1/2 before:left-4 before:-z-10`}
          >
            <div
              className={`baseBold text-center border rounded-[50%] min-w-[32px] min-h-[32px] my-auto mt-4 mr-[14px] border-SecondaryAccent bg-white`}
            >
              <span className={`inline-block align-middle text-MainAccent`}>
                4
              </span>
            </div>
            <div className={`text-SecondaryAccent-dark mt-2`}>
              После оплаты вы сможете назначить подписку вашим сотрудникам в
              разделе «Команда». Неиспользованные подписки вы сможете назначить
              новым сотрудникам в течение всего срока действия пакета подписки.
            </div>
          </div>
          <div className={`flex relative `}>
            <div
              className={`baseBold text-center border rounded-[50%] min-w-[32px] min-h-[32px] my-auto mt-3 mr-[14px] border-SecondaryAccent bg-white`}
            >
              <span className={`inline-block align-middle text-MainAccent`}>
                5
              </span>
            </div>
            <div className={`text-SecondaryAccent-dark mt-4`}>
              Информация о пакете подписки и сроке его действия всегда доступна
              в Личном кабинете.
            </div>
          </div>
        </div>
      </div>
      <div className={`mt-10`}>
        <p className={`base text-2xl`}>Пакеты подписки</p>
        <div className={`mt-10`}>
          <table className={`w-full border-collapse`}>
            <thead>
              <tr>
                <th
                  className={`baseMicro border-b w-[80px] align-top pb-[12px] text-left pl-4`}
                >
                  Пакет
                </th>
                <th
                  className={`baseMicro border-b w-[80px] align-top pb-[6px]`}
                >
                  Количество участников*
                </th>
                <th
                  className={`baseMicro border-b w-[80px] align-top pb-[6px]`}
                >
                  Срок подписки
                </th>
                <th
                  className={`baseMicro border-b w-[80px] align-top pb-[6px]`}
                >
                  Стоимость
                </th>
                <th
                  className={`baseMicro border-b w-[80px] align-top pb-[6px]`}
                ></th>
              </tr>
            </thead>
            <tbody>{renderedElements}</tbody>
          </table>
        </div>
      </div>
      <ModalWrapper
        header={complete ? "Запрос успешно отправлен" : "СПОСОБ ОПЛАТЫ"}
        text={""}
        onClose={() => {
          setComplete(false);
          setIsModal(false);
        }}
        visible={isModal}
        children={child}
      />
      <div className={`mt-10`}>
        <p className={`base text-SecondaryAccent text-[16px]`}>
          Информация о сроке действия и пакете подписки всегда доступна в личном
          кабинете.
        </p>
        <p className={`baseMicro text-SecondaryAccent mt-2 mb-10`}>
          * Пакет подписки должен покрывать или превышать количество сотрудников
          в вашей команде. В случае необходимости увеличения числа сотрудников
          сверх пакета вам будет предложено изменение пакета подписки в текущем
          расчетном периоде. Также вы сможете обновить пакет в новом расчетном
          периоде, при этом до его наступления новые сотрудники не будут
          подключены к платным услугам.
        </p>
      </div>
    </div>
  );
};
