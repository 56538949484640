import React, {useEffect} from "react";
import CommandBlock from "../../components/CommandBlock";

export const Command = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
  return (
    <div>
      <CommandBlock />
    </div>
  );
};
