import React from "react";
import { ArchiveCardReq } from "../interfaces/ArchiveCard";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";

type IProps = ArchiveCardReq;

const ArchiveCard = (p: IProps) => {
  const link = createPath({
    route: AppRoutes.Archive,
    args: [{ key: "id", value: p.courseId }],
  });
  return (
    <Link
      className="flex w-[384px] h-[120px] shadow-recomendation my-[10px] mx-[9px] defaultTransition"
      to={link}
    >
      <img className="w-[50%] h-[100%]" src={p.imgSrc} alt="img" />
      <div className="w-[50%] px-4 py-[6px]">
        <p className="text-DarkAccent leading-6 min-h-[90px] base">{p.name}</p>
        <p className="baseMicro text-SecondaryAccent uppercase leading-[11px]">
          {p.count} {p.count === 1 ? "курс" : p.count <= 4 ? "курса" : "курсов"}
        </p>
      </div>
      <div className="w-[4.2%] h-[100%] bg-MainAccent"></div>
    </Link>
  );
};

export default ArchiveCard;
