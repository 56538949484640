import React from "react";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import angle from "../assets/img/courseAngle.svg";
import { getDeadlineColor } from "../utils/colorsHandler";
import { GetContiuningEducationRes } from "../network/CourseApi/courseInterfaces";

type IProps = {
  continueCards: Array<GetContiuningEducationRes>;
  filter?: FilterCourses;
  cardsLoad: boolean
};
type FilterCourses = {
  direction?: any;
  status?: any;
  deadline?: any;
};

const ContinueCourse = (p: IProps) => {

  let skeletons: any = []
  for (let i = 0; i < 3; i++) {
    skeletons.push(<div role="status"
      className="w-60 h-64 mr-3 my-[14px]  space-y-8 animate-pulse rtl:space-x-reverse">
      <div className="flex flex-col items-center justify-center w-full h-36 bg-gray-300 rounded dark:bg-gray-400">
        <svg className="w-10 h-10 text-gray-200 dark:bg-gray-400" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      <div className="w-full">
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-16"></div>
        <div className="h-1 bg-gray-200 rounded-full dark:bg-gray-400 mb-2"></div>
        <div className="h-1 bg-gray-200 rounded-full dark:bg-gray-400"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>)
  }

  const renderedCards = (p.continueCards || []).map(
    (card: GetContiuningEducationRes) => {
      const deadline = p.filter?.deadline || card.course_deadline;
      const status =
        p.filter?.status?.value == "progress"
          ? card.user_percent > 0 && card.user_percent < 100
          : p.filter?.status?.value == "end"
            ? card.user_percent === 100
            : p.filter?.status?.value === "none"
              ? card.user_percent === 0
              : true;
      const direction = p.filter?.direction?.value || card.course_code;
      const link = createPath({
        route: AppRoutes.Learn,
        args: [{ key: "course", value: card.course_id }],
      });
      if (deadline && status && card.course_code.includes(direction)) {
        if (card.course_code.includes("COFFEE")) {
          return;
        }
        return (
          <Link to={link} className="continueCourseMainCard">
            <div>
              <img
                className="w-[248px] h-[152px] object-cover transition duration-200 ease-in-out hover:shadow-recomendationHover"
                src={card.course_image}
                alt="cardImg"
              />
              <img
                className="absolute right-0 top-0 w-auto h-auto"
                src={angle}
                alt="angle"
              />
              <div className="relative mt-[14px] w-[248px] h-2 bg-SecondaryAccent">
                <div>
                  <div
                    className={`${getDeadlineColor(
                      card.course_deadline,
                      card.user_percent,
                      card.test_percent
                    )} absolute h-2`}
                    style={{ width: `${card.user_percent}%` }}
                  ></div>
                </div>
                <div className="w-[100%] min-h-[105px] flex flex-col justify-between">
                  <div className="pt-3 mb-[14px] base leading-6 text-SecondaryAccent-dark grow w-[248px]">
                    <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {card.course_name}
                    </p>
                  </div>
                  <div className="">
                    <p className="baseMicro pb-3 text-SecondaryAccent">
                      {card.course_type}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      } else {
        return;
      }
    }
  );

  return (
    <div className="mt-[11px] flex flex-wrap justify-start">
      {p.cardsLoad ? renderedCards: skeletons}
    </div>
  );
};

export default ContinueCourse;
