import React, { useState } from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { GetCommandRes } from "../network/CommandApi/commandInterfaces";

type IProps = {
  selectedUsers: any;
  setSelectedUsers: (team: any) => void;
  team: GetCommandRes;
};

const headCards = [
  {
    text: "Сортировать по алфавиту",
    name: "full_name",
    className: "tableDefaultHead mr-20",
  },
  {
    text: "Количество",
    name: "courses_count",
  },
  {
    text: "Завершено",
    name: "countFinished",
  },
  {
    text: "Статус курсов",
    name: "assigned",
  },
];

const TableBlock = (p: IProps) => {
  const [sortBy, setSortBy] = useState<any>("name");
  const [order, setOrder] = useState<boolean>(true);

  return (
    <table className={`ml-4 mt-3`}>
      <TableHead
        cards={headCards}
        sortBy={setSortBy}
        currentSort={sortBy}
        orderBy={setOrder}
        currentOrder={order}
      />
      {p.team.employers && (
        <TableBody
          rows={p.team.employers}
          sort={sortBy}
          order={order}
          setSelectedUsers={p.setSelectedUsers}
          selectedUsers={p.selectedUsers}
        />
      )}
    </table>
  );
};

export default TableBlock;
