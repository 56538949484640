import React from "react";

const MainLearn = () => (
    <div
        className={`w-full h-[100vh] lg:mt-6 rounded-tl-[48px] rounded-tr-[48px] bg-SecondaryAccent-veryLight z-10 relative flex items-center justify-center`}
    >
        <div
            className={`base leading-[108px] text-[72px] lg:text-[54px] w-[60%] lg:w-[80%] text-center flex justify-center sm:text-[28px] sm:w-[90%]`}
        >
            <p>
                <span className={`text-MainAccent`}>Обучайся</span>, зарабатывай
                <span className={`text-MainAccent `}> монеты</span> и обменивай их на
                <span className={`text-MainAccent`}> подарки</span>
            </p>
        </div>
    </div>
);

export default MainLearn;
