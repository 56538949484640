enum UserEndpoints {
  GetHeader = "/get/user/header",
  GetLogins = "/get/user/logins",
  GetLogout = "/get/user/logout",
  GetData = "/get/user/data",
  SetData = "/set/user/data",
  SetAvatar = "/set/user/avatar",
  CheckKey = "/set/user/check_key",
  GetCertificates = "/get/user/certificates",
  GetSubscribes = "/get/user/subscribes",
  SendFeedback = "/set/user/send_feedback",
  Login = "/login",
  Register = "/registration",
  Recovery = "/user/recovery",
  Reset = "/user/reset",
}

export default UserEndpoints;
