import React, { useState } from "react";
import { TableHeadReq } from "../interfaces/TableHead";
import { tableArrow } from "../utils/tableArrow";
import { getColor } from "../utils/colorsHandler";
import Colors from "../structure/colors";

type IProps = {
  element: TableHeadReq;
  sortBy: (sort: string) => void;
  currentCort: string;
  orderBy: (order: boolean) => void;
  currentOrder: boolean;
};
const TableHeadElement = (p: IProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const toggleSelected = (e: any) => {
    p.orderBy(!p.currentOrder);
    p.sortBy(e.currentTarget.id);
    setIsSelected(!isSelected);
  };

  return (
    <th
      onClick={toggleSelected}
      id={p.element.name}
      className={`${
        p.element.className ? p.element.className : "tableDefaultHead"
      }`}
    >
      {p.element.text}
      <div
        className={`${
          isSelected ? "rotate-180 pr-1 " : "pl-1"
        } duration-300 origin-center`}
      >
        {tableArrow(
          p.element.name === p.currentCort ? getColor(Colors.Main, true) : ""
        )}
      </div>
    </th>
  );
};

export default TableHeadElement;
