import Button from "../Button";
import menu from "../../assets/img/welcome/menu.svg";

import logo from "../../assets/img/logo.svg";

interface IProps {openModal: ()=> void}

const CreateHeader = (props: IProps) => {
  return (
    <div
      id={`header`}
      className={`min-h-[92px] flex flex-row items-center base justify-between `}
    >
      <div id={`logo`} className={``}>
        <img src={logo} alt="logo" />
      </div>
      <div>
        <Button
          text={"Вход"}
          className={
            "bg-SecondaryAccent-veryLight text-MainAccent mr-4 h-[60px] w-[124px] rounded-full hover:bg-SecondaryAccent-light font-medium sm:hidden"
          }
          onClick={props.openModal}
        />


      </div>
    </div>
  );
};

export default CreateHeader;
