import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';

import TableSortLabel from './TableSortLabel';
import TableHeader from './TableHeader';

export interface Pagination {
	page: number;
	size: number;
}

export interface Sort {
	order_by?: string;
	order_dir?: 'asc' | 'desc';
}

export interface Column<T> {
	key?: string;
	title?: string;
	sortKey?: string;
	noExport?: boolean;
	render?(item: T, index: number): any;
	renderHeader?(): any;
	renderExport?(item: T): any;
	className?: string;
}

export interface TableTemplate<T> {
	data: Column<T>[];
	firstRow?: ReactNode;
}

export interface TableProps<T> {
	template: TableTemplate<T>;
	data: T[];
	sort?: Sort;
	setSort?(value: Sort): void;
	pagination?: Pagination;
	setPage?(value: number): void;
	setPageSize?(value: number): void;
	allCount?: number;

	styleClasses?: {
		table?: string;
		head?: string;
		column?: string;
		titles?: string;
		row?: string;
		padding?: string;
	};
}

const Table = <T extends object>(props: TableProps<T>) => {
	const onChangeSort = (property: string) => {
		const isAscending =
			props.sort?.order_by === property && props.sort?.order_dir === 'asc';
		if (props.sort?.order_by === property && !isAscending) {
			props.setSort?.({});
		} else {
			props.setSort?.({
				order_by: property,
				order_dir: isAscending ? 'desc' : 'asc',
			});
		}
	};

	const getHeaderCell = (column: Column<T>) => {
		if (column.renderHeader) {
			return column.renderHeader();
		} else if (column.sortKey) {
			return (
				<TableSortLabel
					active={props.sort?.order_by === column.sortKey}
					direction={
						props.sort?.order_by === column.sortKey
							? props.sort?.order_dir
							: 'desc'
					}
					onClick={(event) => onChangeSort(column.sortKey || '')}
				>
					{column.title}
				</TableSortLabel>
			);
		} else
			return (
				<TableHeader className={props.styleClasses?.titles}>
					{column.title}
				</TableHeader>
			);
	};

	const getCellValue = (item: T, column: Column<T>, index: number) => {
		if (column.render) return column.render(item, index);
		else if (column.key) return item[column.key as keyof T];
		return '';
	};

	useEffect(() => {
		if (
			props.pagination &&
			props.setPage &&
			props.allCount &&
			props.allCount > 0 &&
			props.pagination.page * props.pagination.size > props.allCount
		) {
			props.setPage(~~(props.allCount / props.pagination.size));
		}
	}, [props.pagination, props.allCount]);

	return (
		<div className='flex w-full flex-col'>
			<table className='w-full border-collapse'>
				<thead
					className={clsx(
						'sticky top-0 z-10 mt-auto h-11 w-5 border-b border-gray-2 bg-gray-1 text-left text-gray-4',
						props.styleClasses?.head
					)}
				>
					<tr>
						{props.template.data.map((col, i) => (
							<th
								key={`column-${i}`}
								onClick={() => col.sortKey && onChangeSort(col.sortKey)}
								className={clsx(
									'pb-0 text-xs font-medium tracking-widest',
									props.styleClasses?.padding ||
										'first:pl-section last:w-1 last:pr-section',
									col.sortKey && 'cursor-pointer',
									props.styleClasses?.column,
									col.className
								)}
							>
								{getHeaderCell(col)}
							</th>
						))}
					</tr>
				</thead>
				<tbody className='text-black'>
					{props.template.firstRow && (
						<tr
							className={clsx(
								'border-b border-gray-2',
								props.styleClasses?.row
							)}
						>
							<td
								className={clsx(
									'py-3',
									props.styleClasses?.padding ||
										'first:pl-section last:pr-section'
								)}
								colSpan={props.template.data.length}
							>
								{props.template.firstRow}
							</td>
						</tr>
					)}
					{props.data.map((item, i) => (
						<tr
							key={i}
							className={clsx(
								'border-b border-gray-2',
								props.styleClasses?.row
							)}
						>
							{props.template.data.map((col, j) => (
								<td
									key={j}
									className={clsx(
										'py-3',
										props.styleClasses?.padding ||
											'first:pl-section last:pr-section',
										col.className
									)}
								>
									{getCellValue(item, col, i)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
