import SessionEndpoints from "../../structure/sessionEndpoints";
import { request } from "../mainRequest";
import { CalendarSessions, GetSessionRes } from "./sessionInterfaces";

export function getSession(): Promise<Array<GetSessionRes>> {
  return request(SessionEndpoints.GetSession);
}

export function getSessionsForCalendar(): Promise<CalendarSessions> {
  return request(SessionEndpoints.GetSessionsForCalendar);
}
