import React from "react";
import { TabbarElementReq } from "../interfaces/TabbarElement";
import { Link, useLocation } from "react-router-dom";

type IProps = {
  elements: Array<TabbarElementReq>;
  onSelect: (v: any) => void;
};

const UseHash = () => {
  const location = useLocation();
  return location.hash;
};

const TabBarBlock = (p: IProps) => {
  const handleSelect = (option: any) => {
    p.onSelect(option);
  };

  const currentHash = UseHash();
  const checkHash = (arg: string) => {
    return currentHash === arg;
  };
  let count = 0;
  const renderedElements = p.elements.map((el) => {
    count++;
    console.log(count);
    return (
      <Link
        to={el.path}
        onClick={() => {
          handleSelect(el.path);
        }}
      >
        <div
          className={`base h-6 leading-6 px-4 border-b border-b-SecondaryAccent ${
            checkHash(el.path) || (count === 1 && currentHash === "")
              ? "text-MainAccent border-b-2 border-b-MainAccent bg-SecondaryAccent-veryLight font-bold"
              : "text-SecondaryAccent"
          }`}
        >
          <p>{el.name}</p>
        </div>
      </Link>
    );
  });

  return <div className={`flex`}>{renderedElements}</div>;
};

export default TabBarBlock;
