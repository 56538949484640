import React, { createContext, useContext, useMemo, useState } from "react";
import { PromoInfoResp, PromoUser } from "../network/PromoApi/promoInterfaces";
import { promoUserInfo } from "../network/PromoApi/promoApi";

type UserInfo = PromoInfoResp | undefined;

type IAuthContext = [UserInfo, React.Dispatch<React.SetStateAction<UserInfo>>, ()=> void];

const AuthContext = createContext<IAuthContext>([undefined, () => null,() => null]);

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

function AuthProvider(props: any) {
  const [auth, setAuth] = useState<UserInfo>();
  const getUserInfo = () => {
    promoUserInfo().then((r) => {
      if (r.status === "ok") {
        setAuth(r.data);
      }
    });
  };

  const value = useMemo(
    () => [auth, setAuth, getUserInfo],
    [auth, getUserInfo]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

export { AuthProvider, useAuth };
