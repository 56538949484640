import React, { useEffect, useState } from "react";
import { GetStartTestRes } from "../network/CourseApi/courseInterfaces";
import Button from "./Button";
import { getPercent } from "../utils/percentHandler";
import { acceptStep, getEducationCourse } from "../network/CourseApi/courseApi";

type IProps = GetStartTestRes;

type answers = number;
const UserTest = (p: IProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selected, setSelected] = useState<any>();
  const [multiSelected, setMultiSelected] = useState<any>([]);
  const [userAnswers, setUserAnswers] = useState<answers>(0);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (multiSelected.length) {
      let totalCount = 0;
      p.items[currentStep].answers.map((el) => {
        if (el.is_correct === 1) {
          totalCount++;
        }
      });
      let check = totalCount;
      multiSelected.map((el: any) => {
        if (p.items[currentStep].answers[el].is_correct === 1) {
          check = check - 1;
        } else {
        }
        console.log(totalCount, check);
        if (totalCount - check === totalCount) {
          setUserAnswers(userAnswers + 1);
        }
      });
    } else {
      setUserAnswers(
        userAnswers + p.items[currentStep].answers[selected].is_correct
      );
    }
    setCurrentStep(currentStep + 1);
    setSelected(undefined);
    setMultiSelected([]);
  };

  const resetTest = () => {
    setCurrentStep(0);
    setUserAnswers(0);
    setSelected(undefined);
  };

  useEffect(() => {
    if (currentStep > p.items.length - 1) {
      if (getPercent(userAnswers, p.items.length) > 50) {
        acceptStep({ bookmark: 0, course_id: p.test_id.toString() }).then(
          () => {
            getEducationCourse({ course_id: currentStep }).then(() => {});
          }
        );
      }
    }
  }, [currentStep]);
  console.log(!multiSelected.length);
  return (
    <div
      className={`w-[90%] min-h-[40%] p-[10px] relative shadow-recomendation`}
    >
      {currentStep <= p.items.length - 1 && (
        <div>
          <div
            className={`base text-[24px] leading-8`}
            dangerouslySetInnerHTML={{
              __html: p.items[currentStep].title_quest,
            }}
          ></div>
          <div>
            <form
              className={`flex flex-col items-start mt-6`}
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              {p.items[currentStep].answers.map((el, key) => {
                return (
                  <label>
                    <input
                      className={`mr-2`}
                      type={
                        p.items[currentStep].type_quest != "choice_multiple"
                          ? "radio"
                          : "checkbox"
                      }
                      checked={
                        p.items[currentStep].type_quest != "choice_multiple"
                          ? Number(selected) === key
                          : multiSelected.includes(key.toString())
                      }
                      name={`quest${p.items[currentStep].answers[0].answer_id}`}
                      value={key}
                      onChange={(event) => {
                        if (p.items[currentStep].type_quest === "choice") {
                          setSelected(event.currentTarget.value);
                        } else if (
                          p.items[currentStep].type_quest ===
                            "choice_multiple" &&
                          !multiSelected.includes(key.toString())
                        ) {
                          setMultiSelected([
                            ...multiSelected,
                            event.currentTarget.value,
                          ]);
                        } else {
                          setMultiSelected(
                            multiSelected.filter(
                              (e: string) => e !== key.toString()
                            )
                          );
                        }
                      }}
                    />
                    {el.answer}
                  </label>
                );
              })}
              <Button
                text={"Подтвердить"}
                className={`border border-MainAccent bg-white text-MainAccent mt-2 hover:text-white`}
                disabled={!selected && !multiSelected.length}
              />
            </form>
          </div>
        </div>
      )}
      {currentStep > p.items.length - 1 && (
        <div>
          {/*<p>{getPercent(userAnswers, p.items.length)}</p>*/}
          <p
            className={`mt-2 base text-[34px] ${
              getPercent(userAnswers, p.items.length) > 50
                ? "text-MainAccent"
                : "text-CloseDeadlineAccent"
            }`}
          >
            {getPercent(userAnswers, p.items.length) > 50
              ? "Вы успешно прошли тест"
              : "Тест не пройден"}
          </p>
          <p
            className={`mt-8 base text-[30px] ${
              getPercent(userAnswers, p.items.length) > 50
                ? "text-MainAccent"
                : "text-CloseDeadlineAccent"
            }`}
          >
            {getPercent(userAnswers, p.items.length) + "%"}
          </p>
          <div>
            <Button
              onClick={() => {
                resetTest();
              }}
              text={"Повторить тест"}
              className={`border border-MainAccent bg-white text-MainAccent mt-4 hover:text-white`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTest;
