import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import {
  getArchiveCount,
  getContiuningEducation,
} from "../../network/CourseApi/courseApi";
import ContinueCourse from "../../components/ContinueCourse";
import {
  GetArchiveCountRes,
  GetContiuningEducationReq,
  GetContiuningEducationRes,
} from "../../network/CourseApi/courseInterfaces";
import { ArchiveCardReq } from "../../interfaces/ArchiveCard";
import mediumVoltage from "../../assets/img/mediumVoltage.png";
import lowVoltage from "../../assets/img/lowVoltage.png";
import asutp from "../../assets/img/asutp.png";
import buildingAutomation from "../../assets/img/buildingAutomation.png";
import informationModeling from "../../assets/img/informationModeling.png";
import calculationsAndDesign from "../../assets/img/calculationsAndDesign.png";
import ArchiveBlock from "../../components/ArchiveBlock";
import CalendarBlock from "../../components/CalendarBlock";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import CoffeeBlock from "../../components/CoffeeBlock";
import {
  GetCoffeeReq,
  GetCoffeeRes,
} from "../../network/CoffeeApi/coffeeInterfaces";
import { getCoffee } from "../../network/CoffeeApi/coffeeApi";
import { CalendarSessions } from "../../network/SessionApi/sessionInterfaces";
import { getSessionsForCalendar } from "../../network/SessionApi/sessionApi";

const CONTINUE_FILTER: GetContiuningEducationReq = { limit: 3, only: "scorm" };
const COFFEE_FILTER: GetCoffeeReq = { limit: 2 };

export const Main = () => {
  const [courses, setCourses] = useState<Array<GetContiuningEducationRes>>([]);
  const [coursesloaded, setCoursesLoaded] = useState<boolean>(false)
  const [archiveCount, setArchiveCount] = useState<GetArchiveCountRes>([]);
  const [coffee, setCoffee] = useState<Array<GetCoffeeRes>>([]);
  const [calendarSessions, setCalendarSessions] = useState<CalendarSessions>();

  useEffect(() => {
      window.scrollTo(0,0)
    getSessionsForCalendar().then((r) => {
      setCalendarSessions(r);
    });
  }, []);

  useEffect(() => {
    getContiuningEducation(CONTINUE_FILTER).then((r) => {
      setCourses(r);
      setCoursesLoaded(true)
    });
  }, []);

  useEffect(() => {
    getArchiveCount().then((r) => {
      setArchiveCount(r);
    });
  }, []);

  useEffect(() => {
    getCoffee(COFFEE_FILTER).then((r) => {
      setCoffee(r);
    });
  }, []);

  const archive: Array<ArchiveCardReq> = [
    {
      imgSrc: mediumVoltage,
      name: "Среднее напряжение",
      count: archiveCount[0],
      courseId: 24,
    },
    {
      imgSrc: lowVoltage,
      name: "Низкое напряжение",
      count: archiveCount[1],
      courseId: 22,
    },
    {
      imgSrc: asutp,
      name: "АСУТП",
      count: archiveCount[2],
      courseId: 20,
    },
    {
      imgSrc: buildingAutomation,
      name: "Автоматизация зданий",
      count: archiveCount[3],
      courseId: 19,
    },
    {
      imgSrc: informationModeling,
      name: "Информационное моделирование",
      count: archiveCount[4],
      courseId: 21,
    },
    {
      imgSrc: calculationsAndDesign,
      name: "Расчеты и проектирование",
      count: archiveCount[5],
      courseId: 23,
    },
  ];
  return (
    <div className={`w-[795px]`}>
      <div className={`mt-4`}>
        <h1 className={`base text-[50px]`}>Life-long Learning</h1>
        <p className={`base text-[22px] mt-7 w-[550px]`}>
          Программа непрерывного развития компетенций для специалистов проектных
          организаций
        </p>
        <Link to={AppRoutes.About}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Возможности платформы`}
          />
        </Link>
      </div>
      <div className={`mt-20`}>
        <p className={`base text-[36px]`}>Продолжить обучение</p>
        <ContinueCourse cardsLoad = {coursesloaded} continueCards={courses} />
        <Link to={AppRoutes.UserCabinet}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Учебный кабинет`}
          />
        </Link>
      </div>
      <div className={`mt-20`}>
        <p className={`base text-[36px]`}>Профессиональные курсы</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Помогают глубоко изучить тему и получить сертификат. Обучение удобно
          разделено по направлениям, в каждом из которых вы можете
          последовательно или выборочно проходить нужные вам курсы. Курс может
          содержать один или несколько уроков, а также обязательное
          тестирование. При успешной сдаче теста вы получаете именной сертификат
          о прохождении обучения.
        </p>
        <ArchiveBlock archiveCards={archive} />
        <Link to={AppRoutes.Courses}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Выбрать курс`}
          />
        </Link>
      </div>
      <div className={`mt-20`}>
        <p className={`base text-[36px]`}>Экспертные сессии</p>
        {calendarSessions && (
          <CalendarBlock sessions={calendarSessions.sessions} />
        )}
      </div>
      <div className={`mt-20 mb-24`}>
        <p className={`base text-[36px]`}>Кофе с экспертом</p>
        <p className={`base text-SecondaryAccent-dark mt-4 text-[15px]`}>
          Короткие видео от экспертов
        </p>
        <CoffeeBlock cards={coffee} small={true} />
        <Link to={`${AppRoutes.Courses}#coffee`}>
          <Button
            className={`mt-[35px] text-MainAccent bg-white border border-MainAccent hover:text-white`}
            text={`Все записи`}
          />
        </Link>
      </div>
    </div>
  );
};
