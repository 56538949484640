import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import InputForm from "../../components/InputForm";
import Button from "../../components/Button";
import { buildObject } from "../../utils/buildObject";
import { shopOrder } from "../../network/ShopApi/shopApi";
import ModalWrapper from "../../components/ModalWrapper";

const fields = [
  {
    placeholder: "Имя",
    name: "name",
    required: true,
  },
  {
    placeholder: "Фамилия",
    name: "lastname",
    required: true,
  },
  {
    placeholder: "Страна",
    name: "country",
    required: true,
  },
  {
    placeholder: "Индекс",
    name: "region",
    required: true,
  },
  {
    placeholder: "Город",
    name: "city",
    required: true,
  },
  {
    placeholder: "Улица",
    name: "street",
    required: true,
  },
  {
    placeholder: "Номер дома",
    name: "house",
    required: true,
  },
  {
    placeholder: "Квартира/офис (необязательно)",
    name: "apartament",
  },
  {
    placeholder: "Комментарий (необязательно)",
    name: "comment",
    text: true,
  },
];

export const Order = () => {
  const [isMarked, setIsMarked] = useState(false);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const toggleMark = () => {
    setIsMarked(!isMarked);
  };
  const location = useLocation();
  const current = new URLSearchParams(location.search).get("id");
  const renderedFields = fields.map((el) => {
    return (
      <InputForm
        text={el.text}
        name={el.name}
        required={el.required}
        placeholder={el.placeholder}
        className={`w-[400px] border border-SecondaryAccent-one rounded-xl h-[55px] relative mb-6 px-[13px]`}
      />
    );
  });
  const handleOrder = (e: any) => {
    e.preventDefault();
    const data = buildObject(e, 9, Number(current));
    shopOrder(data).then(() => {
      return setIsModal(true);
    });
  };
  return (
    <div>
      <div className={`mt-5`}>
        <p className={`base text-[24px]`}>Данные для доставки</p>
      </div>
      <div className={`mt-10`}>
        <form
          className={`flex flex-col items-start`}
          method="POST"
          onSubmit={(event) => handleOrder(event)}
        >
          {renderedFields}
          <div className="flex items-start my-[10px] w-[400px]">
            <div
              onClick={toggleMark}
              className={`min-w-[15px] min-h-[15px] mr-[10px] cursor-pointer border-2 border-SecondaryAccent-one ${
                isMarked ? "bg-MainAccent" : ""
              }`}
            ></div>
            <p className="baseMicro">
              Заполняя настоящую регистрационную форму, я даю согласие
              акционерному обществу «Систэм Электрик» на обработку содержащихся
              в ней персональных данных в соответствии с условиями и порядком
              обработки моих персональных данных, установленных{" "}
              <Link
                className="underline text-MainAccent"
                to="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
              >
                {" "}
                Политикой акционерного общества «Систэм Электрик»{" "}
              </Link>{" "}
              в отношении обработки персональных данных и сведениями о
              реализуемых требованиях к защите персональных данных, в целях
              получения информации о продуктах и доступных решениях Систэм
              Электрик, акцептом которых является заполнение данной формы.
            </p>
          </div>
          <Button
            className="font-bold mt-4"
            text="Оформить заказ"
            isAttention={false}
            disabled={!isMarked}
          />
        </form>
      </div>
      <ModalWrapper
        header={"ЗАЯВКА ОТПРАВЛЕНА"}
        text={"Ваш заказ в обработке, мы свяжемся с Вами по эл. почте"}
        onClose={() => {
          setIsModal(false);
        }}
        visible={isModal}
      />
    </div>
  );
};
