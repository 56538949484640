import React, { useState } from "react";
import city from "../../assets/img/welcome/city.svg";
import InputForm from "../InputForm";
import Button from "../Button";
import { Simulate } from "react-dom/test-utils";
import input = Simulate.input;

type IProps = {
  button: (email: string) => void;
};

const MainRegister = (p: IProps) => {
  const [email, setEmail] = useState<string>("");
  return (
    <div
      className={`w-full h-[118vh] mt-[-60px] rounded-tl-[48px] rounded-tr-[48px] bg-[#E2E5EB] z-10 relative flex items-center justify-center overflow-hidden`}
    >
      <div
        className={`flex-col flex justify-between items-center text-center relative`}
      >
        <div className={`pb-[10%] text-[48px]`}>
          <p>Регистрируйся</p>
        </div>
        <div className={`pb-[20%] text-[24px] w-[70%] font-light`}>
          <p>Зарегистрируйтесь на платформе и начните обучение уже сегодня</p>
        </div>
        <div
          className={`bg-white w-[640px] h-[414px] relative rounded-[32px] sm:w-[90%]`}
        >
          <p className={`text-[32px] mt-[10%]`}>Регистрация</p>
          <div className={`mt-[10%]`}>
            <InputForm
              onChange={(e) => {
                setEmail(e);
              }}
              placeholder={"E-mail"}
              name={`register`}
              className={`w-[496px] border rounded-[32px] h-[72px] pl-6 sm:w-[90%]`}
            />
            <div className={`mt-[5%]`}>
              <Button
                onClick={() => {
                  p.button(email);
                }}
                text={`Зарегистрироваться`}
                className={`w-[496px] h-[72px] rounded-[32px] text-[18px] sm:w-[90%]`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`absolute left-0 bottom-0 -z-10`}>
        <img src={city} alt="" />
      </div>
    </div>
  );
};

export default MainRegister;
