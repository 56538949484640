import React from "react";
import Button from "../Button";

type stages = "login" | "register";
type IProps = {
  setModal: (stage: stages) => void;
};

const MainCompactNav = (p: IProps) => {
  return (
    <div
      className={`fixed bottom-[1%]  z-[9999] left-1/2 w-[400px] h-[100px] bg-white rounded-[32px] shadow-recomendation transform -translate-x-1/2`}
    >
      <div
        className={`flex flex-row h-full justify-center px-[5%] py-[5%] gap-5`}
      >
        <Button
          text={"Вход"}
          onClick={() => {
            p.setModal("login");
          }}
          className={`w-full rounded-[32px] bg-SecondaryAccent-light text-MainAccent hover:text-white`}
        />
        <Button
          text={"Регистрация"}
          onClick={() => {
            p.setModal("register");
          }}
          className={`w-full rounded-[32px]`}
        />
      </div>
    </div>
  );
};

export default MainCompactNav;
