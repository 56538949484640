import React, {useState} from "react";
import work from "../../assets/img/welcome/work.png";
import mark from "../../assets/img/welcome/mark.svg";
import Button from "../Button";
import MainFooter from "./MainFooter";

type IProps = {
    button: (email: string) => void;
};
const MainFeatures = (p: IProps) => {
    const [email, setEmail] = useState<string>("");
    return (
        <div id = "ForCompanyes">
            <div
                className={`w-full h-[1750px] lg:h-[1450px] md:h-[1350px] rounded-tl-[48px] rounded-tr-[48px] bg-white z-10 relative flex lg:flex-col md:justify-start md:pt-[8%] justify-end overflow-hidden px-[10%] lg:px-[5%]`}
            >
                <div className={`pt-[10%] md:pt-0`}>
                    <p
                        className={`text-[72px] w-[70%] md:w-full lg:text-[54px] sm:text-[36px]`}
                    >
                        Возможности <span className={`text-MainAccent`}>для компаний</span>
                    </p>
                    <p
                        className={`mt-[10%]  text-[36px] font-light w-[60%] sm:w-full
      `}
                    >
                        Управляйте развитием компетенций ваших сотрудников
                    </p>
                    <Button
                        text={"Узнать больше"}
                        className={`mt-[10%] md:mt-4 w-[235px] h-[72px] rounded-[32px] text-[18px]`}
                    />
                </div>
                <div
                    className={`pt-[15%] ml-[10%] md:pt-[10%] md:ml-0 w-[40%] lg:w-[60%] lg:flex lg:flex-col lg:gap-3 sm:w-full`}
                >
                    <div className={`flex flex-row text-[24px] md:text-[20px]`}>
                        <img src={mark} alt="" className={`mr-6`}/>
                        <p>Добавляйте сотрудников в свою команду</p>
                    </div>
                    <div className={`flex flex-row text-[24px] md:text-[20px]`}>
                        <img src={mark} alt="" className={`mr-6`}/>
                        <p>Назначайте обучение и определяйте срок завершения</p>
                    </div>
                    <div className={`flex flex-row text-[24px] md:text-[20px]`}>
                        <img src={mark} alt="" className={`mr-6`}/>
                        <p>Отслеживайте прогресс вашей команды</p>
                    </div>
                    <div className={`flex flex-row text-[24px] md:text-[20px]`}>
                        <img src={mark} alt="" className={`mr-6`}/>
                        <p>
                            Получайте дополнительные возможности для развития сотрудников с
                            подпиской на платные продукты
                        </p>
                    </div>
                    <div className={`flex flex-row text-[24px] md:text-[20px]`}>
                        <img src={mark} alt="" className={`mr-6`}/>
                        <p>
                            Создавайте внутреннее пространство для обучения сотрудников,
                            оформленное в вашем корпоративном стиле
                        </p>
                    </div>
                </div>
                <img
                    src={work}
                    alt=""
                    className={`absolute bottom-0 object-cover w-full max-h-[800px] left-0`}
                />
            </div>
            <MainFooter
                button={(e) => {
                    setEmail(e);
                    p.button(email);
                }}
            />
        </div>
    );
};

export default MainFeatures;
