import React from "react";

type IProps = {
  number: string;
  header: string;
  text: string;
};
const StageCard = (p: IProps) => (
  <div
    className={`w-[704px] py-20 rounded-[32px] bg-SecondaryAccent-veryLight mt-4 sm:w-full`}
  >
    <div className={`flex justify-center`}>
      <p className={`text-MainAccent text-[48px] fle`}>{p.number}</p>
      <div className={`ml-16 sm:ml-6 sm:w-64`}>
        <p className={`text-[32px] mt-[-10px]`}>{p.header}</p>
        <p className={`text-[18px] leading-[30px] mt-8 w-[468px] sm:w-[60vw]`}>
          {p.text}
        </p>
      </div>
    </div>
  </div>
);

export default StageCard;
