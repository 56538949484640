import React from "react";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../utils/dateHandler";
import { Months } from "../structure/months";
import { CalendarDayReq } from "../interfaces/CalendarDay";
import CalendarDay from "./CalendarDay";
import { GetSessionRes } from "../network/SessionApi/sessionInterfaces";

type IProps = {
  sessions: Array<GetSessionRes>;
};

const CalendarBlock = (p: IProps) => {
  const TODAY = new Date();
  const firstDay = getFirstDayOfMonth(TODAY.getFullYear(), TODAY.getMonth());
  const dayIterator =
    new Date(TODAY.getFullYear(), TODAY.getMonth(), 0).getDate() + 1;
  let arrSessions: Array<number> = [];
  let arrSessionIds: Array<number> = [];
  const dateObj: any = {};
  (p.sessions || []).map((el) => {
    const split = el.date_session_day.split("-");
    if (TODAY.getMonth() + 1 === Number(split[1])) {
      arrSessions = [...arrSessions, Number(split[2])];
      arrSessionIds = [...arrSessionIds, el.course_id];
      dateObj[Number(split[2])] = {
        date: el.date_session_day,
        begin: el.begin_time,
        end: el.end_time,
      };
    }
  });
  let days: Array<CalendarDayReq> = [];
  for (
    let i =
      firstDay.getDay() === 0
        ? firstDay.getDay() - 6
        : (firstDay.getDay() - 1) * -1;
    i < getLastDayOfMonth(TODAY.getFullYear(), TODAY.getMonth()).getDate();
    i++
  ) {
    if (i < 0) {
      days.push({
        inactive: true,
        day: dayIterator + i,
      });
    } else {
      days.push({
        day: i + 1,
        session: arrSessions.includes(i + 1),
        sessionId: arrSessionIds[arrSessions.indexOf(i + 1)],
        today: i + 1 === TODAY.getDate(),
        date: dateObj[i + 1],
      });
    }
  }
  const renderedDays = (days || []).map((day) => {
    return (
      <CalendarDay
        day={day.day}
        inactive={day.inactive}
        today={day.today}
        session={day.session}
        sessionId={day.sessionId}
        date={dateObj[day.day]}
      />
    );
  });

  return (
    <div className={"mt-[20px]"}>
      <div className={"mt-[30px] flex h-6 uppercase"}>
        <div
          className={`base w-28 h-6 leading-6 text-center bg-MainAccent text-[0.75em] text-white select-none`}
        >
          {Months[TODAY.getMonth()]}
        </div>
        <div
          className={
            "w-[calc(100%-112px)] bg-SecondaryAccent text-white base text-[0.75em] leading-6 pl-[15px] select-none"
          }
        >
          <p>Запишитесь на предстоящую онлайн-сессию</p>
        </div>
      </div>
      <div className={`grid grid-cols-7 w-full`}>{renderedDays}</div>
    </div>
  );
};

export default CalendarBlock;
