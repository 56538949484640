import React from "react";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

const Command = () => {
  return (
    <div className="text-SecondaryAccent-dark baseUnderline">
      <Link to={AppRoutes.Command}>Команда</Link>
    </div>
  );
};

export default Command;
